import React from "react";

const showHike = (currentCTC, offeredCTC) => {
    let hikePercentage = ((offeredCTC - currentCTC) / currentCTC) * 100;

    // Check if hikePercentage is NaN
    if (isNaN(hikePercentage)) {
        hikePercentage = 0;
    }
    if (hikePercentage == Infinity) {
        hikePercentage = 100;
    }
    const isPositiveHike = hikePercentage > 0;
    const isZeroHike = hikePercentage === 0;
    const formattedHike = Math.abs(hikePercentage).toFixed(0);

    const hikeStyle = {
        color: isZeroHike ? "#808080" : isPositiveHike ? "#00c900" : "#ff5454", // Grey color for no hike
    };

    const arrow = isZeroHike ? "→" : isPositiveHike ? "↑" : "↓"; // Neutral arrow for no hike

    return (
        <div style={hikeStyle}>
            {arrow} {formattedHike}%
        </div>
    );
};

export default showHike;
