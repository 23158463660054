import axios from "axios";
import React, { createContext, useState, useContext, useMemo } from "react";
import { useEnv } from "./EnvContext";
import { useAuth } from "./AuthContext";
import useGlobalToast from "../Utils/GlobalFunctions/toast";
import { useCandidate } from "./CandidateContext";
import { useJD } from "./JDContext";
import { getStatusCtoS, getStatusStoC } from "../Utils/GlobalFunctions/GetStatus";

const ApplicationContext = createContext();

export const ApplicationContextProvider = ({ children }) => {
    const toast = useGlobalToast();
    const { backendUrl } = useEnv();
    const { userData } = useAuth();
    const { setAllMatchingCandidates } = useCandidate();
    const { setAllMatchingJDs } = useJD();

    const [loadingApplication, setLoadingApplication] = useState(false);
    const [error, setError] = useState(null);

    // handle applications
    const [allApplications, setAllApplications] = useState({
        applications: {
            all: [],
            selected: [],
            onProcess: [],
            rejected: [],
            notStarted: [],
        },
        cvProcess: {
            all: [],
            selected: [],
            rejected: [],
            pending: [],
        },
        interviewProcess: {
            all: [],
            selected: [],
            rejected: [],
            onProcess: [],
        },
        offerProcess: {
            all: [],
            accepted: [],
            rejected: [],
            pending: [],
        },
        placementProcess: {
            all: [],
            accepted: [],
            rejected: [],
            pending: [],
        },
    });

    const [hasMoreApplications, setHasMoreApplications] = useState({
        applications: {
            all: true,
            selected: true,
            onProcess: true,
            rejected: true,
            notStarted: true,
        },
        cvProcess: {
            all: true,
            selected: true,
            rejected: true,
            pending: true,
        },
        interviewProcess: {
            all: true,
            selected: true,
            rejected: true,
            onProcess: true,
        },
        offerProcess: {
            all: true,
            selected: true,
            rejected: true,
            pending: true,
        },
        placementProcess: {
            all: true,
            selected: true,
            rejected: true,
            pending: true,
        },
    });

    const [applicationsPageNo, setApplicationsPageNo] = useState({
        applications: {
            all: 1,
            selected: 1,
            onProcess: 1,
            rejected: 1,
            notStarted: 1,
        },
        cvProcess: {
            all: 1,
            selected: 1,
            rejected: 1,
            pending: 1,
        },
        interviewProcess: {
            all: 1,
            selected: 1,
            rejected: 1,
            onProcess: 1,
        },
        offerProcess: {
            all: 1,
            selected: 1,
            rejected: 1,
            pending: 1,
        },
        placementProcess: {
            all: 1,
            selected: 1,
            rejected: 1,
            pending: 1,
        },
    });

    const memoizedApplications = useMemo(() => allApplications, [allApplications]);

    // start Application
    // const startApplication = async (candidateId,selectedRows, jobDescriptionId, status, mpId, handler) => {
    //     setLoadingApplication(true);
    //     setError(null);
    //     console.log(selectedRows);
    //     try {
    //         const token = userData?.token;

    //         // Determine if we need to send `candidateId` or `selectedRows`
    //         const candidates = selectedRows && selectedRows.length > 0 ? selectedRows : [candidateId];
    //         const response = await axios.post(
    //             `${backendUrl}/application/start`,
    //             {
    //                 candidateIds: candidates,
    //                 jobDescriptionId,
    //             },
    //             {
    //                 headers: {
    //                     Authorization: `Bearer ${token}`,
    //                 },
    //             }
    //         );

    //         console.log("add candidate response", response.data);
    //         // Extract necessary details from the response
    //         const { currentState, applicationID } = response.data.applicationProcess;

    //         if (handler == "procesJD") {
    //             setAllMatchingCandidates((prevState) => {
    //                 const updatedStatusCandidates = prevState[status].filter(
    //                     // (candidate) => candidate._id !== mpId
    //                 );
    //                 const candidateToMove = prevState[status].find(
    //                     // (candidate) => candidate._id === mpId
    //                 );

    //                 // Update candidate with addingStatus, currentState, and applicationID
    //                 const updatedCandidateToMove = {
    //                     ...candidateToMove,
    //                     addingStatus: true,
    //                     currentState,
    //                     applicationID,
    //                 };

    //                 return {
    //                     ...prevState,
    //                     [status]: updatedStatusCandidates,
    //                     processing: [...prevState.processing, updatedCandidateToMove],
    //                 };
    //             });
    //         } else if (handler === "procesCandidate") {
    //             setAllMatchingJDs((prevState) => {
    //                 return prevState.map((data) => {
    //                     if (data.jobDescriptionId._id === jobDescriptionId) {
    //                         return {
    //                             ...data,
    //                             addingStatus: true,
    //                             currentState,
    //                             applicationID,
    //                         };
    //                     }
    //                     return data;
    //                 });
    //             });
    //         }
    //         toast.success("Application Created Successfully!");
    //     } catch (err) {
    //         console.log("Error", err);
    //         let error = err.response
    //             ? err.response?.data?.message
    //             : err.message
    //             ? err.message
    //             : "Something Went Wrong";
    //         setError(error);
    //         toast.error("Error", error);
    //     } finally {
    //         setLoadingApplication(false);
    //     }
    // };

    const startApplication = async (candidateId, selectedRows, jobDescriptionId, status, mpId, handler) => {
        setLoadingApplication(true);
        setError(null);
        console.log(selectedRows);
        try {
            const token = userData?.token;
    
            // Determine if we need to send `candidateId` or `selectedRows`
            const candidates = selectedRows && selectedRows.length > 0 ? selectedRows : [candidateId];
            const response = await axios.post(
                `${backendUrl}/application/start`,
                {
                    candidateIds: candidates,
                    jobDescriptionId,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
    
            console.log("add candidate response", response.data);
    
            // Extract array of application processes from the response
            const applicationProcesses = response.data.applicationProcesses;
    
            if (handler === "procesJD") {
                setAllMatchingCandidates((prevState) => {
                    let updatedCandidates = { ...prevState };
    
                    applicationProcesses.forEach(({ candidate, currentState, applicationID }) => {
                        const updatedStatusCandidates = updatedCandidates[status].filter(
                            (c) => c._id !== candidate._id
                        );
                        const candidateToMove = updatedCandidates[status].find(
                            (c) => c._id === candidate._id
                        );
    
                        // Update candidate with addingStatus, currentState, and applicationID
                        if (candidateToMove) {
                            const updatedCandidateToMove = {
                                ...candidateToMove,
                                addingStatus: true,
                                currentState,
                                applicationID,
                            };
    
                            updatedCandidates = {
                                ...updatedCandidates,
                                [status]: updatedStatusCandidates,
                                processing: [...updatedCandidates.processing, updatedCandidateToMove],
                            };
                        }
                    });
    
                    return updatedCandidates;
                });
            } else if (handler === "procesCandidate") {
                setAllMatchingJDs((prevState) => {
                    return prevState.map((data) => {
                        if (data.jobDescriptionId._id === jobDescriptionId) {
                            const matchingProcess = applicationProcesses.find(
                                (process) => process.candidate._id === data.candidate._id
                            );
                            if (matchingProcess) {
                                return {
                                    ...data,
                                    addingStatus: true,
                                    currentState: matchingProcess.currentState,
                                    applicationID: matchingProcess.applicationID,
                                };
                            }
                        }
                        return data;
                    });
                });
            }
            toast.success("Application Created Successfully!");
        } catch (err) {
            console.log("Error", err);
            let error = err.response
                ? err.response?.data?.message
                : err.message
                ? err.message
                : "Something Went Wrong";
            setError(error);
            toast.error("Error", error);
        } finally {
            setLoadingApplication(false);
        }
    };

    // Fetch All Applications
    const fetchApplications = async (tab, status, page = 1, limit = 30, updateData = false) => {
        setLoadingApplication(true);
        try {
            if (!updateData && memoizedApplications[tab][status].length > 0) {
                setLoadingApplication(false);
                return;
            }

            let requestStatus = getStatusStoC(status);

            const token = userData?.token;
            const response = await axios.get(`${backendUrl}/application/all`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: { page, limit, tab, status: requestStatus },
            });

            const fetchedApplications = response.data;
            // console.log("fetchedApplications", fetchedApplications);

            if (updateData) {
                setAllApplications((prevState) => ({
                    ...prevState,
                    [tab]: {
                        ...prevState[tab],
                        [status]: [...prevState[tab][status], ...fetchedApplications],
                    },
                }));

                setApplicationsPageNo((prevState) => ({
                    ...prevState,
                    [tab]: {
                        ...prevState[tab],
                        [status]: prevState[tab][status] + 1,
                    },
                }));

                if (fetchedApplications.length < limit) {
                    setHasMoreApplications((prevState) => ({
                        ...prevState,
                        [tab]: {
                            ...prevState[tab],
                            [status]: false,
                        },
                    }));
                }
            } else {
                setAllApplications((prevState) => ({
                    ...prevState,
                    [tab]: {
                        ...prevState[tab],
                        [status]: fetchedApplications,
                    },
                }));

                setApplicationsPageNo((prevState) => ({
                    ...prevState,
                    [tab]: {
                        ...prevState[tab],
                        [status]: 2,
                    },
                }));

                setHasMoreApplications((prevState) => ({
                    ...prevState,
                    [tab]: {
                        ...prevState[tab],
                        [status]: fetchedApplications.length === limit,
                    },
                }));
            }

            toast.success(`Applications fetched Successfully!`);
        } catch (err) {
            console.log("Error", err);
            let error = err.response
                ? err.response?.data?.message
                : err.message
                ? err.message
                : "Something Went Wrong";
            setError(error);
            toast.error("Error", error);
        } finally {
            setLoadingApplication(false);
        }
    };

    // Forward CV
    const forwardCV = async (applicationId, status) => {
        setLoadingApplication(true);
        setError(null);

        try {
            const token = userData?.token;
            const response = await axios.put(
                `${backendUrl}/application/forward_cv`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: {
                        applicationId,
                    },
                }
            );

            const forwardCVRes = response.data;
            // console.log("forwardCVRes", forwardCVRes);
            const updatedApplication = forwardCVRes.applicationProcess;

            // Update the allApplications state
            setAllApplications((prevApplications) => {
                const updatedApplications = { ...prevApplications };

                // Update the 'all' array
                updatedApplications.applications.all = prevApplications.applications.all.map(
                    (app) => (app._id === applicationId ? updatedApplication : app)
                );

                // Remove the application from the current status array
                if (status in updatedApplications.applications) {
                    updatedApplications.applications[status] = prevApplications.applications[
                        status
                    ].filter((app) => app._id !== applicationId);
                }

                // Add the application to the 'onProcess' status array
                if (updatedApplications.applications.onProcess.length > 0) {
                    updatedApplications.applications.onProcess = [
                        updatedApplication,
                        ...prevApplications.applications.onProcess,
                    ];
                }

                // Add the application to the 'all' and 'pending' arrays in the cvProcess field
                if (updatedApplications.cvProcess.all.length > 0) {
                    updatedApplications.cvProcess.all = [
                        updatedApplication,
                        ...prevApplications.cvProcess.all,
                    ];
                }
                if (updatedApplications.cvProcess.pending.length > 0) {
                    updatedApplications.cvProcess.pending = [
                        updatedApplication,
                        ...prevApplications.cvProcess.pending,
                    ];
                }

                return updatedApplications;
            });

            toast.success("CV Forwarded Successfully!");
        } catch (err) {
            console.log("Error", err);
            let error = err.response
                ? err.response?.data?.message
                : err.message
                ? err.message
                : "Something Went Wrong";
            setError(error);
            toast.error("Error", error);
        } finally {
            setLoadingApplication(false);
        }
    };

    // Record CV Response
    const recordCvRes = async (applicationId, formData, prevStatus) => {
        setLoadingApplication(true);
        setError(null);

        try {
            const token = userData?.token;
            const response = await axios.put(
                `${backendUrl}/application/record_cv_res`,
                { result: formData.result, remark: formData.remark },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: {
                        applicationId,
                    },
                }
            );

            const recordCvRes = response.data;
            // console.log("recordCvRes", recordCvRes);
            const updatedApplication = recordCvRes.applicationProcess;

            const newStatus = getStatusCtoS(formData.result);
            prevStatus = getStatusCtoS(prevStatus);

            // Update the allApplications state
            setAllApplications((prevApplications) => {
                const updatedApplications = { ...prevApplications };

                // Update the 'applications.all' array
                updatedApplications.applications.all = prevApplications.applications.all.map(
                    (app) => (app._id === applicationId ? updatedApplication : app)
                );

                // Update the 'cvProcess.all' array
                updatedApplications.cvProcess.all = prevApplications.cvProcess.all.map((app) =>
                    app._id === applicationId ? updatedApplication : app
                );

                // Remove the application from the previous status array in cvProcess
                if (prevStatus in updatedApplications.cvProcess) {
                    updatedApplications.cvProcess[prevStatus] = prevApplications.cvProcess[
                        prevStatus
                    ].filter((app) => app._id !== applicationId);
                }

                // Add the application to the new status array in cvProcess if its length > 0
                if (
                    newStatus in updatedApplications.cvProcess &&
                    updatedApplications.cvProcess[newStatus].length > 0
                ) {
                    updatedApplications.cvProcess[newStatus] = [
                        updatedApplication,
                        ...prevApplications.cvProcess[newStatus],
                    ];
                }

                // Add the application to 'applications.rejected' if newStatus is rejected and its length > 0
                if (
                    newStatus === "rejected" &&
                    updatedApplications.applications.rejected.length > 0
                ) {
                    updatedApplications.applications.rejected = [
                        ...prevApplications.applications.rejected,
                        updatedApplication,
                    ];
                }

                // Remove the application from 'applications.rejected' if prevStatus is rejected and newStatus is not rejected
                if (prevStatus === "rejected" && newStatus !== "rejected") {
                    updatedApplications.applications.rejected =
                        prevApplications.applications.rejected.filter(
                            (app) => app._id !== applicationId
                        );
                }

                return updatedApplications;
            });

            toast.success(recordCvRes.message);
            return { success: true };
        } catch (err) {
            console.log("Error", err);
            let error = err.response
                ? err.response?.data?.message
                : err.message
                ? err.message
                : "Something Went Wrong";
            setError(error);
            toast.error("Error", error);
            return { success: false };
        } finally {
            setLoadingApplication(false);
        }
    };

    // Schedule Interview
    const scheduleInterview = async (applicationId, formData, isRescheduling) => {
        setLoadingApplication(true);
        setError(null);

        try {
            const token = userData?.token;
            const response = await axios.put(
                `${backendUrl}/application/schedule_interview`,
                { roundNo: formData.roundNo, scheduledOn: formData.scheduledOn },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: {
                        applicationId,
                    },
                }
            );

            const skdIntRes = response.data;
            // console.log("skdIntRes", skdIntRes);
            const updatedApplication = skdIntRes.applicationProcess;

            // Update the allApplications state
            setAllApplications((prevApplications) => {
                const updatedApplications = { ...prevApplications };

                if (formData.roundNo == 1) {
                    // Update the 'cvProcess.all' array
                    updatedApplications.cvProcess.all = prevApplications.cvProcess.all.map((app) =>
                        app._id === applicationId ? updatedApplication : app
                    );

                    // Update the 'cvProcess.selected' array
                    updatedApplications.cvProcess.selected =
                        prevApplications.cvProcess.selected.map((app) =>
                            app._id === applicationId ? updatedApplication : app
                        );
                }

                if (isRescheduling || formData.roundNo !== 1) {
                    // Update the 'interviewProcess.all' array
                    updatedApplications.interviewProcess.all =
                        prevApplications.interviewProcess.all.map((app) =>
                            app._id === applicationId ? updatedApplication : app
                        );

                    // Update the 'interviewProcess.onProcess' array
                    updatedApplications.interviewProcess.onProcess =
                        prevApplications.interviewProcess.onProcess.map((app) =>
                            app._id === applicationId ? updatedApplication : app
                        );
                } else {
                    // Add the application to 'interviewProcess.all' if its length > 0
                    if (updatedApplications.interviewProcess.all.length > 0) {
                        updatedApplications.interviewProcess.all = [
                            updatedApplication,
                            ...prevApplications.interviewProcess.all,
                        ];
                    }

                    // Add the application to 'interviewProcess.onProcess' if its length > 0
                    if (updatedApplications.interviewProcess.onProcess.length > 0) {
                        updatedApplications.interviewProcess.onProcess = [
                            updatedApplication,
                            ...prevApplications.interviewProcess.onProcess,
                        ];
                    }
                }
                return updatedApplications;
            });

            toast.success(skdIntRes.message);
            return { success: true };
        } catch (err) {
            console.log("Error", err);
            let error = err.response
                ? err.response?.data?.message
                : err.message
                ? err.message
                : "Something Went Wrong";
            setError(error);
            toast.error("Error", error);
            return { success: false };
        } finally {
            setLoadingApplication(false);
        }
    };

    // Helper Function for Updating AllApplications state after Recording Interview Results
    // Helper function to update interviewProcess arrays
    const updateInterviewProcess = (
        prevApplications,
        applicationId,
        updatedApplication,
        prevStatus,
        newStatus
    ) => {
        const updatedApplications = { ...prevApplications };

        // Remove the Application from [prevStatus] array
        updatedApplications.interviewProcess[prevStatus] = prevApplications.interviewProcess[
            prevStatus
        ].filter((app) => app._id !== applicationId);

        // Add the updatedApplication to the [newStatus] array
        if (updatedApplications.interviewProcess[newStatus].length > 0) {
            updatedApplications.interviewProcess[newStatus] = [
                updatedApplication,
                ...prevApplications.interviewProcess[newStatus],
            ];
        }

        return updatedApplications;
    };

    // Helper function to update applications.all and interviewProcess.all array
    const updateAllApplications = (prevApplications, applicationId, updatedApplication) => {
        const updatedApplications = { ...prevApplications };

        // Update the 'applications.all' array
        updatedApplications.applications.all = prevApplications.applications.all.map((app) =>
            app._id === applicationId ? updatedApplication : app
        );

        // Update the 'interviewProcess.all' array
        updatedApplications.interviewProcess.all = prevApplications.interviewProcess.all.map(
            (app) => (app._id === applicationId ? updatedApplication : app)
        );

        return updatedApplications;
    };

    // Helper function to update applications.rejected array
    const updateRejectedApplications = (
        prevApplications,
        applicationId,
        updatedApplication,
        remove = false
    ) => {
        const updatedApplications = { ...prevApplications };

        if (remove) {
            // Remove it from reject array
            updatedApplications.applications.rejected =
                prevApplications.applications.rejected.filter((app) => app._id !== applicationId);
        } else {
            // Add the updatedApplication to the rejected array
            if (updatedApplications.applications.rejected.length > 0) {
                updatedApplications.applications.rejected = [
                    updatedApplication,
                    ...prevApplications.applications.rejected,
                ];
            }
        }

        return updatedApplications;
    };

    // Record Interview Results API Call
    const recordInterviewRes = async (
        applicationId,
        formData,
        isUpdatingResult,
        previousResult
    ) => {
        setLoadingApplication(true);
        setError(null);
        // console.log("applicationId", applicationId, "formData", formData);
        // return;
        try {
            const token = userData?.token;
            const response = await axios.put(
                `${backendUrl}/application/record_interview_res`,
                {
                    roundNo: formData.roundNo,
                    result: formData.result,
                    remarks: formData.remarks,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: {
                        applicationId,
                    },
                }
            );

            const recIntRes = response.data;
            // console.log("recIntRes", recIntRes);
            const updatedApplication = recIntRes.applicationProcess;

            // Update the allApplications state
            setAllApplications((prevApplications) => {
                let updatedApplications = updateAllApplications(
                    prevApplications,
                    applicationId,
                    updatedApplication
                );

                if (isUpdatingResult) {
                    if (formData.result === "Passed") {
                        if (recIntRes.isPassedAllInterview) {
                            if (previousResult === "Failed") {
                                updatedApplications = updateRejectedApplications(
                                    updatedApplications,
                                    applicationId,
                                    updatedApplication,
                                    true
                                );
                                updatedApplications = updateInterviewProcess(
                                    updatedApplications,
                                    applicationId,
                                    updatedApplication,
                                    "rejected",
                                    "selected"
                                );
                            } else if (previousResult === "Passed") {
                                updatedApplications.interviewProcess.selected =
                                    updatedApplications.interviewProcess.selected.map((app) =>
                                        app._id === applicationId ? updatedApplication : app
                                    );
                            } else if (previousResult === "Pending") {
                                updatedApplications = updateInterviewProcess(
                                    updatedApplications,
                                    applicationId,
                                    updatedApplication,
                                    "onProcess",
                                    "selected"
                                );
                            }
                        } else {
                            if (previousResult === "Failed") {
                                updatedApplications = updateRejectedApplications(
                                    updatedApplications,
                                    applicationId,
                                    updatedApplication,
                                    true
                                );
                                updatedApplications = updateInterviewProcess(
                                    updatedApplications,
                                    applicationId,
                                    updatedApplication,
                                    "rejected",
                                    "onProcess"
                                );
                            } else {
                                updatedApplications.interviewProcess.onProcess =
                                    updatedApplications.interviewProcess.onProcess.map((app) =>
                                        app._id === applicationId ? updatedApplication : app
                                    );
                            }
                        }
                    } else if (formData.result === "Failed") {
                        if (previousResult === "Failed") {
                            updatedApplications = updateRejectedApplications(
                                updatedApplications,
                                applicationId,
                                updatedApplication
                            );
                            updatedApplications.interviewProcess.rejected =
                                updatedApplications.interviewProcess.rejected.map((app) =>
                                    app._id === applicationId ? updatedApplication : app
                                );
                        } else if (previousResult === "Passed") {
                            if (recIntRes.isPassedAllInterview) {
                                updatedApplications = updateInterviewProcess(
                                    updatedApplications,
                                    applicationId,
                                    updatedApplication,
                                    "selected",
                                    "rejected"
                                );
                                updatedApplications = updateRejectedApplications(
                                    updatedApplications,
                                    applicationId,
                                    updatedApplication
                                );
                            } else {
                                updatedApplications = updateInterviewProcess(
                                    updatedApplications,
                                    applicationId,
                                    updatedApplication,
                                    "onProcess",
                                    "rejected"
                                );
                                updatedApplications = updateRejectedApplications(
                                    updatedApplications,
                                    applicationId,
                                    updatedApplication
                                );
                            }
                        } else if (previousResult === "Pending") {
                            updatedApplications = updateInterviewProcess(
                                updatedApplications,
                                applicationId,
                                updatedApplication,
                                "onProcess",
                                "rejected"
                            );
                            updatedApplications = updateRejectedApplications(
                                updatedApplications,
                                applicationId,
                                updatedApplication
                            );
                        }
                    } else if (formData.result === "Pending") {
                        if (previousResult === "Failed") {
                            updatedApplications = updateRejectedApplications(
                                updatedApplications,
                                applicationId,
                                updatedApplication,
                                true
                            );
                            updatedApplications = updateInterviewProcess(
                                updatedApplications,
                                applicationId,
                                updatedApplication,
                                "rejected",
                                "onProcess"
                            );
                        } else if (previousResult === "Passed" && recIntRes.isPassedAllInterview) {
                            updatedApplications = updateInterviewProcess(
                                updatedApplications,
                                applicationId,
                                updatedApplication,
                                "selected",
                                "onProcess"
                            );
                        } else {
                            updatedApplications.interviewProcess.onProcess =
                                updatedApplications.interviewProcess.onProcess.map((app) =>
                                    app._id === applicationId ? updatedApplication : app
                                );
                        }
                    }
                } else {
                    if (formData.result === "Passed" && recIntRes.isPassedAllInterview) {
                        updatedApplications = updateInterviewProcess(
                            updatedApplications,
                            applicationId,
                            updatedApplication,
                            "onProcess",
                            "selected"
                        );
                    } else if (formData.result === "Failed") {
                        updatedApplications = updateInterviewProcess(
                            updatedApplications,
                            applicationId,
                            updatedApplication,
                            "onProcess",
                            "rejected"
                        );
                        updatedApplications = updateRejectedApplications(
                            updatedApplications,
                            applicationId,
                            updatedApplication
                        );
                    } else {
                        updatedApplications.interviewProcess.onProcess =
                            updatedApplications.interviewProcess.onProcess.map((app) =>
                                app._id === applicationId ? updatedApplication : app
                            );
                    }
                }

                return updatedApplications;
            });

            toast.success(recIntRes.message);
            return { success: true };
        } catch (err) {
            console.log("Error", err);
            let error = err.response
                ? err.response?.data?.message
                : err.message
                ? err.message
                : "Something Went Wrong";
            setError(error);
            toast.error("Error", error);
            return { success: false };
        } finally {
            setLoadingApplication(false);
        }
    };

    // Record Offer
    const recordOffer = async (applicationId, formData) => {
        setLoadingApplication(true);
        setError(null);

        try {
            const token = userData?.token;
            const response = await axios.put(`${backendUrl}/application/record_offer`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    applicationId,
                },
            });

            const recOfferRes = response.data;
            // console.log("recOfferRes", recOfferRes);
            const updatedApplication = recOfferRes.applicationProcess;

            // Update the allApplications state
            setAllApplications((prevApplications) => {
                const updatedApplications = { ...prevApplications };

                // Update the 'applications.all' array
                updatedApplications.applications.all = prevApplications.applications.all.map(
                    (app) => (app._id === applicationId ? updatedApplication : app)
                );

                // Update the 'interviewProcess.all' array
                updatedApplications.interviewProcess.all =
                    prevApplications.interviewProcess.all.map((app) =>
                        app._id === applicationId ? updatedApplication : app
                    );

                // Update the 'interviewProcess.selected' array
                updatedApplications.interviewProcess.selected =
                    prevApplications.interviewProcess.selected.map((app) =>
                        app._id === applicationId ? updatedApplication : app
                    );

                // Add the application to the 'all' and 'pending' arrays in the offerProcess field
                if (updatedApplications.offerProcess.all.length > 0) {
                    updatedApplications.offerProcess.all = [
                        updatedApplication,
                        ...prevApplications.offerProcess.all,
                    ];
                }
                if (updatedApplications.offerProcess.pending.length > 0) {
                    updatedApplications.offerProcess.pending = [
                        updatedApplication,
                        ...prevApplications.offerProcess.pending,
                    ];
                }

                return updatedApplications;
            });

            toast.success(recOfferRes.message);
            return { success: true };
        } catch (err) {
            console.log("Error", err);
            let error = err.response
                ? err.response?.data?.message
                : err.message
                ? err.message
                : "Something Went Wrong";
            setError(error);
            toast.error("Error", error);
            return { success: false };
        } finally {
            setLoadingApplication(false);
        }
    };

    // Record Candidate Response to Offer
    const recordCanOfferRes = async (applicationId, formData, isUpdatingResult, prevStatus) => {
        setLoadingApplication(true);
        setError(null);

        try {
            const token = userData?.token;
            const response = await axios.put(
                `${backendUrl}/application/record_res_offer`,
                { offerStatus: formData.offerStatus, remarks: formData.remarks },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: {
                        applicationId,
                    },
                }
            );

            const recordCanOfferRes = response.data;
            // console.log("recordCanOfferRes", recordCanOfferRes);
            const updatedApplication = recordCanOfferRes.applicationProcess;

            const newStatus = getStatusCtoS(formData.offerStatus);
            prevStatus = getStatusCtoS(prevStatus);

            // Update the allApplications state
            setAllApplications((prevApplications) => {
                const updatedApplications = { ...prevApplications };

                // Update the 'applications.all' array
                updatedApplications.applications.all = prevApplications.applications.all.map(
                    (app) => (app._id === applicationId ? updatedApplication : app)
                );

                // Update the 'offerProcess.all' array
                updatedApplications.offerProcess.all = prevApplications.offerProcess.all.map(
                    (app) => (app._id === applicationId ? updatedApplication : app)
                );

                if (isUpdatingResult && newStatus == prevStatus) {
                    // update offerProcess[prevStatus] array
                    updatedApplications.offerProcess[prevStatus] = prevApplications.offerProcess[
                        prevStatus
                    ].map((app) => (app._id === applicationId ? updatedApplication : app));
                } else {
                    // Remove the application from the previous status array in offerProcess
                    if (prevStatus in updatedApplications.offerProcess) {
                        updatedApplications.offerProcess[prevStatus] =
                            prevApplications.offerProcess[prevStatus].filter(
                                (app) => app._id !== applicationId
                            );
                    }

                    // Add the application to the new status array in offerProcess if its length > 0
                    if (
                        newStatus in updatedApplications.offerProcess &&
                        updatedApplications.offerProcess[newStatus].length > 0
                    ) {
                        updatedApplications.offerProcess[newStatus] = [
                            updatedApplication,
                            ...prevApplications.offerProcess[newStatus],
                        ];
                    }
                }

                if (
                    !isUpdatingResult &&
                    formData.offerStatus == "Rejected" &&
                    updatedApplications.applications.rejected.length > 0
                ) {
                    // add it to applications.rejected
                    updatedApplications.applications.rejected = [
                        updatedApplication,
                        ...prevApplications.applications.rejected,
                    ];
                } else if (
                    isUpdatingResult &&
                    formData.offerStatus == "Rejected" &&
                    newStatus == prevStatus
                ) {
                    // update applications.rejected
                    updatedApplications.applications.rejected =
                        prevApplications.applications.rejected.map((app) =>
                            app._id === applicationId ? updatedApplication : app
                        );
                }

                return updatedApplications;
            });

            toast.success(recordCanOfferRes.message);
            return { success: true };
        } catch (err) {
            console.log("Error", err);
            let error = err.response
                ? err.response?.data?.message
                : err.message
                ? err.message
                : "Something Went Wrong";
            setError(error);
            toast.error("Error", error);
            return { success: false };
        } finally {
            setLoadingApplication(false);
        }
    };

    // Record Offer
    const recordJoiningDetails = async (applicationId, formData) => {
        setLoadingApplication(true);
        setError(null);

        try {
            const token = userData?.token;
            const response = await axios.put(
                `${backendUrl}/application/record_placement`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: {
                        applicationId,
                    },
                }
            );

            const recJoiningAPiRes = response.data;
            // console.log("recJoiningAPiRes", recJoiningAPiRes);
            const updatedApplication = recJoiningAPiRes.applicationProcess;

            // Update the allApplications state
            setAllApplications((prevApplications) => {
                const updatedApplications = { ...prevApplications };

                // Update the 'applications.all' array
                updatedApplications.applications.all = prevApplications.applications.all.map(
                    (app) => (app._id === applicationId ? updatedApplication : app)
                );

                // Update the 'offerProcess.all' array
                updatedApplications.offerProcess.all = prevApplications.offerProcess.all.map(
                    (app) => (app._id === applicationId ? updatedApplication : app)
                );

                // Update the 'offerProcess.accepted' array
                updatedApplications.offerProcess.accepted =
                    prevApplications.offerProcess.accepted.map((app) =>
                        app._id === applicationId ? updatedApplication : app
                    );

                // Add the application to the 'all' and 'pending' arrays in the placementProcess field
                if (updatedApplications.placementProcess.all.length > 0) {
                    updatedApplications.placementProcess.all = [
                        updatedApplication,
                        ...prevApplications.placementProcess.all,
                    ];
                }
                if (updatedApplications.placementProcess.pending.length > 0) {
                    updatedApplications.placementProcess.pending = [
                        updatedApplication,
                        ...prevApplications.placementProcess.pending,
                    ];
                }

                return updatedApplications;
            });

            toast.success(recJoiningAPiRes.message);
            return { success: true };
        } catch (err) {
            console.log("Error", err);
            let error = err.response
                ? err.response?.data?.message
                : err.message
                ? err.message
                : "Something Went Wrong";
            setError(error);
            toast.error("Error", error);
            return { success: false };
        } finally {
            setLoadingApplication(false);
        }
    };

    // Record Candidate Joining Status
    const recordCandJoiningRes = async (applicationId, formData, isUpdatingResult, prevStatus) => {
        setLoadingApplication(true);
        setError(null);

        try {
            const token = userData?.token;
            const response = await axios.put(
                `${backendUrl}/application/record_placement_update`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: {
                        applicationId,
                    },
                }
            );

            const recordCanJoiningRes = response.data;
            // console.log("recordCanJoiningRes", recordCanJoiningRes);
            const updatedApplication = recordCanJoiningRes.applicationProcess;

            const newStatus = getStatusCtoS(updatedApplication.placementDetails.candidateResponse);
            prevStatus = getStatusCtoS(prevStatus);

            // Update the allApplications state
            setAllApplications((prevApplications) => {
                const updatedApplications = { ...prevApplications };

                // Update the 'applications.all' array
                updatedApplications.applications.all = prevApplications.applications.all.map(
                    (app) => (app._id === applicationId ? updatedApplication : app)
                );

                // Update the 'placementProcess.all' array
                updatedApplications.placementProcess.all =
                    prevApplications.placementProcess.all.map((app) =>
                        app._id === applicationId ? updatedApplication : app
                    );

                if (isUpdatingResult && newStatus == prevStatus) {
                    // update placementProcess[prevStatus] array
                    updatedApplications.placementProcess[prevStatus] =
                        prevApplications.placementProcess[prevStatus].map((app) =>
                            app._id === applicationId ? updatedApplication : app
                        );
                } else {
                    // Remove the application from the previous status array in placementProcess
                    if (prevStatus in updatedApplications.placementProcess) {
                        updatedApplications.placementProcess[prevStatus] =
                            prevApplications.placementProcess[prevStatus].filter(
                                (app) => app._id !== applicationId
                            );
                    }

                    // Add the application to the new status array in placementProcess if its length > 0
                    if (
                        newStatus in updatedApplications.placementProcess &&
                        updatedApplications.placementProcess[newStatus].length > 0
                    ) {
                        updatedApplications.placementProcess[newStatus] = [
                            updatedApplication,
                            ...prevApplications.placementProcess[newStatus],
                        ];
                    }
                }

                if (
                    !isUpdatingResult &&
                    updatedApplication.placementDetails.candidateResponse == "Rejected" &&
                    updatedApplications.applications.rejected.length > 0
                ) {
                    // add it to applications.rejected
                    updatedApplications.applications.rejected = [
                        updatedApplication,
                        ...prevApplications.applications.rejected,
                    ];
                } else if (
                    isUpdatingResult &&
                    updatedApplication.placementDetails.candidateResponse == "Rejected" &&
                    newStatus == prevStatus
                ) {
                    // update applications.rejected
                    updatedApplications.applications.rejected =
                        prevApplications.applications.rejected.map((app) =>
                            app._id === applicationId ? updatedApplication : app
                        );
                }

                return updatedApplications;
            });

            toast.success(recordCanJoiningRes.message);
            return { success: true };
        } catch (err) {
            console.log("Error", err);
            let error = err.response
                ? err.response?.data?.message
                : err.message
                ? err.message
                : "Something Went Wrong";
            setError(error);
            toast.error("Error", error);
            return { success: false };
        } finally {
            setLoadingApplication(false);
        }
    };

    // Mark Candidate Complted MBP
    const markCanComMBP = async (application, formData, isUpdating) => {
        setLoadingApplication(true);
        setError(null);

        const applicationId = application._id;
        try {
            const token = userData?.token;
            const response = await axios.put(
                `${backendUrl}/application/mark_can_comp_mbp`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: {
                        applicationId,
                    },
                }
            );

            const recordApiRes = response.data;
            // console.log("recordApiRes", recordApiRes);
            const updatedApplication = recordApiRes.applicationProcess;
            const updatedBill = recordApiRes.bill;

            // Update the allApplications state
            setAllApplications((prevApplications) => {
                const updatedApplications = { ...prevApplications };

                // Update the 'applications.all' array
                updatedApplications.applications.all = prevApplications.applications.all.map(
                    (app) => (app._id === applicationId ? updatedApplication : app)
                );

                // Update the 'placementProcess.all' array
                updatedApplications.placementProcess.all =
                    prevApplications.placementProcess.all.map((app) =>
                        app._id === applicationId ? updatedApplication : app
                    );

                // Update the 'placementProcess.accepted' array
                updatedApplications.placementProcess.accepted =
                    prevApplications.placementProcess.accepted.map((app) =>
                        app._id === applicationId ? updatedApplication : app
                    );

                return updatedApplications;
            });

            toast.success(recordApiRes.message);
            return { success: true };
        } catch (err) {
            console.log("Error", err);
            let error = err.response
                ? err.response?.data?.message
                : err.message
                ? err.message
                : "Something Went Wrong";
            setError(error);
            toast.error("Error", error);
            return { success: false };
        } finally {
            setLoadingApplication(false);
        }
    };

    // Reject Application
    const rejectApplication = async (applicationId, status) => {
        // Prompt the user for confirmation
        const confirmation = window.prompt('Type "reject application" to confirm the rejection:');
        if (confirmation !== "reject application") {
            toast.error("Rejection cancelled or incorrect phrase entered.");
            return;
        }

        // Prompt the user for rejection remarks
        const rejectionRemarks = window.prompt("Please enter rejection remarks:");

        if (!rejectionRemarks || rejectionRemarks.trim() === "") {
            toast.error("Rejection remarks cannot be empty.");
            return;
        }
        setLoadingApplication(true);
        setError(null);

        try {
            const token = userData?.token;
            const response = await axios.put(
                `${backendUrl}/application/reject_application`,
                { rejectionRemarks },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: {
                        applicationId,
                    },
                }
            );

            const rejectApplicationRes = response.data;
            // console.log("rejectApplicationRes", rejectApplicationRes);
            const updatedApplication = rejectApplicationRes.applicationProcess;

            // Update the allApplications state
            setAllApplications((prevApplications) => {
                // Update the 'all' array
                const updatedAll = prevApplications.all.map((app) =>
                    app._id === applicationId ? updatedApplication : app
                );

                // Remove the application from the current status array
                const updatedCurrentStatusArray = prevApplications[status].filter(
                    (app) => app._id !== applicationId
                );

                // Add the application to the 'rejected' status array
                const updatedRejected = [...prevApplications.rejected, updatedApplication];

                return {
                    ...prevApplications,
                    all: updatedAll,
                    [status]: updatedCurrentStatusArray,
                    rejected: updatedRejected,
                };
            });

            toast.success("Application Rejected Successfully!");
        } catch (err) {
            console.log("Error", err);
            let error = err.response
                ? err.response?.data?.message
                : err.message
                ? err.message
                : "Something Went Wrong";
            setError(error);
            toast.error("Error", error);
            toast.error("Error", error);
        } finally {
            setLoadingApplication(false);
        }
    };

    // Revert Rejection
    const revertRejection = async (applicationId, status) => {
        // Prompt the user for confirmation
        const confirmation = window.prompt(
            'Type "Revert Application Rejection" to confirm the deletion:'
        );

        if (confirmation !== "Revert Application Rejection") {
            toast.error("Revert Application Rejection cancelled or incorrect phrase entered.");
            return;
        }

        setLoadingApplication(true);
        setError(null);

        try {
            const token = userData?.token;
            const response = await axios.put(
                `${backendUrl}/application/revert_rejection`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: {
                        applicationId,
                    },
                }
            );

            const revertRejectionRes = response.data;
            // console.log("revertRejectionRes", revertRejectionRes);
            const updatedApplication = revertRejectionRes.applicationProcess;

            let previousStatus = getStatusCtoS(updatedApplication.rejectionPreviousStatus);

            // Update the allApplications state
            setAllApplications((prevApplications) => {
                // Update the 'all' array
                const updatedAll = prevApplications.all.map((app) =>
                    app._id === applicationId ? updatedApplication : app
                );

                // Remove the application from the 'rejected' status array
                const updatedRejected = prevApplications.rejected.filter(
                    (app) => app._id !== applicationId
                );

                // Add the application to the previousStatus array
                const updatedPreviousStateData = [
                    ...prevApplications[previousStatus],
                    updatedApplication,
                ];

                return {
                    ...prevApplications,
                    all: updatedAll,
                    rejected: updatedRejected,
                    [previousStatus]: updatedPreviousStateData,
                };
            });

            toast.success(
                `Rejection Reverted Successfully! Now you can find this application in "${updatedApplication.rejectionPreviousStatus}" tab`
            );
        } catch (err) {
            console.log("Error", err);
            let error = err.response
                ? err.response?.data?.message
                : err.message
                ? err.message
                : "Something Went Wrong";
            setError(error);
            toast.error("Error", error);
        } finally {
            setLoadingApplication(false);
        }
    };

    return (
        <ApplicationContext.Provider
            value={{
                loadingApplication,
                allApplications,
                hasMoreApplications,
                applicationsPageNo,
                startApplication,
                fetchApplications,
                forwardCV,
                recordCvRes,
                scheduleInterview,
                recordInterviewRes,
                recordOffer,
                recordCanOfferRes,
                recordJoiningDetails,
                recordCandJoiningRes,
                markCanComMBP,
                rejectApplication,
                revertRejection,
            }}
        >
            {children}
        </ApplicationContext.Provider>
    );
};

export default ApplicationContext;

export const useApplication = () => useContext(ApplicationContext);
