import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Tabs, TabList, TabPanels, Tab, TabPanel, Text } from "@chakra-ui/react";

import InterviewProcessHeader from "./InterviewProcessHeader";
import InterviewProcessBody from "./InterviewProcessBody";
import { tagColor } from "../../../Utils/GlobalFunctions/TagColor";
import { useApplication } from "../../../context/ApplicationContext";
import { useAuth } from "../../../context/AuthContext";

const CVProcessContainer = () => {
    const { loading, allApplications, hasMoreApplications, applicationsPageNo, fetchApplications } =
        useApplication();
    const { userData } = useAuth();

    const [tabIndex, setTabIndex] = useState(0);
    const { status } = useParams();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const initialPath = pathname.split("/")[1];

    useEffect(() => {
        // //console.log("userData**", userData);

        switch (status) {
            case "all":
                fetchApplications("interviewProcess", "all", 1, 30);
                setTabIndex(0);
                break;
            case "selected":
                fetchApplications("interviewProcess", "selected", 1, 30);
                setTabIndex(1);
                break;
            case "rejected":
                fetchApplications("interviewProcess", "rejected", 1, 30);
                setTabIndex(2);
                break;
            case "onProcess":
                fetchApplications("interviewProcess", "onProcess", 1, 30);
                setTabIndex(3);
                break;
            default:
                fetchApplications("interviewProcess", "onProcess", 1, 30);
                setTabIndex(3);
                break;
        }
    }, [status]);

    const handleTabsChange = (index) => {
        setTabIndex(index);
        const tabMapping = ["all", "selected", "rejected", "onProcess"];
        navigate(`/${initialPath}/InterviewProcess/${tabMapping[index]}`);
    };

    const applicationData = {};
    const [filteredData, setFilteredData] = useState(applicationData);

    const filterData = (query) => {
        //console.log("query", query);
        if (!query) {
            setFilteredData(applicationData);
            return;
        }

        const filtered = applicationData.filter((item) => {
            return deepSearch(item, query);
        });

        //console.log("filtered", filtered);
        setFilteredData(filtered);
    };

    const deepSearch = (obj, query) => {
        for (const key in obj) {
            const value = obj[key];
            if (typeof value === "object") {
                if (deepSearch(value, query)) {
                    return true;
                }
            } else if (
                typeof value === "string" ||
                typeof value === "number" ||
                typeof value === "boolean"
            ) {
                if (String(value).toLowerCase().includes(query.toLowerCase())) {
                    return true;
                }
            } else if (Array.isArray(value)) {
                for (const item of value) {
                    if (typeof item === "object") {
                        if (deepSearch(item, query)) {
                            return true;
                        }
                    } else if (
                        typeof item === "string" ||
                        typeof item === "number" ||
                        typeof item === "boolean"
                    ) {
                        if (String(item).toLowerCase().includes(query.toLowerCase())) {
                            return true;
                        }
                    }
                }
            }
        }
        return false;
    };

    const filterByStatus = (status) => {
        return filteredData.filter((item) => item.cvSelectionDetails.result === status);
    };
    return (
        <>
            <Tabs isLazy variant="enclosed" index={tabIndex} onChange={handleTabsChange}>
                {/* <InterviewProcessHeader filterData={filterData} /> */}
                <TabList>
                    <Tab>
                        <Text
                            bg={tagColor("").bg}
                            color={tagColor("").clr}
                            px={2}
                            borderRadius={20}
                            fontWeight="bold"
                            fontSize={"sm"}
                        >
                            All
                        </Text>
                    </Tab>
                    <Tab>
                        <Text
                            bg={tagColor("green").bg}
                            color={tagColor("green").clr}
                            px={2}
                            borderRadius={20}
                            fontWeight="bold"
                            fontSize={"sm"}
                        >
                            Selected
                        </Text>
                    </Tab>
                    <Tab>
                        <Text
                            bg={tagColor("red").bg}
                            color={tagColor("red").clr}
                            px={2}
                            borderRadius={20}
                            fontWeight="bold"
                            fontSize={"sm"}
                        >
                            Rejected
                        </Text>
                    </Tab>
                    <Tab>
                        <Text
                            bg={tagColor("blue").bg}
                            color={tagColor("blue").clr}
                            px={2}
                            borderRadius={20}
                            fontWeight="bold"
                            fontSize={"sm"}
                        >
                            On Process
                        </Text>
                    </Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <InterviewProcessBody
                            data={allApplications?.interviewProcess?.all}
                            fetchAllApplications={fetchApplications}
                            hasMore={hasMoreApplications?.interviewProcess?.all}
                            pageNo={applicationsPageNo?.interviewProcess?.all}
                            status={"all"}
                            loading={loading}
                        />
                    </TabPanel>
                    <TabPanel>
                        <InterviewProcessBody
                            data={allApplications?.interviewProcess?.selected}
                            fetchAllApplications={fetchApplications}
                            hasMore={hasMoreApplications?.interviewProcess?.selected}
                            pageNo={applicationsPageNo?.interviewProcess?.selected}
                            status={"selected"}
                            loading={loading}
                        />
                    </TabPanel>
                    <TabPanel>
                        <InterviewProcessBody
                            data={allApplications?.interviewProcess?.rejected}
                            fetchAllApplications={fetchApplications}
                            hasMore={hasMoreApplications?.interviewProcess?.rejected}
                            pageNo={applicationsPageNo?.interviewProcess?.rejected}
                            status={"rejected"}
                            loading={loading}
                        />
                    </TabPanel>
                    <TabPanel>
                        <InterviewProcessBody
                            data={allApplications?.interviewProcess?.onProcess}
                            fetchAllApplications={fetchApplications}
                            hasMore={hasMoreApplications?.interviewProcess?.onProcess}
                            pageNo={applicationsPageNo?.interviewProcess?.onProcess}
                            status={"onProcess"}
                            loading={loading}
                        />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </>
    );
};

export default CVProcessContainer;
