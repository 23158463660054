export function tagColor(input) {
    let bg, clr;
    switch (input) {
        case "green":
            bg = "#9ae6b429";
            clr = "#9ae6b4";
            break;
        case "blue":
            bg = "#90cdf429";
            clr = "#90cdf4";
            break;
        case "orange":
            bg = "#fbd38d29";
            clr = "#fbd38d";
            break;
        case "red":
            bg = "#feb2b229";
            clr = "#feb2b2";
            break;
        case "yellow":
            bg = "#faf08929";
            clr = "#faf089";
            break;
        case "purple":
            bg = "#d6bcfa29";
            clr = "#d6bcfa";
            break;
        default:
            bg = "#ffffff29";
            clr = "#a9a9a9";
    }
    return { bg, clr };
}
