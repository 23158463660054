import { Box, Button, Flex, Skeleton, Tag, Text } from "@chakra-ui/react";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    Tooltip,
} from "@chakra-ui/react";
import { formatToShortDate } from "../../../Utils/GlobalFunctions/FormatDate";
import { percentageToColor } from "../../../Utils/GlobalFunctions/ColorAccPer";
import { GiNetworkBars } from "react-icons/gi";

const JDList = ({ isCollapsed, loading, dashJDData }) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const initialPath = pathname.split("/")[1];

    const handleViewAllJD = (jdList) => {
        navigate(`/${initialPath}/JobDescriptions/dashboardData`, {
            state: {
                jdList: jdList,
            },
        });
    };

    return (
        <div>
            {" "}
            <Box
                h={"420px"}
                minW={"400px"}
                bg={"#22242b"}
                borderRadius={"md"}
                p={4}
                overflowY={"clip"}
                position={"relative"}
            >
                <Flex w={"100%"} justify={"space-between"} mb={2}>
                    <Flex direction={"column"}>
                        <Text
                            fontSize={"sm"}
                            fontWeight={"bold"}
                            color={"white"}
                            textAlign={"left"}
                        >
                            Job Descriptions
                        </Text>
                        <Text
                            fontSize={"xs"}
                            mt="-1px"
                            color={"#ffffff9c"}
                            textAlign={"left"}
                            mb={1}
                        >
                            Prioritized Based on Importance
                        </Text>
                    </Flex>
                    {!loading && dashJDData?.jobDescriptionsInPriorityOrder && (
                        <Button
                            colorScheme="gray"
                            size="xs"
                            onClick={() => {
                                handleViewAllJD(dashJDData?.jobDescriptionsInPriorityOrder);
                            }}
                        >
                            View Details
                        </Button>
                    )}
                </Flex>
                <TableContainer
                    h={"350px"}
                    overflowY={"auto"}
                    overflowX={"clip"}
                    position={"relative"}
                >
                    <Table size="sm">
                        <Thead>
                            <Tr position={"sticky"} top={0} bg={"#22242B"} zIndex={1}>
                                <Th>Job</Th>
                                <Th>Closing</Th>
                                {isCollapsed && (
                                    <Th>
                                        <Tooltip
                                            hasArrow
                                            label="Applications in Numbers"
                                            bg="gray.300"
                                            color="black"
                                            placement="top"
                                            maxW={"200px"}
                                        >
                                            APN
                                        </Tooltip>
                                    </Th>
                                )}
                                <Th>Closed</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {loading ? (
                                <>
                                    {Array.from({ length: 10 }).map((_, index) => (
                                        <Tr key={index}>
                                            <Td colSpan={5} px={0}>
                                                <Skeleton height="30px" />
                                            </Td>
                                        </Tr>
                                    ))}
                                </>
                            ) : !dashJDData ? (
                                <Tr>
                                    <Td colSpan={5} px={0} border={0}>
                                        <Text textAlign={"center"}>No Data Found</Text>
                                    </Td>
                                </Tr>
                            ) : (
                                dashJDData?.jobDescriptionsInPriorityOrder.map((jd, index) => (
                                    <Tr key={index}>
                                        <Td borderStyle={"dashed"} pl={0}>
                                            <Flex direction={"column"} gap={"2px"}>
                                                <Text
                                                    style={{
                                                        textOverflow: "ellipsis",
                                                        overflow: "hidden",
                                                        maxWidth: "180px",
                                                    }}
                                                >
                                                    {jd?.jobTitle}
                                                </Text>
                                                <Text fontSize={"xs"} color={"#ffffff9c"}>
                                                    {jd?.clientDetails?.client?.name}
                                                </Text>
                                            </Flex>
                                        </Td>
                                        <Td borderStyle={"dashed"}>
                                            <Flex direction={"column"} gap={"2px"}>
                                                <Text>{formatToShortDate(jd?.closingDate)}</Text>
                                                <Text
                                                    fontSize={"xs"}
                                                    color={percentageToColor(
                                                        jd?.remainingDaysInPercentage
                                                    )}
                                                >
                                                    {jd?.daysLeft} Days Left
                                                </Text>
                                            </Flex>
                                        </Td>
                                        {isCollapsed && (
                                            <Td borderStyle={"dashed"}>
                                                <Flex align={"center"} gap={"2px"}>
                                                    <Flex direction={"column"} gap={"2px"}>
                                                        <Tooltip
                                                            hasArrow
                                                            label="Selected"
                                                            bg="#364341"
                                                            color="#9AE6B4"
                                                            placement="left"
                                                        >
                                                            <Tag
                                                                size={"xs"}
                                                                variant="subtle"
                                                                colorScheme="green"
                                                                fontSize={"2xs"}
                                                                display={"flex"}
                                                                justifyContent={"center"}
                                                                px={1}
                                                            >
                                                                {jd?.noOfSelectedApplications}
                                                            </Tag>
                                                        </Tooltip>
                                                        <Tooltip
                                                            hasArrow
                                                            label="Remaining"
                                                            bg="#45453A"
                                                            color="#FAF089"
                                                            placement="left"
                                                        >
                                                            <Tag
                                                                size={"xs"}
                                                                variant="subtle"
                                                                colorScheme="orange"
                                                                fontSize={"2xs"}
                                                                display={"flex"}
                                                                justifyContent={"center"}
                                                                px={1}
                                                            >
                                                                {jd?.noOfRemainingClosings}
                                                            </Tag>
                                                        </Tooltip>
                                                    </Flex>
                                                    <Flex direction={"column"} gap={"2px"}>
                                                        <Tooltip
                                                            hasArrow
                                                            label="On Process"
                                                            bg="#343F4B"
                                                            color="#90cdf4"
                                                            placement="right"
                                                        >
                                                            <Tag
                                                                size={"xs"}
                                                                variant="subtle"
                                                                colorScheme="blue"
                                                                fontSize={"2xs"}
                                                                display={"flex"}
                                                                justifyContent={"center"}
                                                                px={1}
                                                            >
                                                                {jd?.noOfOnProcessApplications}
                                                            </Tag>
                                                        </Tooltip>
                                                        <Tooltip
                                                            hasArrow
                                                            label="Rejected"
                                                            bg="#463B41"
                                                            color="#feb2b2"
                                                            placement="right"
                                                        >
                                                            <Tag
                                                                size={"xs"}
                                                                variant="subtle"
                                                                colorScheme="red"
                                                                fontSize={"2xs"}
                                                                display={"flex"}
                                                                justifyContent={"center"}
                                                                px={1}
                                                            >
                                                                {jd?.noOfRejectedApplications}
                                                            </Tag>
                                                        </Tooltip>
                                                    </Flex>
                                                </Flex>
                                            </Td>
                                        )}
                                        <Td borderStyle={"dashed"} pr={0}>
                                            <Flex
                                                gap={"2px"}
                                                align={"flex-end"}
                                                justify={"flex-end"}
                                                w={"60px"}
                                            >
                                                <Text
                                                    textAlign={"right"}
                                                    fontSize={"xs"}
                                                    color={percentageToColor(
                                                        jd?.percentageOfClosing
                                                    )}
                                                    mr={-2}
                                                    mb={"-2px"}
                                                >
                                                    {jd?.percentageOfClosing?.toFixed(2)}%
                                                </Text>
                                                <Box position={"relative"}>
                                                    <GiNetworkBars
                                                        style={{
                                                            transform: "rotate(-90deg)",
                                                        }}
                                                        fontSize={"30px"}
                                                        color="#6363639c"
                                                    />
                                                    <Flex
                                                        position={"absolute"}
                                                        bottom={0}
                                                        h={`${jd?.percentageOfClosing}%`}
                                                        overflowY={"clip"}
                                                        align={"flex-end"}
                                                    >
                                                        <GiNetworkBars
                                                            fontSize={"30px"}
                                                            style={{
                                                                transform: "rotate(-90deg)",
                                                            }}
                                                            color={percentageToColor(
                                                                jd?.percentageOfClosing
                                                            )}
                                                        />
                                                    </Flex>
                                                </Box>
                                            </Flex>
                                        </Td>
                                    </Tr>
                                ))
                            )}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>
        </div>
    );
};

export default JDList;
