import axios from "axios";
import React, { createContext, useState, useContext, useMemo } from "react";
import { useEnv } from "./EnvContext";
import { useAuth } from "./AuthContext";
import useGlobalToast from "../Utils/GlobalFunctions/toast";

const AdminContext = createContext();

export const AdminContextProvider = ({ children }) => {
    const toast = useGlobalToast();
    const { backendUrl } = useEnv();
    const { userData } = useAuth();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const [bannerPhotos, setBannerPhotos] = useState([]);
    const [carouselStack, setCarouselStack] = useState([]);
    const [basicTools, setBasicTools] = useState({});

    const memoizedBannerPhotos = useMemo(() => bannerPhotos, [bannerPhotos]);
    const memoizedBasicTools = useMemo(() => basicTools, [basicTools]);

    // Add Banner Images
    const addbannerImages = async (imagesFormData) => {
        setLoading(true);
        setError(null);

        toast.loading("Uploading Banner Images");
        try {
            const token = userData?.token;
            const response = await axios.post(
                `${backendUrl}/admin/add_banner_images`,
                imagesFormData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "multipart/form-data", // Ensure this header is set
                    },
                }
            );

            const addbannerImagesRes = response.data;
            // console.log("addbannerImagesRes", addbannerImagesRes);

            setBannerPhotos((prevState) => [...prevState, ...addbannerImagesRes.data]);

            toast.success(addbannerImagesRes.message);
        } catch (err) {
            console.log("Error", err);
            let error = err.response
                ? err.response?.data?.message
                : err.message
                ? err.message
                : "Something Went Wrong";
            setError(error);
            toast.error("Error", error);
        } finally {
            setLoading(false);
        }
    };

    // Fetch Banner Images
    const fetchBannerImages = async () => {
        setLoading(true);
        setError(null);

        try {
            if (memoizedBannerPhotos.length > 0) {
                setLoading(false);
                return;
            }
            const token = userData?.token;
            if(token){

                const response = await axios.get(`${backendUrl}/admin/all_banner_photos`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                
                const fetchedBannerImagesRes = response.data;
                // console.log("fetchedBannerImagesRes", fetchedBannerImagesRes);
                
                setBannerPhotos(fetchedBannerImagesRes.data);
                
                toast.success(fetchedBannerImagesRes.message);
            }
        } catch (err) {
            console.log("Error", err);
            let error = err.response
                ? err.response?.data?.message
                : err.message
                ? err.message
                : "Something Went Wrong";
            setError(error);
            toast.error("Error", error);
        } finally {
            setLoading(false);
        }
    };

    // Fetch Admin Tools
    const fetchBasicTools = async () => {
        setLoading(true);
        setError(null);

        try {
            // Check if the object is empty
            const isBasicToolsEmpty = Object.keys(memoizedBasicTools).length > 0;

            if (isBasicToolsEmpty) {
                setLoading(false);
                return;
            }

            const response = await axios.get(`${backendUrl}/admin/admin_tools`);

            const fetchedAdminToolsRes = response.data;
            // console.log("fetchedAdminToolsRes", fetchedAdminToolsRes);

            setBasicTools(fetchedAdminToolsRes.data);

            // toast.success(fetchedAdminToolsRes.message);
        } catch (err) {
            console.log("Error", err);
            let error = err.response
                ? err.response?.data?.message
                : err.message
                ? err.message
                : "Something Went Wrong";
            setError(error);
            toast.error("Error", error);
        } finally {
            setLoading(false);
        }
    };

    // Delete a banner photo
    const deleteBannerPhoto = async (photoId, photoURL) => {
        // Prompt the user for confirmation
        const confirmation = window.prompt(
            'Note* If this image is used in carousel, then it will be removed from there also. For Confirmation type "delete Banner Photo" to confirm the deletion:'
        );

        if (confirmation !== "delete Banner Photo") {
            toast.error("Deletion cancelled or incorrect phrase entered.");
            return;
        }

        toast.loading("Deleting Banner Image");

        setLoading(true);
        setError(null);

        try {
            const token = userData?.token;
            const response = await axios.delete(
                `${backendUrl}/admin/delete_banner_photo?photoId=${photoId}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            const deleteBannerPhotoRes = response.data;
            // console.log("deleteBannerPhotoRes", deleteBannerPhotoRes);

            // remove it from banner photos
            setBannerPhotos((prevPhotos) => prevPhotos.filter((photo) => photo._id !== photoId));

            // if photo url is present in carousel, then also remove it from there

            setBasicTools((prevState) => {
                // Check if the photoURL is already in the images array
                const imageIndex = prevState?.carouselStack?.indexOf(photoURL);

                // Create a new array with the photoURL removed if it exists
                const newState =
                    imageIndex !== -1
                        ? prevState?.carouselStack?.filter((image, index) => index !== imageIndex)
                        : prevState;

                return [...newState];
            });

            toast.success(deleteBannerPhotoRes.message);
        } catch (err) {
            console.log("Error", err);
            let error = err.response
                ? err.response?.data?.message
                : err.message
                ? err.message
                : "Something Went Wrong";
            setError(error);
            toast.error("Error", error);
        } finally {
            setLoading(false);
        }
    };

    // Update Carousel Stack
    const updateCarouselStack = async (carouselStack) => {
        setError(null);

        toast.loading("Saving New Carousel Stack");
        try {
            const token = userData?.token;
            const response = await axios.put(
                `${backendUrl}/admin/update_carousel_stack`,
                { carouselStack },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            const updateCarouselStackRes = response.data;
            // console.log("updateCarouselStackRes", updateCarouselStackRes);

            setBasicTools(updateCarouselStackRes.data);

            toast.success(updateCarouselStackRes.message);
        } catch (err) {
            console.log("Error", err);
            let error = err.response
                ? err.response?.data?.message
                : err.message
                ? err.message
                : "Something Went Wrong";
            setError(error);
            toast.error("Error", error);
        } finally {
        }
    };

    return (
        <AdminContext.Provider
            value={{
                addbannerImages,
                fetchBannerImages,
                deleteBannerPhoto,
                loading,
                bannerPhotos,
                carouselStack,
                setCarouselStack,
                fetchBasicTools,
                basicTools,
                updateCarouselStack,
            }}
        >
            {children}
        </AdminContext.Provider>
    );
};

export default AdminContext;

export const useAdmin = () => useContext(AdminContext);
