import React, { useState, useRef, useCallback } from "react";
import {
    Box,
    FormControl,
    FormLabel,
    Input,
    Tag,
    TagLabel,
    TagCloseButton,
    List,
    ListItem,
    Avatar,
    Flex,
    Text,
} from "@chakra-ui/react";
import { useCombobox } from "downshift";
import debounce from "lodash.debounce";
import { useClient } from "../../context/ClientContext"; // Assuming you have a context for clients

const ClientDetailsInput = ({ clientDetails, setClientDetails }) => {
    const { fetchSearchedClients, searchedClients } = useClient();

    const [inputValue, setInputValue] = useState("");
    const [selectedClient, setSelectedClient] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const clientInputRef = useRef(null);

    const debouncedFetchClients = useCallback(debounce(fetchSearchedClients, 300), []);

    const { getMenuProps, getInputProps, getItemProps, highlightedIndex } = useCombobox({
        items: searchedClients,
        inputValue: searchQuery, // Use searchQuery here
        onInputValueChange: ({ inputValue }) => {
            setSearchQuery(inputValue);
            setInputValue(inputValue); // Keep inputValue in sync
            debouncedFetchClients(inputValue);
        },
        onSelectedItemChange: ({ selectedItem }) => {
            if (selectedItem) {
                setSelectedClient(selectedItem);
                setInputValue(selectedItem?.name); // Set the inputValue to the selected client's name
                setClientDetails((prev) => ({
                    ...prev,
                    client: selectedItem,
                    handlerId: "",
                }));
            }
        },
    });

    const handleHandlerSelection = (handler) => {
        setClientDetails((prev) => ({
            ...prev,
            handlerId: handler.handlerId,
        }));
    };

    const removeClientDetails = () => {
        setClientDetails({ client: null, handlerId: "" });
        setSelectedClient(null);
        setInputValue(""); // Clear the input value when removing client details
        setSearchQuery(""); // Clear the search query as well
    };

    console.log("client Details", clientDetails);

    return (
        <FormControl>
            <FormLabel mb={0}>Select Client and Handler</FormLabel>
            <Box>
                <List {...getMenuProps()} borderRadius="md" maxH="150px" overflowY="auto">
                    {searchQuery &&
                        searchedClients.map((item, index) => (
                            <ListItem
                                key={index}
                                {...getItemProps({ item, index })}
                                bg={highlightedIndex === index ? "#ffffff29" : "#ffffff14"}
                                p={2}
                                cursor="pointer"
                            >
                                <Flex pl={2} align={"center"} gap={1}>
                                    <Avatar src={item.logo} size="xs" name={item.name} />
                                    <Text>
                                        <b>{item.clientId}</b>
                                    </Text>
                                    <Text>- {item.name}</Text>
                                </Flex>
                            </ListItem>
                        ))}
                </List>
                <Input
                    {...getInputProps({
                        placeholder: "Type client name or ID",
                        ref: clientInputRef,
                        value: inputValue, // Use inputValue here to show typed query
                    })}
                />
            </Box>

            {selectedClient && selectedClient?.contacts.length > 0 && (
                <Box mt={4}>
                    <FormLabel mb={0}>Select Handler</FormLabel>
                    <List>
                        {selectedClient?.contacts.map((handler, index) => (
                            <ListItem
                                key={index}
                                onClick={() => handleHandlerSelection(handler)}
                                cursor="pointer"
                                p={2}
                                bg={
                                    clientDetails.handlerId === handler.handlerId
                                        ? "#ffffff29"
                                        : "#ffffff14"
                                }
                                _hover={{ bg: "#ffffff29" }} // Add hover effect
                            >
                                <Text>
                                    <b>{handler.name}</b> - {handler.role}
                                </Text>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            )}

            {selectedClient && (
                <Box mt={2}>
                    <Tag
                        size="lg"
                        borderRadius="full"
                        variant="solid"
                        colorScheme="teal"
                        mt={1}
                        mx={1}
                    >
                        <Avatar
                            src={selectedClient?.logo}
                            size="xs"
                            name={selectedClient?.name}
                            ml={-2}
                            mr={2}
                        />
                        <TagLabel fontSize={"sm"}>
                            {selectedClient?.name} (ID: {selectedClient?.clientId}) -- (Handler:{" "}
                            {clientDetails.handlerId} -{" "}
                            {clientDetails.handlerId &&
                                selectedClient?.contacts.find(
                                    (handler) => handler.handlerId === clientDetails.handlerId
                                )?.name}{" "}
                            -{" "}
                            {clientDetails.handlerId &&
                                selectedClient?.contacts.find(
                                    (handler) => handler.handlerId === clientDetails.handlerId
                                )?.role}
                            )
                        </TagLabel>
                        <TagCloseButton onClick={removeClientDetails} />
                    </Tag>
                </Box>
            )}
        </FormControl>
    );
};

export default ClientDetailsInput;