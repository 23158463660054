import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Text,
} from "@chakra-ui/react";

import CandidateHeader from "./CandidateHeader";
import CandidateBody from "./CandidateBody";

import { tagColor } from "../../../Utils/GlobalFunctions/TagColor";
import { useCandidate } from "../../../context/CandidateContext";
import useGlobalToast from "../../../Utils/GlobalFunctions/toast";

const CandidateContainer = () => {
  const {
    loading,
    fetchAllCandidates,
    allCandidates,
    hasMoreCandidates,
    candidatesPageNo,
    searchedCandidates,
    setSearchedCandidates,
    filterCandidates,
    setFilterCandidates,
    dataFiltered,
    setDataFiltered,
  } = useCandidate();

  const [tabIndex, setTabIndex] = useState(0);
  const { status } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const initialPath = pathname.split("/")[1];
  const [searchFilterData, setSearchFilterData] = useState([]);
  const [FilteredData, setFilteredData] = useState([]);
  const toast = useGlobalToast();

  useEffect(() => {
    // if (!userData?.token) return;
    switch (status) {
      case "All":
        fetchAllCandidates("all", 1, 30);
        setTabIndex(0);
        break;
      case "Selected":
        fetchAllCandidates("selected", 1, 30);
        setTabIndex(1);
        break;
      case "Processing":
        fetchAllCandidates("processing", 1, 30);
        setTabIndex(2);
        break;
      case "OnBench":
        fetchAllCandidates("onBench", 1, 30);
        setTabIndex(3);
        break;
      default:
        fetchAllCandidates("all", 1, 30);
        setTabIndex(0);
        break;
    }
  }, [status]);

  const handleTabsChange = (index) => {
    setTabIndex(index);
    const tabMapping = ["All", "Selected", "Processing", "OnBench"];
    navigate(`/${initialPath}/Candidates/${tabMapping[index]}`);
  };

  // console.log(allCandidates)

  useEffect(() => {
    if (
      allCandidates &&
      status.charAt(0).toLowerCase() + status.slice(1) in allCandidates
    ) {
      setSearchFilterData(
        allCandidates[status.charAt(0).toLowerCase() + status.slice(1)]
      );
    }
    else if(status === "Default"){
      setSearchFilterData(
        allCandidates?.all
      );
    
    }
    else {
      console.warn(
        `Status "${status.toLowerCase()}" not found in allCandidates`
      );
      setSearchFilterData([]); // Set to empty array if the status doesn't match any property
    }
  }, [allCandidates, status]);

  useEffect(() => {
    if (
      allCandidates &&
      status.charAt(0).toLowerCase() + status.slice(1) in allCandidates
    ) {
      setFilteredData(
        allCandidates[status.charAt(0).toLowerCase() + status.slice(1)]
      );
    }else if(status === "Default"){
      setFilteredData(
        allCandidates?.all
      );
    
    } else {
      console.warn(
        `Status "${status.toLowerCase()}" not found in allCandidates`
      );
      setFilteredData([]);
    }
  }, [allCandidates, status]);

  const filterData = (query) => {
    console.log("query", query, searchFilterData);
    const trimmedQuery = query.trim();
    if (!trimmedQuery) {
      return;
    }

    const lowerCaseQuery = trimmedQuery.toLowerCase();

    const filtered = searchFilterData?.filter((item) => {
      // Check basic fields
      const basicFieldsMatch =
        (item.name && item.name.toLowerCase().includes(lowerCaseQuery)) ||
        (item.email && item.email.toLowerCase().includes(lowerCaseQuery)) ||
        (item.stuId && item.stuId.toLowerCase().includes(lowerCaseQuery)) ||
        (item.phone && item.phone.includes(trimmedQuery)) || // No toLowerCase needed for phone numbers
        (item.experience &&
          item.experience.toString().toLowerCase().includes(lowerCaseQuery));

      // Check skills array
      const skillsMatch =
        item.skills &&
        item.skills.some(
          (skill) =>
            skill.skillName &&
            skill.skillName.toLowerCase().includes(lowerCaseQuery)
        );

      // Convert address object values to a single string and search within it
      const addressString = item.address
        ? Object.values(item.address).join(" ").toLowerCase()
        : "";
      const addressMatch = addressString.includes(lowerCaseQuery);

      // Return true if any of the conditions match
      return basicFieldsMatch || skillsMatch || addressMatch;
    });

    // console.log("filtered", filtered);
    setSearchedCandidates((prevState) => ({
      ...prevState,
      [status.charAt(0).toLowerCase() + status.slice(1)]: filtered, // Append fetched candidates to the correct array
    }));
  };

  function filterCandidatesData(candidates, filtersArray) {
    console.log(candidates);
    console.log(filtersArray);
    return candidates.filter((candidate) => {
      return filtersArray.every((filter) => {
        const { type, value } = filter;

        switch (type) {
          case "experience":
            // Check if candidate's experience is empty, default to 0 if so
            const experienceMatch =
                candidate.experience &&
                candidate.experience.match(/(\d+)\s*(Years?|Months?)/i);
        
            // Convert candidate's experience to months for easier comparison
            let candidateExperienceInMonths = 0;
            if (experienceMatch) {
                const experienceValue = Number(experienceMatch[1]);
                const experienceUnit = experienceMatch[2].toLowerCase();
        
                // Convert years to months or take months as is
                candidateExperienceInMonths = experienceUnit.startsWith("year")
                    ? experienceValue * 12
                    : experienceValue;
            }
        
            // If experience is between 1 and 11 months, treat it as 0 experience
            if (candidateExperienceInMonths >= 1 && candidateExperienceInMonths <= 11) {
                candidateExperienceInMonths = 0;
            }
        
            // Parse filter value, handle '0' or range like '1 to 5'
            if (value === '0') {
                // Case where we want only candidates with 0 experience
                return candidateExperienceInMonths === 0;
            } else if (value.includes("-")) {
                // Parse range (e.g., '1 to 5' means 1 year to 5 years)
                const [minYears, maxYears] = value.split("-").map(Number);
                const minMonths = minYears * 12;
                const maxMonths = maxYears * 12;
        
                // Include candidates with experience in the specified range
                return candidateExperienceInMonths >= minMonths && candidateExperienceInMonths <= maxMonths;
            } else {
                // If the input isn't in range format, default to minimum experience in months
                const requiredExperienceInMonths = Number(value) * 12;
        
                // Include candidates with experience >= requiredExperienceInMonths
                return candidateExperienceInMonths >= requiredExperienceInMonths;
            }
        
            case "ctc":
              // If no value is provided, return true to include all candidates
              if (!value) {
                return true;
              }
            
              if (typeof value === "string" && value.includes("-")) {
                // Handle range case, e.g., "5 to 8"
                const [minCtc, maxCtc] = value.split("-").map(ctc => {
                  const parsed = parseFloat(ctc.trim());
                  if (isNaN(parsed)) {
                    return NaN; // Return NaN if the value is invalid
                  }
                  return parsed * 100000; // Convert lakhs to rupees
                });
            
                if (isNaN(minCtc) || isNaN(maxCtc)) {
                  return true; // If the range is invalid, return true to include all candidates
                }
            
                // Check if candidate's CTC falls within the specified range
                return candidate.currentCTC >= minCtc && candidate.currentCTC <= maxCtc;
              } else if (typeof value === "number" || !isNaN(value)) {
                // Handle single CTC value case, e.g., 5 or "5"
                const targetCtc = parseFloat(value) * 100000; // Convert lakhs to rupees
                if (isNaN(targetCtc)) {
                  return true; // If the value is invalid, return true to include all candidates
                }
                return candidate.currentCTC === targetCtc;
              } else {
                // If no valid range or value is provided, include all candidates
                return true;
              }
            

            case "skills":
              // Check if `value` is an array and is empty, allow all candidates by returning true
              if (!Array.isArray(value) || value.length === 0) {
                  return true;
              }
              
              // If `value` is a non-empty array, check if each skill in `value` is in candidate's skills array
              return value.every((skill) =>
                  candidate.skills.some(
                      (candidateSkill) =>
                          candidateSkill.skillName.toLowerCase() === skill.toLowerCase()
                  )
              );

          case "address":
            // Check each address field in `value` matches candidate's address
            return Object.keys(value).every(
              (key) =>
                value[key] === "" ||
                candidate.address[key].toLowerCase() ===
                  value[key].toLowerCase()
            );
            case "recruiter":
              return filter.value.length > 0
                ? filter.value.some(
                    (selectedRecruiter) =>
                      candidate.addedBy?.name === selectedRecruiter
                  )
                : true;
          default:
            return true; // Ignore filters that don't match candidate properties
        }
      });
    });
  }

  useEffect(() => {
    const filteredAllData = filterCandidatesData(
      FilteredData,
      filterCandidates
    );
    if (filteredAllData.length === 0 && filterCandidates.length > 0) {
      toast.error("No data found");
    }
    setDataFiltered(filteredAllData);
  }, [FilteredData, filterCandidates]); // Trigger effect when FilteredData or filterCandidates change

  useEffect(() => {
    setFilterCandidates([]); // Set to empty array if the status doesn't match any property
    setDataFiltered([]);
  }, [status]);

  return (
    <>
      <Tabs
        isLazy
        variant="enclosed"
        index={tabIndex}
        onChange={handleTabsChange}
      >
        <CandidateHeader filterData={filterData} initialPath={initialPath} />
        <TabList>
          <Tab>
            <Text
              bg={tagColor("").bg}
              color={tagColor("").clr}
              px={2}
              borderRadius={20}
              fontWeight="bold"
              fontSize={"sm"}
            >
              All
            </Text>
          </Tab>
          <Tab>
            <Text
              bg={tagColor("green").bg}
              color={tagColor("green").clr}
              px={2}
              borderRadius={20}
              fontWeight="bold"
              fontSize={"sm"}
            >
              Selected
            </Text>
          </Tab>
          <Tab>
            <Text
              bg={tagColor("blue").bg}
              color={tagColor("blue").clr}
              px={2}
              borderRadius={20}
              fontWeight="bold"
              fontSize={"sm"}
            >
              Processing
            </Text>
          </Tab>
          <Tab>
            <Text
              bg={tagColor("orange").bg}
              color={tagColor("orange").clr}
              px={2}
              borderRadius={20}
              fontWeight="bold"
              fontSize={"sm"}
            >
              On Bench
            </Text>
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel p={0} pt={4}>
            <CandidateBody
              data={allCandidates?.all}
              fetchAllCandidates={fetchAllCandidates}
              hasMore={hasMoreCandidates.all}
              pageNo={candidatesPageNo.all}
              status={"all"}
              loading={loading}
              searchedCandidates={searchedCandidates}
              dataFiltered={dataFiltered}
            />
          </TabPanel>
          <TabPanel p={0} pt={4}>
            <CandidateBody
              data={allCandidates?.selected}
              fetchAllCandidates={fetchAllCandidates}
              hasMore={hasMoreCandidates.selected}
              pageNo={candidatesPageNo.selected}
              status={"selected"}
              loading={loading}
              searchedCandidates={searchedCandidates}
              dataFiltered={dataFiltered}
            />
          </TabPanel>
          <TabPanel p={0} pt={4}>
            <CandidateBody
              data={allCandidates?.processing}
              fetchAllCandidates={fetchAllCandidates}
              hasMore={hasMoreCandidates.processing}
              pageNo={candidatesPageNo.processing}
              status={"processing"}
              loading={loading}
              searchedCandidates={searchedCandidates}
              dataFiltered={dataFiltered}
            />
          </TabPanel>
          <TabPanel p={0} pt={4}>
            <CandidateBody
              data={allCandidates?.onBench}
              fetchAllCandidates={fetchAllCandidates}
              hasMore={hasMoreCandidates.onBench}
              pageNo={candidatesPageNo.onBench}
              status={"onBench"}
              loading={loading}
              searchedCandidates={searchedCandidates}
              dataFiltered={dataFiltered}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
};

export default CandidateContainer;
