import React, { useState } from "react";
import * as XLSX from "xlsx";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  Box
} from "@chakra-ui/react";
import { useCandidate } from "../../../context/CandidateContext";

const CandidateDataModal = ({ isOpen, onClose }) => {
  const [jsonData, setJsonData] = useState([]);
  const [loader,setLoader] = useState(false)
const {addMultipleCandidate} = useCandidate();

  const handleSubmit = async()=>{
    setLoader(true);
    console.log("Starting upload...");
    await addMultipleCandidate(jsonData);
    console.log("Upload complete");
    setLoader(false);
  }

  // Handle Excel file upload and conversion
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const json = XLSX.utils.sheet_to_json(worksheet);
        setJsonData(json);
      };
      reader.readAsArrayBuffer(file);
    }
  };

  return (
    <>
      <Button onClick={isOpen} colorScheme="blue">Upload Excel File</Button>

      <Modal isOpen={isOpen} onClose={onClose} size="full">
        <ModalOverlay />
        <ModalContent width="90vw" maxWidth="90vw">
          <ModalHeader>Upload Excel File</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              type="file"
              accept=".xlsx, .xls"
              onChange={handleFileUpload}
              mb={4}
              p={1}
            />
            {jsonData.length > 0 && (
              <Box
                maxHeight="60vh"
                overflowY="auto"
                overflowX="auto"
                border="1px solid #E2E8F0"
                borderRadius="md"
                width="100%"
              >
                <Table variant="unstyled" size="sm" width="100%">
                  <Thead>
                    <Tr>
                      {Object.keys(jsonData[0]).map((key) => (
                        <Th
                          key={key}
                        //   border="1px solid #E2E8F0"
                        borderBottom="1px solid #E2E8F0"
                          px={4}
                          py={2}
                          textAlign="left"
                          textColor={"lightcyan"}
                          fontSize={".9vw"}
                        >
                          {key}
                        </Th>
                      ))}
                    </Tr>
                  </Thead>
                  <Tbody>
                    {jsonData.map((row, index) => (
                      <Tr key={index} borderBottom="1px solid #E2E8F0">
                        {Object.values(row).map((value, i) => (
                          <Td
                            key={i}
                            // border="1px solid #E2E8F0"
                            px={4}
                            py={2}
                            textAlign="left"
                          >
                            {value}
                          </Td>
                        ))}
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            )}
          </ModalBody>
          <ModalFooter gap={"10px"}>
          <Button
              onClick={handleSubmit}
              colorScheme="green"
              isLoading={loader} // Add loader animation
            >
              Submit
            </Button>
            <Button onClick={onClose} colorScheme="blue">Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CandidateDataModal;
