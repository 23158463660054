import { Box, Flex, Spinner, Tag, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import PieChart from "../../../Utils/GlobalComponents/Charts/PieChart";
import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    Tooltip as ChakraTooltip,
} from "@chakra-ui/react";
import DonutChart from "../../../Utils/GlobalComponents/Charts/DonutChart";

export const OverallApplicationStatistics = ({ loading, applicationsStatics }) => {
    return (
        <Flex
            bg={"#22242b"}
            borderRadius={"md"}
            p={4}
            position={"relative"}
            direction={"column"}
            align={"center"}
            gap={2}
        >
            <Flex direction={"column"} w={"100%"}>
                <Text fontSize={"sm"} fontWeight={"bold"} color={"white"} textAlign={"left"}>
                    Application Statistics
                </Text>
            </Flex>
            <Flex gap={4}>
                <Flex direction={"column"} gap={4}>
                    <Box w={"200px"}>
                        {loading ? (
                            <Spinner
                                thickness="100px"
                                speed="0.65s"
                                emptyColor="gray.200"
                                color="blue.500"
                                height="200px"
                                width="200px"
                            />
                        ) : (
                            <PieChart
                                height={"1px"}
                                width={"1px"}
                                dataSet={[
                                    {
                                        label: "Selected",
                                        data: applicationsStatics?.selectedApplications || 0,
                                        color: "#4CAF50",
                                    },
                                    {
                                        label: "Rejected",
                                        data: applicationsStatics?.rejectedApplications || 0,
                                        color: "#E57373",
                                    },
                                ]}
                            />
                        )}
                    </Box>
                    <TableContainer w={"100%"}>
                        <Table size="sm">
                            <Tbody>
                                <Tr>
                                    <Td
                                        borderStyle={"dashed"}
                                        pr={0}
                                        pl={2}
                                        py={"2px"}
                                        fontSize={"xs"}
                                        color={"#ffffff9c"}
                                    >
                                        <Box
                                            h={"10px"}
                                            w={"30px"}
                                            borderRadius={"5px"}
                                            bg={"#4CAF50"}
                                        ></Box>
                                    </Td>
                                    <Td
                                        borderStyle={"dashed"}
                                        px={0}
                                        py={"2px"}
                                        fontSize={"xs"}
                                        color={"#ffffff9c"}
                                        fontWeight={"500"}
                                    >
                                        Selected
                                    </Td>
                                    <Td
                                        borderStyle={"dashed"}
                                        px={0}
                                        py={"2px"}
                                        fontSize={"xs"}
                                        color={"#ffffff9c"}
                                    >
                                        {applicationsStatics?.selectedApplications || 0}
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td
                                        borderStyle={"dashed"}
                                        pr={0}
                                        pl={2}
                                        py={"2px"}
                                        fontSize={"xs"}
                                        color={"#ffffff9c"}
                                    >
                                        <Box
                                            h={"10px"}
                                            w={"30px"}
                                            borderRadius={"5px"}
                                            bg={"#E57373"}
                                        ></Box>
                                    </Td>
                                    <Td
                                        borderStyle={"dashed"}
                                        px={0}
                                        py={"2px"}
                                        fontSize={"xs"}
                                        color={"#ffffff9c"}
                                        fontWeight={"500"}
                                    >
                                        Rejected
                                    </Td>
                                    <Td
                                        borderStyle={"dashed"}
                                        px={0}
                                        py={"2px"}
                                        fontSize={"xs"}
                                        color={"#ffffff9c"}
                                    >
                                        {applicationsStatics?.rejectedApplications || 0}
                                    </Td>
                                </Tr>
                            </Tbody>
                        </Table>
                    </TableContainer>
                </Flex>
            </Flex>
        </Flex>
    );
};

export const SingleRoundStatistics = ({ loading, roundName, roundData }) => {
    const [hoveredSegment, setHoveredSegment] = useState(null);
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const handleHoverSegment = (index) => {
        setHoveredSegment(index);
        setTooltipOpen(index !== null);
    };

    return (
        <Flex
            bg={"#22242b"}
            borderRadius={"md"}
            p={4}
            position={"relative"}
            gap={2}
            align={"flex-start"}
            direction={"column"}
            w={"20%"}
        >
            <Text fontSize={"xs"} fontWeight={"bold"} color={"white"} align={"left"} lineHeight={1}>
                {roundName}
            </Text>
            <Flex justify={"space-between"} flexGrow={1} gap={2} w={"100%"}>
                {loading ? (
                    <Spinner
                        thickness="10px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        color="blue.500"
                        height="50px"
                        width="50px"
                    />
                ) : roundData?.totalSelected == 0 &&
                  roundData?.totalRejected == 0 &&
                  roundData?.totalOnProcess == 0 ? (
                    <Spinner
                        thickness="10px"
                        speed="0"
                        emptyColor="white"
                        height="50px"
                        width="50px"
                    />
                ) : (
                    <DonutChart
                        size={"50px"}
                        cutout={"55%"}
                        tooltip={false}
                        dataSet={[
                            {
                                label: "Selected",
                                data: roundData?.totalSelected || 0,
                                color: "#4CAF50",
                            },
                            {
                                label: "Rejected",
                                data: roundData?.totalRejected || 0,
                                color: "#E57373",
                            },
                            {
                                label: "On Process",
                                data: roundData?.totalOnProcess || 0,
                                color: "#FFA726",
                            },
                        ]}
                        onHoverSegment={handleHoverSegment}
                    />
                )}

                <Flex direction={"column"} gap={"2px"} flexGrow={1} justify={"space-between"}>
                    <ChakraTooltip
                        hasArrow
                        isOpen={hoveredSegment === 2 && tooltipOpen}
                        label={`${roundData?.totalOnProcess || 0} On Process`}
                        bg="#45453A"
                        color="#FAF089"
                        placement="right"
                    >
                        <Tag
                            size={"xs"}
                            variant="subtle"
                            colorScheme="yellow"
                            fontSize={"2xs"}
                            display={"flex"}
                            justifyContent={"center"}
                            px={1}
                            onMouseEnter={() => handleHoverSegment(2)} // Corresponding index for "On Process"
                            onMouseLeave={() => handleHoverSegment(null)}
                            style={{
                                transform: hoveredSegment === 2 ? "scale(1.05)" : "scale(1)", // Minimal transform
                                backgroundColor: hoveredSegment === 2 ? "#3A3A3A" : "#45453A", // Darker shade
                            }}
                        >
                            {roundData?.totalOnProcess || 0}
                        </Tag>
                    </ChakraTooltip>
                    <ChakraTooltip
                        hasArrow
                        isOpen={hoveredSegment === 0 && tooltipOpen}
                        label={`${roundData?.totalSelected || 0} Selected`}
                        bg="#364341"
                        color="#9AE6B4"
                        placement="right"
                    >
                        <Tag
                            size={"xs"}
                            variant="subtle"
                            colorScheme="green"
                            fontSize={"2xs"}
                            display={"flex"}
                            justifyContent={"center"}
                            px={1}
                            onMouseEnter={() => handleHoverSegment(0)} // Corresponding index for "Selected"
                            onMouseLeave={() => handleHoverSegment(null)}
                            style={{
                                transform: hoveredSegment === 0 ? "scale(1.05)" : "scale(1)", // Minimal transform
                                backgroundColor: hoveredSegment === 0 ? "#323F34" : "#364341", // Darker shade
                            }}
                        >
                            {roundData?.totalSelected || 0}
                        </Tag>
                    </ChakraTooltip>
                    <ChakraTooltip
                        hasArrow
                        isOpen={hoveredSegment === 1 && tooltipOpen}
                        label={`${roundData?.totalRejected || 0} Rejected`}
                        bg="#463B41"
                        color="#feb2b2"
                        placement="right"
                    >
                        <Tag
                            size={"xs"}
                            variant="subtle"
                            colorScheme="red"
                            fontSize={"2xs"}
                            display={"flex"}
                            justifyContent={"center"}
                            px={1}
                            onMouseEnter={() => handleHoverSegment(1)} // Corresponding index for "Rejected"
                            onMouseLeave={() => handleHoverSegment(null)}
                            style={{
                                transform: hoveredSegment === 1 ? "scale(1.05)" : "scale(1)", // Minimal transform
                                backgroundColor: hoveredSegment === 1 ? "#3C2E34" : "#463B41", // Darker shade
                            }}
                        >
                            {roundData?.totalRejected || 0}
                        </Tag>
                    </ChakraTooltip>
                </Flex>
            </Flex>
        </Flex>
    );
};
