import axios from "axios";
import React, { createContext, useState, useContext, useMemo } from "react";
import { useEnv } from "./EnvContext";
import { useAuth } from "./AuthContext";
import useGlobalToast from "../Utils/GlobalFunctions/toast";
import { getStatusStoC } from "../Utils/GlobalFunctions/GetStatus";

const BillingContext = createContext();

export const BillingContextProvider = ({ children }) => {
    const toast = useGlobalToast();
    const { backendUrl } = useEnv();
    const { userData } = useAuth();

    const [loadingBills, setLoadingBills] = useState(false);
    const [error, setError] = useState(null);

    // handle Bills
    const [allBills, setAllBills] = useState({
        all: [],
        validated: [],
        rejected: [],
        pending: [],
    });

    const [hasMoreBills, setHasMoreBills] = useState({
        all: true,
        validated: true,
        rejected: true,
        pending: true,
    });

    const [BillsPageNo, setBillsPageNo] = useState({
        all: 1,
        validated: 1,
        rejected: 1,
        pending: 1,
    });

    const memoizedBills = useMemo(() => allBills, [allBills]);

    // Fetch All Bills
    const fetchBills = async (status, page = 1, limit = 30, updateData = false) => {
        setLoadingBills(true);
        try {
            if (!updateData && memoizedBills[status].length > 0) {
                setLoadingBills(false);
                return;
            }

            let requestStatus = getStatusStoC(status);

            const token = userData?.token;
            const response = await axios.get(`${backendUrl}/billing/all`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: { page, limit, status: requestStatus },
            });

            const fetchedBillsRes = response.data;
            const fetchedBills = fetchedBillsRes.billings;
            // console.log("fetchedBillsRes", fetchedBillsRes);

            if (updateData) {
                setAllBills((prevState) => ({
                    ...prevState,
                    [status]: [...prevState[status], ...fetchedBills],
                }));

                setBillsPageNo((prevState) => ({
                    ...prevState,
                    [status]: prevState[status] + 1,
                }));

                if (fetchedBills.length < limit) {
                    setHasMoreBills((prevState) => ({
                        ...prevState,
                        [status]: false,
                    }));
                }
            } else {
                setAllBills((prevState) => ({
                    ...prevState,
                    [status]: fetchedBills,
                }));

                setBillsPageNo((prevState) => ({
                    ...prevState,
                    [status]: 2,
                }));

                setHasMoreBills((prevState) => ({
                    ...prevState,
                    [status]: fetchedBills.length === limit,
                }));
            }

            toast.success(`Bills fetched Successfully!`);
        } catch (err) {
            console.log("Error", err);
            let error = err.response
                ? err.response?.data?.message
                : err.message
                ? err.message
                : "Something Went Wrong";
            setError(error);
            toast.error("Error", error);
        } finally {
            setLoadingBills(false);
        }
    };

    return (
        <BillingContext.Provider
            value={{
                loadingBills,
                allBills,
                hasMoreBills,
                BillsPageNo,
                fetchBills,
            }}
        >
            {children}
        </BillingContext.Provider>
    );
};

export default BillingContext;

export const useBill = () => useContext(BillingContext);
