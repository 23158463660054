import React from "react";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Tag,
  TagLabel,
  Box,
  Text,
  Avatar,
  Flex,
  Grid,
  Badge,
  Heading,
} from "@chakra-ui/react";
import { Table, Tbody, Tr, Td, TableContainer } from "@chakra-ui/react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";

import { FaBirthdayCake, FaPhoneAlt } from "react-icons/fa";
import { BsWhatsapp } from "react-icons/bs";
import {
  MdGroupWork,
  MdLocationPin,
  MdOutlineAlternateEmail,
} from "react-icons/md";

import { useEnv } from "../../../context/EnvContext";
import getGenderIcon from "../../../Utils/GlobalFunctions/GenderIcon";
import {
  formatDateTime,
  formatToShortDate,
} from "../../../Utils/GlobalFunctions/FormatDate";

const CandidateDetails = ({ isOpen, onClose, candidate }) => {
  const { backendUrl } = useEnv();
  // console.log(candidate);
  return (
    <div>
      <Modal
        isLazy
        isOpen={isOpen}
        onClose={onClose}
        size={"3xl"}
        style={{ minHeight: "100vh" }}
      >
        <ModalOverlay />
        <ModalContent className="candidate-details">
          <ModalHeader>
            <Flex gap="20px">
              <Flex direction={"column"}>
                <Avatar
                  size="xl"
                  name={candidate?.name}
                  src={`${backendUrl}${candidate?.dp}`}
                />
                <Text textAlign={"center"} fontSize="md">
                  {candidate?.stuId}
                </Text>
              </Flex>
              <Grid templateColumns="repeat(2, 1fr)" gap={5}>
                <Flex direction="column" maxWidth={"100%"}>
                  <Flex gap={1} align={"center"}>
                    {getGenderIcon(candidate?.gender)}
                    <Text>{candidate?.name}</Text>
                  </Flex>
                  <Flex
                    gap={"5px"}
                    align={"center"}
                    fontSize="md"
                    color="#a3a3a3"
                    width={"100%"}
                    flexWrap={"wrap"}
                  >
                    <MdOutlineAlternateEmail />{" "}
                    <Text
                      style={{
                        maxWidth: "80%",
                      }}
                    >
                      {candidate?.email}
                    </Text>
                  </Flex>
                  <Flex
                    gap={"5px"}
                    align={"center"}
                    fontSize="sm"
                    color="#a3a3a3"
                  >
                    <FaPhoneAlt /> {candidate?.phone}
                  </Flex>
                  <Flex
                    gap={"5px"}
                    align={"center"}
                    fontSize="sm"
                    color="#a3a3a3"
                  >
                    <BsWhatsapp /> {candidate?.whatsappNo}
                  </Flex>
                </Flex>

                <Flex direction="column">
                  <Flex
                    gap={"5px"}
                    align={"center"}
                    fontSize="sm"
                    color="#a3a3a3"
                  >
                    <FaBirthdayCake />{" "}
                    {candidate?.dob? candidate?.dob
                      ? new Date(candidate?.dob)
                          .toLocaleDateString()
                          .split("T")[0]
                      : "N/A":""}{" "}
                    {candidate?.dob?candidate?.dob
                      ? Math.floor(
                          (new Date() - new Date(candidate?.dob)) /
                            (365.25 * 24 * 60 * 60 * 1000)
                        )
                      : "N/A":""} {candidate?.dob?"Years":""}
                  </Flex>
                  <Flex
                    gap={"5px"}
                    align={"center"}
                    fontSize="sm"
                    color="#a3a3a3"
                  >
                    <div
                      style={{
                        alignSelf: "flex-start",
                        marginTop: "4px",
                      }}
                    >
                      <MdLocationPin />
                    </div>
                    {candidate?.address && (
                      <>
                        {candidate?.address?.at && `${candidate?.address?.at}, `}
                        {candidate?.address?.po && `${candidate?.address?.po}, `}
                        {candidate?.address?.city &&
                          `${candidate?.address?.city}, `}
                        {candidate?.address?.dist &&
                          `${candidate?.address?.dist}, `}
                        {candidate?.address?.state &&
                          `${candidate?.address?.state}, `}
                        {candidate?.address?.country &&
                          `${candidate?.address?.country}, `}
                        {candidate?.address?.pin && `${candidate?.address?.pin}`}
                      </>
                    )}
                  </Flex>
                  <Flex
                    gap={"5px"}
                    align={"center"}
                    fontSize="sm"
                    color="#a3a3a3"
                  >
                    <MdGroupWork />{" "}
                    {candidate?.currentStatus == "Selected" && (
                      <Badge variant="subtle" colorScheme="green">
                        Selected
                      </Badge>
                    )}
                    {candidate?.currentStatus == "Processing" && (
                      <Badge variant="subtle" colorScheme="blue">
                        Processing
                      </Badge>
                    )}
                    {candidate?.currentStatus == "On Bench" && (
                      <Badge variant="subtle" colorScheme="orange">
                        On Bench
                      </Badge>
                    )}
                  </Flex>
                </Flex>
              </Grid>
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody style={{ paddingBottom: "20px" }}>
            <TableContainer width="100%" style={{ whiteSpace: "normal" }}>
              <Table variant="simple">
                <Tbody>
                  <Tr>
                    <Td>
                      <b>Added By: </b>
                    </Td>
                    <Td>
                      <Tag
                        style={{
                          margin: "2px 0px",
                        }}
                        size="lg"
                        borderRadius="full"
                      >
                        <Avatar
                          src={`${backendUrl}${candidate?.addedBy?.dp}`}
                          size="xs"
                          name={candidate?.addedBy?.name}
                          ml={-1}
                          mr={2}
                        />
                        <TagLabel fontSize={"sm"}>
                          <Text fontSize={"sm"}>
                            ({candidate?.addedBy?.empId}) {candidate?.addedBy?.name}{" "}
                            on {formatDateTime(candidate?.createdAt)}
                          </Text>
                        </TagLabel>
                      </Tag>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <b>Skills:</b>
                    </Td>
                    <Td>
                      {candidate?.skills?.map((skill, index) => (
                        <Tag
                          key={index}
                          size="md"
                          borderRadius="full"
                          variant="solid"
                          m={1}
                        >
                          <TagLabel>{`${skill?.skillName} - ${skill?.relevantExp} Years`}</TagLabel>
                        </Tag>
                      ))}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <b>Experience: </b>
                    </Td>
                    <Td>
                      {candidate?.isFresher
                        ? "Fresher"
                        : `Experienced: ${candidate?.experience || "N/A"}, ${
                            candidate?.experienceType !== "NA"
                              ? candidate?.experienceType
                              : ""
                          } Experience`}
                    </Td>
                  </Tr>
                  {candidate?.workDetails && (
                    <Tr>
                      <Td>
                        <b>Previous Work Details: </b>
                      </Td>
                      <Td>
                        <Accordion allowMultiple>
                          {candidate?.workDetails.map((work, index) => (
                            <AccordionItem key={index}>
                              <h2>
                                <AccordionButton>
                                  <Box as="span" flex="1" textAlign="left">
                                    <Flex
                                      justify={"space-between"}
                                      align={"center"}
                                    >
                                      <Text fontSize={"md"}>{work?.role}</Text>
                                      <Text fontSize={"xs"}>
                                        {formatToShortDate(work?.startingDate)} -{" "}
                                        {formatToShortDate(work?.endingDate)}
                                      </Text>
                                    </Flex>
                                  </Box>
                                  <AccordionIcon />
                                </AccordionButton>
                              </h2>
                              <AccordionPanel pb={4}>
                                <Tag
                                  key={index}
                                  size="md"
                                  borderRadius="sm"
                                  variant="solid"
                                  colorScheme="teal"
                                  m={1}
                                  style={{
                                    alignItems: "flex-start",
                                  }}
                                >
                                  <TagLabel>
                                    <Flex direction={"column"} py={2}>
                                      <Text fontSize={"md"} fontWeight="bold">
                                        {work?.companyName}
                                      </Text>
                                      <Flex gap={1}>
                                        <Text fontSize={"sm"}>
                                          {work?.companyaddress?.city}
                                          {", "}
                                        </Text>
                                        <Text fontSize={"sm"}>
                                          {work?.companyaddress?.state}
                                          {", "}
                                        </Text>
                                        <Text fontSize={"sm"}>
                                          {work?.companyaddress?.country}
                                        </Text>
                                      </Flex>

                                      <Text mt={1}>
                                        <span>
                                          <b>Work Details: </b>
                                        </span>
                                        {work?.workDetails}
                                      </Text>
                                    </Flex>
                                  </TagLabel>
                                </Tag>
                              </AccordionPanel>
                            </AccordionItem>
                          ))}
                        </Accordion>
                      </Td>
                    </Tr>
                  )}
                  <Tr>
                    <Td>
                      <b>
                        {candidate?.isFresher ? "Stipend: " : "Current CTC: "}
                      </b>
                    </Td>
                    <Td>
                      {candidate?.isFresher
                        ? `₹ ${
                            candidate?.stipend === null
                              ? ""
                              : candidate?.stipend
                          }`
                        : `₹ ${
                            candidate?.currentCTC === null
                              ? ""
                              : candidate?.currentCTC
                          }`}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <b>Expected CTC: </b>
                    </Td>
                    <Td>
                      {"₹ "}
                      {candidate?.expectedCTC}
                    </Td>
                  </Tr>
                  {candidate?.noticePeriod && (
                    <Tr>
                      <Td>
                        <b>Notice Period: </b>
                      </Td>
                      <Td>{candidate?.noticePeriod}</Td>
                    </Tr>
                  )}

                  {candidate?.otherDetails && (
                    <Tr>
                      <Td>
                        <b>Other Details: </b>
                      </Td>
                      <Td>{candidate?.otherDetails}</Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </TableContainer>
            {candidate?.cv && (
              <Box>
                <Heading as="h5" size="sm" mb={2} pl={1}>
                  Resume:
                </Heading>{" "}
                <iframe
                  src={`${backendUrl}${candidate?.cv}`}
                  width="100%"
                  height="1050px"
                  title="CV Preview"
                />
              </Box>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default CandidateDetails;
