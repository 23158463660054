import React from "react";

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Tag,
    Box,
    Text,
    Avatar,
    Flex,
    Badge,
} from "@chakra-ui/react";
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
} from "@chakra-ui/react";

import { FaCircle } from "react-icons/fa";
import { useEnv } from "../../../context/EnvContext";
import getGenderIcon from "../../../Utils/GlobalFunctions/GenderIcon";
import { numberToWords } from "../../../Utils/GlobalFunctions/FormatSalary";
import { formatDateDay, formatDateTimeDay } from "../../../Utils/GlobalFunctions/FormatDate";

const ApplicationDetails = ({ isOpen, onClose, application, tab, subTab, bill }) => {
    const { backendUrl } = useEnv();
    return (
        <div>
            <Modal
                isLazy
                isOpen={isOpen}
                onClose={onClose}
                size={"3xl"}
                style={{ minHeight: "100vh" }}
            >
                <ModalOverlay />
                <ModalContent className="application-details">
                    <ModalHeader>
                        <Flex align={"center"} justify={"center"} gap={2}>
                            <Text fontSize={"xl"} fontWeight={"bold"} textAlign={"center"}>
                                Application {application.applicationID}{" "}
                            </Text>{" "}
                            {application?.applicationStatus == "Not Started" && (
                                <Badge
                                    variant="subtle"
                                    colorScheme="yellow"
                                    display={"flex"}
                                    alignItems={"center"}
                                    gap={1}
                                >
                                    <FaCircle fontSize={"10px"} />
                                    Not Started
                                </Badge>
                            )}
                            {application?.applicationStatus == "On Process" && (
                                <Badge
                                    variant="subtle"
                                    colorScheme="blue"
                                    display={"flex"}
                                    alignItems={"center"}
                                    gap={1}
                                >
                                    <FaCircle fontSize={"10px"} />
                                    On Process
                                </Badge>
                            )}
                            {application?.applicationStatus == "Selected" && (
                                <Badge
                                    variant="subtle"
                                    colorScheme="green"
                                    display={"flex"}
                                    alignItems={"center"}
                                    gap={1}
                                >
                                    <FaCircle fontSize={"10px"} />
                                    Selected
                                </Badge>
                            )}
                            {application?.applicationStatus == "Rejected" && (
                                <Badge
                                    variant="subtle"
                                    colorScheme="red"
                                    display={"flex"}
                                    alignItems={"center"}
                                    gap={1}
                                >
                                    <FaCircle fontSize={"10px"} />
                                    Rejected
                                </Badge>
                            )}
                        </Flex>
                        <Flex justify={"space-between"} w={"100%"} mt={2} gap={2}>
                            <Box background={"teal"} w={"50%"} px={2} py={1} borderRadius={"md"}>
                                <Text fontSize={"md"}>{application?.jobDescription?.jobId}</Text>
                                <Text
                                    fontSize={"sm"}
                                    color={"#c9c9c9"}
                                    style={{
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                        maxWidth: "200px",
                                    }}
                                >
                                    {application?.jobDescription?.jobTitle}
                                </Text>
                            </Box>{" "}
                            <Flex
                                align={"center"}
                                background={"teal"}
                                w={"50%"}
                                px={2}
                                py={1}
                                borderRadius={"md"}
                            >
                                <Avatar
                                    src={`${backendUrl}${application?.candidate?.dp}`}
                                    size="sm"
                                    name={application?.candidate?.name}
                                    mr={2}
                                    background={"green"}
                                    color={"white"}
                                />

                                <Box
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                >
                                    <Flex gap={1}>
                                        <Text
                                            fontSize={"sm"}
                                            style={{
                                                textOverflow: "ellipsis",
                                                overflow: "hidden",
                                                maxWidth: "270px",
                                            }}
                                        >
                                            <span
                                                style={{
                                                    color: "#c9c9c9",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                {application?.candidate?.stuId}
                                            </span>
                                            {" - "}
                                            {application?.candidate?.name}
                                        </Text>
                                        {getGenderIcon(application?.candidate?.gender)}
                                    </Flex>
                                    <Text
                                        fontSize={"sm"}
                                        color={"#c9c9c9"}
                                        style={{
                                            textOverflow: "ellipsis",
                                            overflow: "hidden",
                                            maxWidth: "270px",
                                        }}
                                    >
                                        {application?.candidate?.email}
                                    </Text>
                                </Box>
                            </Flex>
                        </Flex>
                        <Tag
                            size={"md"}
                            variant="solid"
                            colorScheme="orange"
                            mt={2}
                            w={"100%"}
                            justifyContent={"center"}
                            gap={1}
                        >
                            <b>
                                <i>Current Status- {application?.currentState}</i>
                            </b>
                        </Tag>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody style={{ paddingBottom: "20px" }}>
                        <Accordion defaultIndex={[tab]} allowMultiple>
                            {/* CV Process */}
                            <AccordionItem>
                                <AccordionButton
                                    _expanded={{ bg: "#171e29", borderBottomRadius: "10px" }}
                                >
                                    <Flex as="span" flex="1" justify={"space-between"} pr={"15px"}>
                                        CV Process{" "}
                                        {application?.cvProcess == "Not Started" && (
                                            <Badge
                                                variant="subtle"
                                                colorScheme="yellow"
                                                display={"flex"}
                                                alignItems={"center"}
                                                gap={1}
                                            >
                                                Not Started
                                                <FaCircle fontSize={"10px"} />
                                            </Badge>
                                        )}
                                        {application?.cvProcess == "On Process" && (
                                            <Badge
                                                variant="subtle"
                                                colorScheme="blue"
                                                display={"flex"}
                                                alignItems={"center"}
                                                gap={1}
                                            >
                                                On Process
                                                <FaCircle fontSize={"10px"} />
                                            </Badge>
                                        )}
                                        {application?.cvProcess == "Completed" && (
                                            <Badge
                                                variant="subtle"
                                                colorScheme="green"
                                                display={"flex"}
                                                alignItems={"center"}
                                                gap={1}
                                            >
                                                Completed
                                                <FaCircle fontSize={"10px"} />
                                            </Badge>
                                        )}
                                    </Flex>
                                    <AccordionIcon />
                                </AccordionButton>
                                <AccordionPanel pb={4}>
                                    {application?.cvProcess == "Not Started" ? (
                                        <Text textAlign={"center"} fontWeight={"bold"}>
                                            - - - Process Not Started Yet - - -
                                        </Text>
                                    ) : (
                                        <table>
                                            <tr>
                                                <td>Process Id - </td>
                                                <td style={{ paddingLeft: "10px" }}>
                                                    <b>
                                                        {application?.cvSelectionDetails?.processId}
                                                    </b>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>CV Status - </td>
                                                <td style={{ paddingLeft: "10px" }}>
                                                    <b>
                                                        {application?.cvSelectionDetails
                                                            .isForwarded ? (
                                                            <span style={{ color: "#6FB888" }}>
                                                                Forwarded
                                                            </span>
                                                        ) : (
                                                            <span style={{ color: "orange" }}>
                                                                Not Forwarded
                                                            </span>
                                                        )}
                                                    </b>
                                                </td>
                                            </tr>
                                            {application?.cvSelectionDetails?.isForwarded && (
                                                <>
                                                    <tr>
                                                        <td>Forwarded On - </td>
                                                        <td style={{ paddingLeft: "10px" }}>
                                                            <b>
                                                                {formatDateTimeDay(
                                                                    application?.cvSelectionDetails
                                                                        ?.forwardingDate
                                                                )}
                                                            </b>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Result - </td>
                                                        <td style={{ paddingLeft: "10px" }}>
                                                            {application?.cvSelectionDetails
                                                                ?.result == "Pending" && (
                                                                <Badge
                                                                    variant="subtle"
                                                                    colorScheme="blue"
                                                                >
                                                                    Pending
                                                                </Badge>
                                                            )}
                                                            {application?.cvSelectionDetails
                                                                .result == "Selected" && (
                                                                <Badge
                                                                    variant="subtle"
                                                                    colorScheme="green"
                                                                >
                                                                    Selected
                                                                </Badge>
                                                            )}
                                                            {application?.cvSelectionDetails
                                                                .result == "Rejected" && (
                                                                <Badge
                                                                    variant="subtle"
                                                                    colorScheme="red"
                                                                >
                                                                    Rejected
                                                                </Badge>
                                                            )}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Result Recorded On - </td>
                                                        <td style={{ paddingLeft: "10px" }}>
                                                            <b>
                                                                {formatDateTimeDay(
                                                                    application?.cvSelectionDetails
                                                                        ?.resultDate
                                                                )}
                                                            </b>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            style={{
                                                                verticalAlign: "top",
                                                            }}
                                                        >
                                                            Remarks -{" "}
                                                        </td>
                                                        <td
                                                            style={{
                                                                paddingLeft: "10px",
                                                                maxWidth: "450px",
                                                            }}
                                                        >
                                                            <Box whiteSpace="pre-line">
                                                                <i>
                                                                    {
                                                                        application
                                                                            ?.cvSelectionDetails
                                                                            ?.remark
                                                                    }
                                                                </i>
                                                            </Box>
                                                        </td>
                                                    </tr>
                                                </>
                                            )}
                                        </table>
                                    )}
                                </AccordionPanel>
                            </AccordionItem>

                            {/* Interview Process */}
                            <AccordionItem>
                                <AccordionButton
                                    _expanded={{ bg: "#171e29", borderBottomRadius: "10px" }}
                                >
                                    <Flex as="span" flex="1" justify={"space-between"} pr={"15px"}>
                                        Interview Process{" "}
                                        {application?.overallInterviewProcess == "Not Started" && (
                                            <Badge
                                                variant="subtle"
                                                colorScheme="yellow"
                                                display={"flex"}
                                                alignItems={"center"}
                                                gap={1}
                                            >
                                                Not Started
                                                <FaCircle fontSize={"10px"} />
                                            </Badge>
                                        )}
                                        {application?.overallInterviewProcess == "On Process" && (
                                            <Badge
                                                variant="subtle"
                                                colorScheme="blue"
                                                display={"flex"}
                                                alignItems={"center"}
                                                gap={1}
                                            >
                                                On Process
                                                <FaCircle fontSize={"10px"} />
                                            </Badge>
                                        )}
                                        {application?.overallInterviewProcess == "Selected" && (
                                            <Badge
                                                variant="subtle"
                                                colorScheme="green"
                                                display={"flex"}
                                                alignItems={"center"}
                                                gap={1}
                                            >
                                                Passed
                                                <FaCircle fontSize={"10px"} />
                                            </Badge>
                                        )}
                                        {application?.overallInterviewProcess == "Rejected" && (
                                            <Badge
                                                variant="subtle"
                                                colorScheme="red"
                                                display={"flex"}
                                                alignItems={"center"}
                                                gap={1}
                                            >
                                                Failed
                                                <FaCircle fontSize={"10px"} />
                                            </Badge>
                                        )}
                                    </Flex>
                                    <AccordionIcon />
                                </AccordionButton>
                                <AccordionPanel pb={4}>
                                    {application?.overallInterviewProcess == "Not Started" ? (
                                        <Text textAlign={"center"} fontWeight={"bold"}>
                                            - - - Interview Process not Started Yet - - -
                                        </Text>
                                    ) : (
                                        <Accordion defaultIndex={[subTab]} allowMultiple>
                                            {application?.jobDescription?.interviewRounds.map(
                                                (intv, index) => (
                                                    <AccordionItem key={index}>
                                                        <AccordionButton
                                                            bg={"#212c3fa1"}
                                                            _expanded={{
                                                                bg: "#151D2B",
                                                                borderBottomRadius: "10px",
                                                            }}
                                                        >
                                                            <Flex
                                                                as="span"
                                                                flex="1"
                                                                justify={"space-between"}
                                                                pr={"15px"}
                                                            >
                                                                Round No. - {intv?.roundNo}
                                                                {", "}
                                                                {intv?.title}
                                                                {application?.interviewProcess[
                                                                    index
                                                                ]?.state == "Not Started" && (
                                                                    <Badge
                                                                        variant="subtle"
                                                                        colorScheme="yellow"
                                                                        display={"flex"}
                                                                        alignItems={"center"}
                                                                        gap={1}
                                                                    >
                                                                        Not Started
                                                                        <FaCircle
                                                                            fontSize={"10px"}
                                                                        />
                                                                    </Badge>
                                                                )}
                                                                {application?.interviewProcess[
                                                                    index
                                                                ]?.state == "On Process" && (
                                                                    <Badge
                                                                        variant="subtle"
                                                                        colorScheme="blue"
                                                                        display={"flex"}
                                                                        alignItems={"center"}
                                                                        gap={1}
                                                                    >
                                                                        On Process
                                                                        <FaCircle
                                                                            fontSize={"10px"}
                                                                        />
                                                                    </Badge>
                                                                )}
                                                                {application?.interviewProcess[
                                                                    index
                                                                ]?.state == "Completed" && (
                                                                    <Badge
                                                                        variant="subtle"
                                                                        colorScheme="green"
                                                                        display={"flex"}
                                                                        alignItems={"center"}
                                                                        gap={1}
                                                                    >
                                                                        Completed
                                                                        <FaCircle
                                                                            fontSize={"10px"}
                                                                        />
                                                                    </Badge>
                                                                )}
                                                            </Flex>
                                                            <AccordionIcon />
                                                        </AccordionButton>
                                                        <AccordionPanel pb={4}>
                                                            {application?.interviewProcess[index]
                                                                ?.state == "Not Started" ? (
                                                                <Text
                                                                    textAlign={"center"}
                                                                    fontWeight={"bold"}
                                                                >
                                                                    - - - Interview Round{" "}
                                                                    {index + 1} Not Started Yet - -
                                                                    -
                                                                </Text>
                                                            ) : (
                                                                <table>
                                                                    <tr>
                                                                        <td>Process Id -</td>
                                                                        <td
                                                                            style={{
                                                                                paddingLeft: "10px",
                                                                            }}
                                                                        >
                                                                            <b>
                                                                                {
                                                                                    application
                                                                                        ?.interviewDetails[
                                                                                        index
                                                                                    ]?.interviewId
                                                                                }
                                                                            </b>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Location/Mode -</td>
                                                                        <td
                                                                            style={{
                                                                                paddingLeft: "10px",
                                                                            }}
                                                                        >
                                                                            <b>
                                                                                {
                                                                                    intv?.locationOrMode
                                                                                }
                                                                            </b>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            Interview Scheduled For
                                                                            -
                                                                        </td>
                                                                        <td
                                                                            style={{
                                                                                paddingLeft: "10px",
                                                                            }}
                                                                        >
                                                                            <b>
                                                                                {formatDateTimeDay(
                                                                                    application
                                                                                        ?.interviewDetails[
                                                                                        index
                                                                                    ]?.scheduledOn
                                                                                )}
                                                                            </b>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Scheduling Date -</td>
                                                                        <td
                                                                            style={{
                                                                                paddingLeft: "10px",
                                                                            }}
                                                                        >
                                                                            <b>
                                                                                {formatDateTimeDay(
                                                                                    application
                                                                                        ?.interviewDetails[
                                                                                        index
                                                                                    ]
                                                                                        ?.schedulingDate
                                                                                )}
                                                                            </b>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Interview Result - </td>
                                                                        <td
                                                                            style={{
                                                                                paddingLeft: "10px",
                                                                            }}
                                                                        >
                                                                            {application
                                                                                ?.interviewDetails[
                                                                                index
                                                                            ]?.result ==
                                                                                "Pending" && (
                                                                                <Badge
                                                                                    variant="subtle"
                                                                                    colorScheme="blue"
                                                                                >
                                                                                    Pending
                                                                                </Badge>
                                                                            )}
                                                                            {application
                                                                                ?.interviewDetails[
                                                                                index
                                                                            ]?.result ==
                                                                                "Passed" && (
                                                                                <Badge
                                                                                    variant="subtle"
                                                                                    colorScheme="green"
                                                                                >
                                                                                    Passed
                                                                                </Badge>
                                                                            )}
                                                                            {application
                                                                                ?.interviewDetails[
                                                                                index
                                                                            ]?.result ==
                                                                                "Failed" && (
                                                                                <Badge
                                                                                    variant="subtle"
                                                                                    colorScheme="red"
                                                                                >
                                                                                    Failed
                                                                                </Badge>
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                    {application?.interviewDetails[
                                                                        index
                                                                    ]?.result !== "Pending" && (
                                                                        <>
                                                                            <tr>
                                                                                <td>
                                                                                    Result Updated
                                                                                    On -
                                                                                </td>
                                                                                <td
                                                                                    style={{
                                                                                        paddingLeft:
                                                                                            "10px",
                                                                                    }}
                                                                                >
                                                                                    {" "}
                                                                                    <b>
                                                                                        {formatDateTimeDay(
                                                                                            application
                                                                                                ?.interviewDetails[
                                                                                                index
                                                                                            ]
                                                                                                ?.resultDate
                                                                                        )}
                                                                                    </b>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td
                                                                                    style={{
                                                                                        verticalAlign:
                                                                                            "top",
                                                                                    }}
                                                                                >
                                                                                    Remarks -{" "}
                                                                                </td>
                                                                                <td
                                                                                    style={{
                                                                                        paddingLeft:
                                                                                            "10px",
                                                                                        maxWidth:
                                                                                            "450px",
                                                                                    }}
                                                                                >
                                                                                    <Box whiteSpace="pre-line">
                                                                                        <i>
                                                                                            {
                                                                                                application
                                                                                                    ?.interviewDetails[
                                                                                                    index
                                                                                                ]
                                                                                                    ?.remarks
                                                                                            }
                                                                                        </i>
                                                                                    </Box>
                                                                                </td>
                                                                            </tr>
                                                                        </>
                                                                    )}
                                                                </table>
                                                            )}
                                                        </AccordionPanel>
                                                    </AccordionItem>
                                                )
                                            )}
                                        </Accordion>
                                    )}
                                </AccordionPanel>
                            </AccordionItem>

                            {/* Offer Process */}
                            <AccordionItem>
                                <AccordionButton
                                    _expanded={{ bg: "#171e29", borderBottomRadius: "10px" }}
                                >
                                    <Flex as="span" flex="1" justify={"space-between"} pr={"15px"}>
                                        Offer Process{" "}
                                        {application?.offerProcess == "Not Started" && (
                                            <Badge
                                                variant="subtle"
                                                colorScheme="yellow"
                                                display={"flex"}
                                                alignItems={"center"}
                                                gap={1}
                                            >
                                                Not Started
                                                <FaCircle fontSize={"10px"} />
                                            </Badge>
                                        )}
                                        {application?.offerProcess == "On Process" && (
                                            <Badge
                                                variant="subtle"
                                                colorScheme="blue"
                                                display={"flex"}
                                                alignItems={"center"}
                                                gap={1}
                                            >
                                                On Process
                                                <FaCircle fontSize={"10px"} />
                                            </Badge>
                                        )}
                                        {application?.offerProcess == "Completed" && (
                                            <Badge
                                                variant="subtle"
                                                colorScheme="green"
                                                display={"flex"}
                                                alignItems={"center"}
                                                gap={1}
                                            >
                                                Completed
                                                <FaCircle fontSize={"10px"} />
                                            </Badge>
                                        )}
                                    </Flex>
                                    <AccordionIcon />
                                </AccordionButton>
                                <AccordionPanel pb={4}>
                                    {application?.offerProcess == "Not Started" ? (
                                        <Text textAlign={"center"} fontWeight={"bold"}>
                                            - - - Offer Process Not Started Yet - - -
                                        </Text>
                                    ) : (
                                        <table>
                                            <tr>
                                                <td>Process Id -</td>
                                                <td style={{ paddingLeft: "10px" }}>
                                                    <b>{application?.offerDetails?.offerId}</b>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Offered Salary in JD -</td>
                                                <td style={{ paddingLeft: "10px" }}>
                                                    <b>
                                                        ₹{" "}
                                                        {
                                                            application?.offerDetails
                                                                ?.previousOfferedSalary
                                                        }
                                                    </b>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Newly offered Salary -</td>
                                                <td style={{ paddingLeft: "10px" }}>
                                                    <b>
                                                        ₹{" "}
                                                        {
                                                            application?.offerDetails
                                                                ?.newOfferedSalary
                                                        }
                                                    </b>{" "}
                                                    <Text as={"span"} fontSize={"sm"}>
                                                        (
                                                        {numberToWords(
                                                            application?.offerDetails
                                                                ?.newOfferedSalary
                                                        )}
                                                        )
                                                    </Text>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Fixed Component -</td>
                                                <td style={{ paddingLeft: "10px" }}>
                                                    <b>
                                                        ₹{" "}
                                                        {application?.offerDetails?.fixedComponent}
                                                    </b>{" "}
                                                    <Text as={"span"} fontSize={"sm"}>
                                                        (
                                                        {numberToWords(
                                                            application?.offerDetails
                                                                ?.fixedComponent
                                                        )}
                                                        )
                                                    </Text>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Variable Component -</td>
                                                <td style={{ paddingLeft: "10px" }}>
                                                    <b>
                                                        ₹{" "}
                                                        {
                                                            application?.offerDetails
                                                                ?.variableComponent
                                                        }
                                                    </b>{" "}
                                                    <Text as={"span"} fontSize={"sm"}>
                                                        (
                                                        {numberToWords(
                                                            application?.offerDetails
                                                                ?.variableComponent
                                                        )}
                                                        )
                                                    </Text>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Offered On -</td>
                                                <td style={{ paddingLeft: "10px" }}>
                                                    <b>
                                                        {formatDateTimeDay(
                                                            application?.offerDetails?.offeredOn
                                                        )}
                                                    </b>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Candidate Rsponse - </td>
                                                <td style={{ paddingLeft: "10px" }}>
                                                    {application?.offerDetails?.offerStatus ==
                                                        "Pending" && (
                                                        <Badge variant="subtle" colorScheme="blue">
                                                            Pending
                                                        </Badge>
                                                    )}
                                                    {application?.offerDetails?.offerStatus ==
                                                        "Accepted" && (
                                                        <Badge variant="subtle" colorScheme="green">
                                                            Accepted
                                                        </Badge>
                                                    )}
                                                    {application?.offerDetails?.offerStatus ==
                                                        "Rejected" && (
                                                        <Badge variant="subtle" colorScheme="red">
                                                            Rejected
                                                        </Badge>
                                                    )}
                                                </td>
                                            </tr>
                                            {application?.offerDetails?.offerStatus !==
                                                "Pending" && (
                                                <>
                                                    <tr>
                                                        <td>Candidate Res. Recorded On -</td>
                                                        <td style={{ paddingLeft: "10px" }}>
                                                            {" "}
                                                            <b>
                                                                {formatDateTimeDay(
                                                                    application?.offerDetails
                                                                        ?.offerResRecordOn
                                                                )}
                                                            </b>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            style={{
                                                                verticalAlign: "top",
                                                            }}
                                                        >
                                                            Remarks -{" "}
                                                        </td>
                                                        <td
                                                            style={{
                                                                paddingLeft: "10px",
                                                                maxWidth: "450px",
                                                            }}
                                                        >
                                                            <Box whiteSpace="pre-line">
                                                                <i>
                                                                    {
                                                                        application?.offerDetails
                                                                            ?.remarks
                                                                    }
                                                                </i>
                                                            </Box>
                                                        </td>
                                                    </tr>
                                                </>
                                            )}
                                        </table>
                                    )}
                                </AccordionPanel>
                            </AccordionItem>

                            {/* Placement Process */}
                            <AccordionItem>
                                <AccordionButton
                                    _expanded={{ bg: "#171e29", borderBottomRadius: "10px" }}
                                >
                                    <Flex as="span" flex="1" justify={"space-between"} pr={"15px"}>
                                        Placement Process{" "}
                                        {application?.placementProcess == "Not Started" && (
                                            <Badge
                                                variant="subtle"
                                                colorScheme="yellow"
                                                display={"flex"}
                                                alignItems={"center"}
                                                gap={1}
                                            >
                                                Not Started
                                                <FaCircle fontSize={"10px"} />
                                            </Badge>
                                        )}
                                        {application?.placementProcess == "On Process" && (
                                            <Badge
                                                variant="subtle"
                                                colorScheme="blue"
                                                display={"flex"}
                                                alignItems={"center"}
                                                gap={1}
                                            >
                                                On Process
                                                <FaCircle fontSize={"10px"} />
                                            </Badge>
                                        )}
                                        {application?.placementProcess == "Completed" && (
                                            <Badge
                                                variant="subtle"
                                                colorScheme="green"
                                                display={"flex"}
                                                alignItems={"center"}
                                                gap={1}
                                            >
                                                Completed
                                                <FaCircle fontSize={"10px"} />
                                            </Badge>
                                        )}
                                    </Flex>
                                    <AccordionIcon />
                                </AccordionButton>
                                <AccordionPanel pb={4}>
                                    {application?.placementProcess == "Not Started" ? (
                                        <Text textAlign={"center"} fontWeight={"bold"}>
                                            - - - Placement Process Not Started Yet - - -
                                        </Text>
                                    ) : (
                                        <table>
                                            <tr>
                                                <td>Process Id -</td>
                                                <td style={{ paddingLeft: "10px" }}>
                                                    <b>
                                                        {application?.placementDetails?.placementId}
                                                    </b>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Joining Date -</td>
                                                <td style={{ paddingLeft: "10px" }}>
                                                    <b>
                                                        {formatDateDay(
                                                            application?.placementDetails
                                                                ?.joiningDate
                                                        )}
                                                    </b>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Notice Period Starting Date -</td>
                                                <td style={{ paddingLeft: "10px" }}>
                                                    <b>
                                                        {formatDateDay(
                                                            application?.placementDetails
                                                                ?.noticePeriodStartDate
                                                        )}
                                                    </b>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Notice Period Ending Date -</td>
                                                <td style={{ paddingLeft: "10px" }}>
                                                    <b>
                                                        {formatDateDay(
                                                            application?.placementDetails
                                                                ?.noticePeriodEndDate
                                                        )}
                                                    </b>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Joining Date Provided On -</td>
                                                <td style={{ paddingLeft: "10px" }}>
                                                    <b>
                                                        {formatDateTimeDay(
                                                            application?.placementDetails
                                                                ?.recordedDate
                                                        )}
                                                    </b>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Joining Status of Candidate - </td>
                                                <td style={{ paddingLeft: "10px" }}>
                                                    {application?.placementDetails
                                                        ?.candidateResponse == "Pending" ? (
                                                        <Badge variant="subtle" colorScheme="blue">
                                                            Pending
                                                        </Badge>
                                                    ) : application?.placementDetails?.isJoined ? (
                                                        <Badge variant="subtle" colorScheme="green">
                                                            Joined
                                                        </Badge>
                                                    ) : (
                                                        <Badge variant="subtle" colorScheme="red">
                                                            Not joined
                                                        </Badge>
                                                    )}
                                                </td>
                                            </tr>
                                            {application?.placementDetails?.candidateResponse !==
                                                "Pending" && (
                                                <>
                                                    <tr>
                                                        <td>Status updated On -</td>
                                                        <td style={{ paddingLeft: "10px" }}>
                                                            {" "}
                                                            <b>
                                                                {formatDateTimeDay(
                                                                    application?.placementDetails
                                                                        ?.remarkedOn
                                                                )}
                                                            </b>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            style={{
                                                                verticalAlign: "top",
                                                            }}
                                                        >
                                                            Remarks -{" "}
                                                        </td>
                                                        <td
                                                            style={{
                                                                paddingLeft: "10px",
                                                                maxWidth: "450px",
                                                            }}
                                                        >
                                                            <Box whiteSpace="pre-line">
                                                                <i>
                                                                    {
                                                                        application
                                                                            ?.placementDetails
                                                                            ?.remarks
                                                                    }
                                                                </i>
                                                            </Box>
                                                        </td>
                                                    </tr>
                                                    {application?.placementDetails
                                                        ?.candidateResponse == "Accepted" && (
                                                        <>
                                                            <tr>
                                                                <td>MBP Ends On -</td>
                                                                <td
                                                                    style={{
                                                                        paddingLeft: "10px",
                                                                    }}
                                                                >
                                                                    {" "}
                                                                    <b>
                                                                        {formatDateDay(
                                                                            application?.billingDate
                                                                        )}
                                                                    </b>
                                                                </td>
                                                            </tr>{" "}
                                                            <tr>
                                                                <td>Billing Status - </td>
                                                                <td style={{ paddingLeft: "10px" }}>
                                                                    {application?.billValidattion ==
                                                                        "Pending" && (
                                                                        <Badge
                                                                            variant="subtle"
                                                                            colorScheme="blue"
                                                                        >
                                                                            Pending
                                                                        </Badge>
                                                                    )}
                                                                    {application?.billValidattion ==
                                                                        "Validated" && (
                                                                        <Badge
                                                                            variant="subtle"
                                                                            colorScheme="green"
                                                                        >
                                                                            Validated
                                                                        </Badge>
                                                                    )}
                                                                    {application?.billValidattion ==
                                                                        "Rejected" && (
                                                                        <Badge
                                                                            variant="subtle"
                                                                            colorScheme="red"
                                                                        >
                                                                            Rejected
                                                                        </Badge>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </table>
                                    )}
                                </AccordionPanel>
                            </AccordionItem>

                            {/* Billing */}
                            {bill && (
                                <AccordionItem>
                                    <AccordionButton
                                        _expanded={{ bg: "#171e29", borderBottomRadius: "10px" }}
                                    >
                                        <Flex
                                            as="span"
                                            flex="1"
                                            justify={"space-between"}
                                            pr={"15px"}
                                        >
                                            Billing{" "}
                                            {bill?.billValidattion == "Rejected" && (
                                                <Badge
                                                    variant="subtle"
                                                    colorScheme="red"
                                                    display={"flex"}
                                                    alignItems={"center"}
                                                    gap={1}
                                                >
                                                    Rejected
                                                    <FaCircle fontSize={"10px"} />
                                                </Badge>
                                            )}
                                            {bill?.billValidattion == "Pending" && (
                                                <Badge
                                                    variant="subtle"
                                                    colorScheme="blue"
                                                    display={"flex"}
                                                    alignItems={"center"}
                                                    gap={1}
                                                >
                                                    Pending
                                                    <FaCircle fontSize={"10px"} />
                                                </Badge>
                                            )}
                                            {bill?.billValidattion == "Validated" && (
                                                <Badge
                                                    variant="subtle"
                                                    colorScheme="green"
                                                    display={"flex"}
                                                    alignItems={"center"}
                                                    gap={1}
                                                >
                                                    Validated
                                                    <FaCircle fontSize={"10px"} />
                                                </Badge>
                                            )}
                                        </Flex>
                                        <AccordionIcon />
                                    </AccordionButton>
                                    <AccordionPanel pb={4}>
                                        <table>
                                            <tr>
                                                <td>Billing Id -</td>
                                                <td style={{ paddingLeft: "10px" }}>
                                                    <b>{bill?.billingId}</b>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>CTC Offered -</td>
                                                <td style={{ paddingLeft: "10px" }}>
                                                    <b>₹ {bill?.ctc}</b>{" "}
                                                    <Text as={"span"} fontSize={"sm"}>
                                                        ({numberToWords(bill?.ctc)})
                                                    </Text>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Percentage Share -</td>
                                                <td style={{ paddingLeft: "10px" }}>
                                                    <b>{bill?.pshare} %</b>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Amount to be Received -</td>
                                                <td style={{ paddingLeft: "10px" }}>
                                                    <b>₹ {bill?.amount}</b>{" "}
                                                    <Text as={"span"} fontSize={"sm"}>
                                                        ({numberToWords(bill?.amount)})
                                                    </Text>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>MBP Ends On -</td>
                                                <td style={{ paddingLeft: "10px" }}>
                                                    {" "}
                                                    <b>{formatDateDay(bill?.billingDate)}</b>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Validity Status - </td>
                                                <td style={{ paddingLeft: "10px" }}>
                                                    {bill?.billValidattion == "Pending" && (
                                                        <Badge variant="subtle" colorScheme="blue">
                                                            Pending
                                                        </Badge>
                                                    )}
                                                    {bill?.billValidattion == "Validated" && (
                                                        <Badge variant="subtle" colorScheme="green">
                                                            Validated
                                                        </Badge>
                                                    )}
                                                    {bill?.billValidattion == "Rejected" && (
                                                        <Badge variant="subtle" colorScheme="red">
                                                            Rejected
                                                        </Badge>
                                                    )}
                                                </td>
                                            </tr>
                                            {bill?.billValidattion !== "Pending" && (
                                                <tr>
                                                    <td>Validity Status Updated On -</td>
                                                    <td style={{ paddingLeft: "10px" }}>
                                                        {" "}
                                                        <b>
                                                            {formatDateDay(
                                                                bill?.validityStatusUpdatedon
                                                            )}
                                                        </b>
                                                    </td>
                                                </tr>
                                            )}
                                            {bill?.billValidattion == "Validated" && (
                                                <tr>
                                                    <td>Payment Status -</td>
                                                    <td style={{ paddingLeft: "10px" }}>
                                                        {bill?.paymentReceived ? (
                                                            <Badge
                                                                variant="subtle"
                                                                colorScheme="green"
                                                            >
                                                                Payment Received
                                                            </Badge>
                                                        ) : (
                                                            <Badge
                                                                variant="subtle"
                                                                colorScheme="red"
                                                            >
                                                                Payment Not Received
                                                            </Badge>
                                                        )}
                                                    </td>
                                                </tr>
                                            )}
                                        </table>
                                    </AccordionPanel>
                                </AccordionItem>
                            )}
                        </Accordion>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </div>
    );
};

export default ApplicationDetails;
