import {
  Box,
  Button,
  Flex,
  InputGroup,
  Input,
  InputRightElement,
  Tooltip,
  Text,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { FaFileExcel, FaFilter, FaSearch } from "react-icons/fa";
import { ImUpload2 } from "react-icons/im";

import CandidateForm from "./CandidateForm";
import { useCandidate } from "../../../context/CandidateContext";
import { useParams } from "react-router-dom";
import CandidateFilterModal from "./CandidateFilterModal";
import { tagColor } from "../../../Utils/GlobalFunctions/TagColor";
import CandidateDataModal from "./CandidateDataModal";

const CandidateHeader = ({ filterData, initialPath }) => {
  const [inputValue, setInputValue] = useState("");
  const [isCandidateFormModalOpen, setIsCandidateFormModalOpen] =
    useState(false);
  const [isCandidateFilterModalOpen, setIsCandidateFilterModalOpen] =
    useState(false);
  const [uploadCandidateModalOpen, setUploadCandidateModalOpen] =
    useState(false);
  const { setSearchedCandidates } = useCandidate();
  const { status } = useParams();

  // console.log(dataFiltered.length,dataFiltered)

  // Clear input and reset searched candidates when status changes
  useEffect(() => {
    setInputValue(""); // Clear input value
    setSearchedCandidates({
      all: [],
      selected: [],
      processing: [],
      onBench: [],
    });
  }, [status, setSearchedCandidates]);

  const handleSearchInputChange = (e) => {
    const newValue = e.target.value;

    if (newValue === "") {
      setSearchedCandidates({
        all: [],
        selected: [],
        processing: [],
        onBench: [],
      });
    }

    setInputValue(newValue);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      // console.log("enter clicked, data to be filtered: ", inputValue);
      filterData(inputValue);
    }
  };

  const handleSearchClick = () => {
    // console.log("search btn clicked, data to be filtered: ", inputValue);
    filterData(inputValue);
  };

  const handleCandidateForm = () => {
    setIsCandidateFormModalOpen(true);
  };
  const handleCandidateFile = () => {
    setUploadCandidateModalOpen(true);
  };
  const handleCandidateFilter = () => {
    console.log(isCandidateFilterModalOpen);
    setIsCandidateFilterModalOpen(true);
  };

  const handleCandidateFormCloseModal = () => {
    setIsCandidateFormModalOpen(false);
  };
  const handleCandidateFilterCloseModal = () => {
    setIsCandidateFilterModalOpen(false);
  };
  const handleCandidateFileCloseModal = () => {
    setUploadCandidateModalOpen(false);
  };
  return (
    <>
      <Flex
        direction={"row-reverse"}
        alignItems={"center"}
        position={"absolute"}
        top={"1px"}
        right="0"
      >
        <Box>
          <InputGroup>
            <Input
              placeholder="Search..."
              style={{
                background: "white",
                color: "black",
                padding: "0",
                heigth: "0px",
                borderRadius: "20px",
                paddingLeft: "10px",
                paddingRight: "32px",
              }}
              variant="unstyled"
              onMouseEnter={(e) => {
                e.target.style.background = "#cfcfcf";
              }}
              onMouseLeave={(e) => {
                e.target.style.background = "white";
              }}
              onChange={handleSearchInputChange}
              onKeyDown={handleKeyDown}
              value={inputValue}
            />
            <InputRightElement
              style={{
                color: "black",
                heigth: "0px",
                padding: "0",
                top: "-8px",
              }}
              onClick={handleSearchClick}
            >
              <FaSearch style={{ cursor: "pointer" }} />
            </InputRightElement>
          </InputGroup>
        </Box>
        <Tooltip
          hasArrow
          label={
            <Text fontSize="xs" fontWeight="bold">
              Upload candidates through Excel
            </Text>
          }
          bg="gray.300"
          color="black"
          placement="top"
        >
          <Button
            bg={tagColor("green").bg}
            color={tagColor("green").clr}
            size="xs"
            style={{
              borderRadius: "20px",
              background: "#1F2937",
              color: "white",
              marginInline: "10px",
              transition: "background 0.3s ease",
            }}
            onMouseEnter={(e) => {
              e.target.style.background = "#3B4A5A";
            }}
            onMouseLeave={(e) => {
              e.target.style.background = "#1F2937";
            }}
            onClick={() => {
              handleCandidateFile();
            }}
          >
            <ImUpload2
              size={22}
              style={{
                color: "lightgreen",
                marginInline: "10px",
                transition: "color 0.3s ease",
              }}
            />
          </Button>
        </Tooltip>

        <Button
          colorScheme="gray"
          size="xs"
          style={{
            borderRadius: "20px",
            background: "#1F2937",
            color: "white",
            marginInline: "10px",
            transition: "background 0.3s ease",
          }}
          onMouseEnter={(e) => {
            e.target.style.background = "#3B4A5A";
          }}
          onMouseLeave={(e) => {
            e.target.style.background = "#1F2937";
          }}
          onClick={() => {
            handleCandidateForm();
          }}
        >
          Add Candidate
        </Button>
        <Button
          bg={tagColor("purple").bg}
          color={tagColor("purple").clr}
          px={2}
          borderRadius={20}
          height={"25px"}
          fontWeight="bold"
          fontSize={"sm"}
          display={"flex"}
          alignItems={"center"}
          gap={1}
          onMouseEnter={(e) => {
            e.target.style.background = "#3B4A5A";
          }}
          onMouseLeave={(e) => {
            e.target.style.background = "#1F2937";
          }}
          onClick={() => {
            handleCandidateFilter();
          }}
        >
          Filter
          <FaFilter />
        </Button>
      </Flex>
      {isCandidateFilterModalOpen && (
        <CandidateFilterModal
          operation={"Filter"}
          isOpen={isCandidateFilterModalOpen}
          onClose={handleCandidateFilterCloseModal}
          initialPath={initialPath}
        />
      )}
      {isCandidateFormModalOpen && (
        <CandidateForm
          operation={"Add Candidate"}
          isOpen={isCandidateFormModalOpen}
          onClose={handleCandidateFormCloseModal}
        />
      )}
      {uploadCandidateModalOpen && (
        <CandidateDataModal
          operation={"UploadFile"}
          isOpen={uploadCandidateModalOpen}
          onClose={handleCandidateFileCloseModal}
        />
      )}
    </>
  );
};

export default CandidateHeader;
