import { Box, Flex, Heading, Skeleton, Text, Tooltip } from "@chakra-ui/react";
import React from "react";
import showHike from "../../../Utils/GlobalFunctions/showHike";
import { formatMonthYear } from "../../../Utils/GlobalFunctions/FormatDate";
import LineChart from "../../../Utils/GlobalComponents/Charts/LineChart";
import BarChart from "../../../Utils/GlobalComponents/Charts/BarChart";

const ClosingStatus = ({ loading, closings }) => {
    //console.log(closings);
    return (
        <Box
            h={"200px"}
            minW={"200px"}
            bg={"#22242b"}
            borderRadius={"md"}
            p={4}
            position={"relative"}
        >
            {loading ? (
                <Skeleton h="100%" w="100%" borderRadius={"md"} />
            ) : (
                <>
                    <Flex gap={1} align={"center"} justify={"space-between"} w={"100%"}>
                        <Heading as="h2" fontSize={"2xl"} textAlign={"left"} pl={1}>
                            {closings ? closings[0]?.noOfClosing : 0}
                        </Heading>
                        <Flex
                            align={"center"}
                            gap={1}
                            ml={2}
                            fontSize={"xs"}
                            bg={"#5d5d5d5c"}
                            px={2}
                            py={"2px"}
                            borderRadius={"md"}
                        >
                            {closings && showHike(closings[1].noOfClosing, closings[0].noOfClosing)}
                        </Flex>
                    </Flex>

                    <Text
                        fontSize={"sm"}
                        fontWeight={"bold"}
                        textAlign={"left"}
                        pl={1}
                        color={"#ffffff9c"}
                    >
                        Closings in {closings ? formatMonthYear(closings[0]?.month) : ""}
                    </Text>

                    <Flex gap={1} align={"center"} mt={2} justify={"space-between"} w={"100%"}>
                        <Heading as="h3" fontSize={"xl"} textAlign={"left"} pl={1}>
                            {closings ? closings[1]?.noOfClosing : 0}
                        </Heading>
                        <Flex
                            align={"center"}
                            gap={1}
                            ml={2}
                            fontSize={"2xs"}
                            bg={"#5d5d5d5c"}
                            px={2}
                            py={"2px"}
                            borderRadius={"md"}
                        >
                            {closings && showHike(closings[2].noOfClosing, closings[1].noOfClosing)}
                        </Flex>
                    </Flex>

                    <Text
                        fontSize={"xs"}
                        fontWeight={"bold"}
                        textAlign={"left"}
                        pl={1}
                        color={"#ffffff9c"}
                    >
                        Closings in {closings ? formatMonthYear(closings[1]?.month) : ""}
                    </Text>

                    <Box position={"absolute"} bottom={1} left={0} px={5} pb={1} w={"100%"}>
                        {closings && <BarChart height={"7px"} width={"20px"} dataset={closings} />}
                        <Text
                            fontSize={"2xs"}
                            fontWeight={"bold"}
                            textAlign={"center"}
                            pl={1}
                            color={"#ffffff9c"}
                            mt={"2px"}
                        >
                            Past 12 month closings
                        </Text>
                    </Box>
                </>
            )}
        </Box>
    );
};

export default ClosingStatus;
