import React from "react";
import { Route, Routes } from "react-router-dom";
import LoginForm from "../All/Login";
// import AdminPage from "../Admin/Pages/AdminPage";
import ProtectedRoute from "./ProtectedRoute";
import RecruiterPage from "./../Pages/RecruiterPage";
import BDPage from "./../Pages/BDPage";
import HomePage from "../Pages/HomePage";
import AdminPage from "../Pages/AdminPage";

const AllRoutes = () => {
    return (
        <Routes>
            <Route path="/login" Component={LoginForm} />

            <Route
                path="/admin_dashboard/:tab/:status"
                element={<ProtectedRoute component={AdminPage} allowedRoles={["Admin"]} />}
            />
            <Route
                path="/recruiter_dashboard/:tab/:status"
                element={<ProtectedRoute component={RecruiterPage} allowedRoles={["Recruiter"]} />}
            />
            <Route
                path="/hr_dashboard/:tab/:status"
                element={<ProtectedRoute component={RecruiterPage} allowedRoles={["HR Manager"]} />}
            />
            <Route
                path="/bd_dashboard/:tab/:status"
                element={
                    <ProtectedRoute component={BDPage} allowedRoles={["Business Developer"]} />
                }
            />
            <Route path="/" Component={HomePage} />
        </Routes>
    );
};

export default AllRoutes;
