import React, { useState, useEffect, useRef } from "react";

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Button,
  Badge,
  Text,
  Box,
  Tooltip,
  Flex,
  Avatar,
  MenuDivider,
  Skeleton,
} from "@chakra-ui/react";
import { Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";

import { TiArrowSortedUp, TiArrowSortedDown } from "react-icons/ti";
import { GrMore } from "react-icons/gr";
import { FaSort } from "react-icons/fa";
import { IoMdMale, IoMdFemale } from "react-icons/io";
import { IoMaleFemale } from "react-icons/io5";
import { MdMenu } from "react-icons/md";

import {
  formatDate,
  formatDateTime,
  formatToShortDate,
} from "../../../Utils/GlobalFunctions/FormatDate";
import getGenderIcon from "./../../../Utils/GlobalFunctions/GenderIcon";
import { useApplication } from "../../../context/ApplicationContext";

import { useEnv } from "../../../context/EnvContext";
import { useCandidate } from "../../../context/CandidateContext";
import { useJD } from "../../../context/JDContext";


import CandidateDetails from "../Candidate/CandidateDetails";
import JdDetails from "../JobDescription/JdDetails";
import RecordJoiningRes from "./RecordResult";
import RecordCanCompMBP from "./RecordCanCompMBP";
import ApplicationDetails from "../Application/ApplicationDetails";
import { debounce } from "../../../Utils/GlobalFunctions/Debounce";

const PlacementProcessBody = ({
  data,
  loading,
  fetchAllApplications,
  hasMore,
  pageNo,
  status,
}) => {
  //console.log("data", data);
  //console.log("hasMore", hasMore);
  //console.log("pageNo", pageNo);
  //console.log("status", status);

  const { backendUrl } = useEnv();
  const {
    candidateData,
    setCandidateData,
    fetchSpecificCandidate,
    loadingCandidate,
  } = useCandidate();
  const { jobDescriptionData, setJobDescriptionData, fetchJDById, loadingJD } =
    useJD();
  const { recordCandJoiningRes, markCanComMBP } = useApplication();

  // handle view modals
  const [selectedApplication, setSelectedApplication] = useState(null);
  const [isCandidateDetailsModalOpen, setIsCandidateDetailsModalOpen] =
    useState(false);
  const [isJDDetailsModalOpen, setIsJDDetailsModalOpen] = useState(false);
  const [isApplicationDetailsModalOpen, setIsApplicationDetailsModalOpen] =
    useState(false);
  const [isRecordResultModalOpen, setIsRecordResultModalOpen] = useState(false);
  const [isRecResModalOpen, setIsRecResModalOpen] = useState(false);
  const [isRecCanCompMBPModalOpen, setIsRecCanCompMBPModalOpen] =
    useState(false);
  const [isUpdatingResult, setIsUpdatingResult] = useState(false);

  const containerRef = useRef(null); // Reference for the scrollable div
  const endRef = useRef(null);
  const pageNoRef = useRef(pageNo);
  const [scrollInitiated, setScrollInitiated] = useState(false); // Track if user has scrolled

  const [sortedData, setSortedData] = useState(data);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: null,
  });

  // Update pageNoRef whenever pageNo changes
  useEffect(() => {
    pageNoRef.current = pageNo;
  }, [pageNo]);

  const fetchMoreData = async () => {
    if (loading || !hasMore || !scrollInitiated) return;

    await fetchAllApplications("offerProcess", status, pageNo, 30, true);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && scrollInitiated) {
          console.log("Fetching more data...");
          fetchMoreData();
        }
      },
      {
        root: containerRef.current,
        rootMargin: "0px 0px 100px 0px", // Adjusted to trigger earlier for smoother loading
        threshold: 0.5, // Increased to trigger loading more reliably
      }
    );

    if (containerRef.current) {
      containerRef.current.scrollIntoView({ behavior: "smooth" });
    }

    if (endRef.current) {
      endRef.current.scrollIntoView({ behavior: "smooth" });
      observer.observe(endRef.current);
    }

    return () => {
      if (endRef.current) {
        observer.unobserve(endRef.current);
      }
    };
  }, [hasMore, loading, scrollInitiated]);

  // Track user scrolling within the container
  const handleScroll = debounce(() => {
    if (containerRef.current.scrollTop > 0) {
      setScrollInitiated(true);
    }
  }, 300); // Adjust the delay as needed

  // Handle Candidate Details modal Preview
  const handleCandidatePreview = (candidate) => {
    // fetch the candidate
    fetchSpecificCandidate(candidate?._id);
    setIsCandidateDetailsModalOpen(true);
  };

  const handleCandidateViewCloseModal = () => {
    setIsCandidateDetailsModalOpen(false);
    setCandidateData(null);
  };

  // Handle JD Details modal Preview
  const handleJDPreview = (jd) => {
    // fetch the jd
    fetchJDById(jd?._id);
    setIsJDDetailsModalOpen(true);
  };

  const handleJDViewCloseModal = () => {
    setIsJDDetailsModalOpen(false);
    setJobDescriptionData(null);
  };

  // Handle Application Details modal Preview
  const handleApplicationPreview = () => {
    setIsApplicationDetailsModalOpen(true);
  };
  const handleApplicationViewCloseModal = () => {
    setIsApplicationDetailsModalOpen(false);
    setSelectedApplication(null);
  };

  // Handle Record joining Response modal Preview
  const handleRecordResponsePreview = (application) => {
    setSelectedApplication(application);
    setIsRecResModalOpen(true);
  };
  const handleRecordResponseViewCloseModal = () => {
    setIsRecResModalOpen(false);
    setIsUpdatingResult(false);
    setSelectedApplication(null);
  };

  // Handle Record Candidate Completing MBP modal Preview
  const handleRecordCanCompMBPPreview = (application) => {
    setSelectedApplication(application);
    setIsRecCanCompMBPModalOpen(true);
  };
  const handleRecordCanCompMBPViewCloseModal = () => {
    setIsRecCanCompMBPModalOpen(false);
    setIsUpdatingResult(false);
    setSelectedApplication(null);
  };

  // Sort the data
  useEffect(() => {
    let sortedArray = [...data];
    if (sortConfig?.key) {
      sortedArray?.sort((a, b) => {
        let aValue = a[sortConfig?.key];
        let bValue = b[sortConfig?.key];

        // Handle nested keys for jobDescription
        if (sortConfig?.key === "jobDescription?.jobTitle") {
          aValue = a?.jobDescription?.jobTitle;
          bValue = b?.jobDescription?.jobTitle;
        }

        // Handle nested keys for candidate
        if (sortConfig?.key === "candidate?.name") {
          aValue = a?.candidate?.name;
          bValue = b?.candidate?.name;
        }

        // Handle nested keys for placementDetails offerId
        if (sortConfig?.key === "placementDetails?.offerId") {
          aValue = a?.placementDetails?.offerId;
          bValue = b?.placementDetails?.offerId;
        }

        // Handle nested keys for placementDetails newOfferedSalary
        if (sortConfig?.key === "placementDetails?.newOfferedSalary") {
          aValue = a?.placementDetails?.newOfferedSalary;
          bValue = b?.placementDetails?.newOfferedSalary;
        }

        // Handle nested keys for placementDetails offeredOn
        if (sortConfig?.key === "placementDetails?.offeredOn") {
          aValue = a?.placementDetails?.offeredOn;
          bValue = b?.placementDetails?.offeredOn;
        }
        // Handle nested keys for placementDetails offerStatus
        if (sortConfig?.key === "placementDetails?.offerStatus") {
          aValue = a?.placementDetails?.offerStatus;
          bValue = b?.placementDetails?.offerStatus;
        }

        // Handle nested keys for placementDetails offerResRecordOn
        if (sortConfig?.key === "placementDetails?.offerResRecordOn") {
          aValue = a?.placementDetails?.offerResRecordOn;
          bValue = b?.placementDetails?.offerResRecordOn;
        }

        if (aValue < bValue) {
          return sortConfig?.direction === "ascending" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig?.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    setSortedData(sortedArray);
  }, [sortConfig, data]);

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig?.key === key && sortConfig?.direction === "ascending") {
      direction = "descending";
    } else if (
      sortConfig?.key === key &&
      sortConfig?.direction === "descending"
    ) {
      setSortConfig({ key: null, direction: null });
      return;
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (key) => {
    if (sortConfig?.key === key) {
      if (sortConfig?.direction === "ascending") {
        return <TiArrowSortedUp cursor={"pointer"} />;
      } else if (sortConfig?.direction === "descending") {
        return <TiArrowSortedDown cursor={"pointer"} />;
      }
    }
    return <FaSort cursor={"pointer"} />;
  };

  // Function for checking in minimum billing period is complted with buffer date
  const hasMinBillingDateCompleted = (application) => {
    const billingPeriod = application?.jobDescription?.billingPeriod;
    const currentDate = new Date();

    // Add 5 to billingPeriod to have 15 days of buffer for marking it correct
    const billingDate = new Date(
      currentDate.getTime() + (billingPeriod + 15) * 24 * 60 * 60 * 1000
    );

    return billingDate > currentDate;
  };

  return (
    <div
      ref={containerRef}
      style={{
        height: "75vh",
        overflow: "auto",
        position: "relative",
      }}
      onScroll={handleScroll} // Trigger scroll detection
    >
      <TableContainer style={{ position: "relative", overflowY: "auto" }}>
        <Table size="sm">
          <Thead
            style={{
              position: "sticky",
              top: 0,
              backgroundColor: "#05080D",
              zIndex: 1,
            }}
          >
            <Tr>
              <Th style={{ color: "white" }}>Sl</Th>
              <Th
                style={{ color: "white" }}
                onClick={() => requestSort("placementDetails?.placementId")}
              >
                <Flex
                  alignItems={"center"}
                  width={"100%"}
                  justify={"space-between"}
                >
                  <Tooltip
                    hasArrow
                    label={
                      <>
                        <Text textAlign={"center"}>
                          Offer Id, Application Id
                        </Text>
                        <Text
                          fontSize={"xs"}
                          color={"gray"}
                          textAlign={"center"}
                        >
                          Click to view the Application Details
                        </Text>
                      </>
                    }
                    bg="gray.300"
                    color="black"
                    placement="top"
                  >
                    Ids
                  </Tooltip>
                  {getSortIcon("placementDetails?.placementId")}
                </Flex>
              </Th>
              <Th
                style={{
                  color: "white",
                }}
                onClick={() => requestSort("jobDescription?.jobTitle")}
              >
                <Flex
                  alignItems={"center"}
                  width={"100%"}
                  justify={"space-between"}
                >
                  <Tooltip
                    hasArrow
                    label={
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Text fontSize={"sm"} textAlign={"center"}>
                          Job Id, Job Title
                        </Text>
                        <Text
                          fontSize={"xs"}
                          color={"gray"}
                          textAlign={"center"}
                        >
                          Click on job Id to view the JD details
                        </Text>
                      </Box>
                    }
                    bg="gray.300"
                    color="black"
                    placement="top"
                  >
                    Job Description
                  </Tooltip>
                  {getSortIcon("jobDescription?.jobTitle")}
                </Flex>
              </Th>
              <Th
                style={{
                  color: "white",
                }}
                onClick={() => requestSort("candidate?.name")}
              >
                <Flex
                  alignItems={"center"}
                  width={"100%"}
                  justify={"space-between"}
                >
                  <Tooltip
                    hasArrow
                    label={
                      <>
                        <Flex align={"center"}>
                          <Avatar
                            src=""
                            size="sm"
                            name="Display Picture"
                            mr={2}
                            background={"green"}
                            color={"white"}
                          />
                          <Box
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Text
                              fontSize={"sm"}
                              style={{
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                              }}
                            >
                              <b>Candidate Id</b>
                              {" - "}
                              Name, gender
                            </Text>
                            <Text
                              fontSize={"sm"}
                              color={"#2b2a2a"}
                              style={{
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                maxWidth: "180px",
                              }}
                            >
                              Candidate Email
                            </Text>
                          </Box>
                        </Flex>
                        <Flex
                          direction={"column"}
                          fontSize={"xs"}
                          color={"gray"}
                          mt={2}
                        >
                          <Text color={"black"}>Gender Details: </Text>
                          <Flex
                            align={"center"}
                            gap={1}
                            background={"black"}
                            p={1}
                            px={2}
                            color={"white"}
                          >
                            <IoMdMale color="#2D9AFE" />
                            <Text>Male, </Text>
                            <IoMdFemale color="#F86CFF" />
                            <Text>Female, </Text>
                            <IoMaleFemale color="#ffd600" />
                            <Text>Other</Text>
                          </Flex>
                        </Flex>
                        <Text
                          fontSize={"xs"}
                          color={"gray"}
                          textAlign={"center"}
                        >
                          Click on the DP to view the candidate details <br />
                          Click on mail id to mail the candidate.
                        </Text>
                      </>
                    }
                    bg="gray.300"
                    color="black"
                    placement="top"
                  >
                    Candidate
                  </Tooltip>
                  {getSortIcon("candidate?.name")}
                </Flex>
              </Th>

              <Th
                style={{ color: "white" }}
                onClick={() => requestSort("placementDetails?.joiningDate")}
              >
                <Flex
                  alignItems={"center"}
                  width={"100%"}
                  justify={"space-between"}
                >
                  <Tooltip
                    hasArrow
                    label="Joining Date"
                    bg="gray?.300"
                    color="black"
                    placement="top"
                  >
                    Join Dt.
                  </Tooltip>
                  {getSortIcon("placementDetails?.joiningDate")}
                </Flex>
              </Th>

              <Th style={{ color: "white" }}>
                <Tooltip
                  hasArrow
                  label={
                    <>
                      <Text textAlign={"center"}>
                        Notice Period Starting Date
                      </Text>
                      <Text textAlign={"center"}>
                        Notice Period Ending Date
                      </Text>
                    </>
                  }
                  bg="gray.300"
                  color="black"
                  placement="top"
                >
                  np. DT.
                </Tooltip>
              </Th>

              <Th
                style={{ color: "white" }}
                onClick={() => requestSort("placementDetails?.recordedDate")}
              >
                <Flex
                  alignItems={"center"}
                  width={"100%"}
                  justify={"space-between"}
                >
                  <Tooltip
                    hasArrow
                    label={
                      <>
                        <Text textAlign={"center"}>
                          Placement Details Recorded On
                        </Text>
                        <Text textAlign={"center"} color={"gray"}>
                          Billing Date for this Application
                        </Text>
                      </>
                    }
                    bg="gray.300"
                    color="black"
                    placement="top"
                  >
                    pl Dt & bl Dt
                  </Tooltip>
                  {getSortIcon("placementDetails?.recordedDate")}
                </Flex>
              </Th>
              {status != "pending" && (
                <Th style={{ color: "white" }} textAlign={"center"}>
                  <Tooltip
                    hasArrow
                    label={
                      <>
                        <Flex gap={3} align={"center"} justify={"center"}>
                          <Box>
                            <Flex align={"center"} gap={2}>
                              <Box
                                h={3}
                                w={3}
                                borderRadius={"50%"}
                                bgColor={"#00cb00"}
                              ></Box>
                              <Text fontSize={"xs"} color={"gray"}>
                                Joined
                              </Text>
                            </Flex>
                            <Flex align={"center"} gap={2}>
                              <Box
                                h={3}
                                w={3}
                                borderRadius={"50%"}
                                bgColor={"red"}
                              ></Box>
                              <Text fontSize={"xs"} color={"gray"}>
                                Not Joined
                              </Text>
                            </Flex>
                          </Box>
                          <Text fontSize={"xs"} color={"black"}>
                            Joining Status
                            <br />
                            Recorded On
                          </Text>
                        </Flex>
                        {status == "all" && (
                          <Text
                            fontSize={"xs"}
                            color={"gray"}
                            mt={2}
                            textAlign={"center"}
                          >
                            Or Record Joing Status
                          </Text>
                        )}
                      </>
                    }
                    bg="gray.300"
                    color="black"
                    placement="top"
                  >
                    Joining Status
                  </Tooltip>
                </Th>
              )}
              {status == "pending" && (
                <Th
                  textAlign={"center"}
                  style={{
                    color: "white",
                  }}
                >
                  <Tooltip
                    hasArrow
                    label="Click to perform respective Action"
                    bg="gray.300"
                    color="black"
                    placement="top"
                  >
                    Actions
                  </Tooltip>
                </Th>
              )}

              <Th style={{ color: "white" }}>
                {" "}
                <Tooltip
                  hasArrow
                  label='Click on respective "Menu Button" for more options.'
                  bg="gray.300"
                  color="black"
                  placement="top"
                  maxW={"200px"}
                >
                  <Box>
                    <MdMenu />
                  </Box>
                </Tooltip>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {sortedData &&
              sortedData?.map((application, index) => (
                <Tr key={index}>
                  <Td>{index + 1}</Td>
                  <Td style={{ cursor: "pointer" }}>
                    <Tooltip
                      hasArrow
                      label={
                        <Text fontSize={"xs"} color={"gray"}>
                          Click to view Application in Details
                        </Text>
                      }
                      bg="gray.300"
                      color="black"
                      placement="top"
                    >
                      <Flex
                        direction={"column"}
                        onClick={() => {
                          setSelectedApplication(application);
                          handleApplicationPreview(application);
                        }}
                      >
                        <Text>
                          {application?.placementDetails?.placementId}
                        </Text>
                        <Text fontSize={"sm"} color={"#a3a3a3"}>
                          {application?.applicationID}
                        </Text>
                      </Flex>
                    </Tooltip>
                  </Td>
                  <Td
                    style={{
                      maxWidth: "230px",
                      overflow: "hidden",
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Text fontSize={"sm"}>
                        <Tooltip
                          hasArrow
                          label={
                            <Text fontSize={"xs"} color={"gray"}>
                              Click to view{" "}
                              <b>{application?.jobDescription?.jobId}</b> in
                              Details
                            </Text>
                          }
                          bg="gray.300"
                          color="black"
                          placement="top"
                        >
                          <Flex
                            style={{ cursor: "pointer" }}
                            cursor={loadingJD ? "progress" : "progress"}
                            onClick={() => {
                              if (!loadingJD)
                                handleJDPreview(application?.jobDescription);
                            }}
                          >
                            {application?.jobDescription?.jobId}
                          </Flex>
                        </Tooltip>
                      </Text>

                      <Text
                        fontSize={"sm"}
                        color={"#a3a3a3"}
                        style={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          maxWidth: "180px",
                        }}
                      >
                        {application?.jobDescription?.jobTitle}
                      </Text>
                    </Box>
                  </Td>
                  <Td
                    style={{
                      maxWidth: "250px",
                      overflow: "hidden",
                    }}
                  >
                    <Flex>
                      <Tooltip
                        hasArrow
                        label={
                          <Text fontSize={"xs"} color={"gray"}>
                            Click to view Candidate{" "}
                            <b>{application?.candidate?.stuId}</b> in Details
                          </Text>
                        }
                        bg="gray.300"
                        color="black"
                        placement="top"
                      >
                        <Avatar
                          src={`${backendUrl}${application?.candidate?.dp}`}
                          size="sm"
                          name={application?.candidate?.name}
                          mr={2}
                          background={"green"}
                          color={"white"}
                          cursor={loadingCandidate ? "progress" : "pointer"}
                          onClick={() => {
                            if (!loadingCandidate)
                              handleCandidatePreview(application?.candidate);
                          }}
                        />
                      </Tooltip>

                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Flex gap={1}>
                          <Text
                            fontSize={"sm"}
                            style={{
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              maxWidth: "150px",
                            }}
                          >
                            <span
                              style={{
                                color: "#a3a3a3",
                                fontWeight: "bold",
                              }}
                            >
                              {application?.candidate?.stuId}
                            </span>
                            {" - "}
                            {application?.candidate?.name}
                          </Text>
                          {getGenderIcon(application?.candidate?.gender)}
                        </Flex>
                        <Text
                          fontSize={"sm"}
                          color={"#a3a3a3"}
                          style={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            maxWidth: "160px",
                          }}
                        >
                          <Tooltip
                            hasArrow
                            label={
                              <Text fontSize={"xs"} color={"gray"}>
                                Click to mail the Candidate
                              </Text>
                            }
                            bg="gray.300"
                            color="black"
                            placement="bottom"
                          >
                            <a
                              target="_blank"
                              href={`mailto:${application?.candidate?.email}`}
                            >
                              {application?.candidate?.email}
                            </a>
                          </Tooltip>
                        </Text>
                      </Box>
                    </Flex>
                  </Td>
                  <Td>
                    {formatToShortDate(
                      application?.placementDetails?.joiningDate
                    )}
                  </Td>
                  <Td>
                    <Flex direction={"column"}>
                      <Text>
                        {formatToShortDate(
                          application?.placementDetails?.noticePeriodStartDate
                        )}
                      </Text>
                      <Text fontSize={"sm"} color={"#a3a3a3"}>
                        {formatToShortDate(
                          application?.placementDetails?.noticePeriodEndDate
                        )}
                      </Text>
                    </Flex>
                  </Td>

                  <Td>
                    <Flex direction={"column"}>
                      <Text>
                        {formatDateTime(
                          application?.placementDetails?.recordedDate
                        )}
                      </Text>
                      {application?.billingDate && (
                        <Text fontSize={"sm"} color={"#a3a3a3"}>
                          {formatDate(application?.billingDate)}
                        </Text>
                      )}
                    </Flex>
                  </Td>
                  <Td>
                    <Flex justify={"space-between"} align={"center"} w={"100%"}>
                      <Tooltip
                        hasArrow
                        label={
                          application?.placementDetails?.remarks && (
                            <>
                              Remark:
                              <Box whiteSpace="pre-line">
                                {application?.placementDetails?.remarks}
                              </Box>
                            </>
                          )
                        }
                        bg="gray.300"
                        color="black"
                        placement="top"
                      >
                        <Flex justify={"center"} align={"center"}>
                          {application?.placementDetails?.isJoined === true && (
                            <Flex gap={1} align={"center"} justify={"center"}>
                              <Box
                                h={3}
                                w={3}
                                borderRadius={"50%"}
                                bgColor={"#00cb00"}
                              ></Box>
                              {formatDateTime(
                                application?.placementDetails?.recordedDate
                              )}
                            </Flex>
                          )}
                          {application?.placementDetails?.isJoined ===
                            false && (
                            <Flex gap={1} align={"center"} justify={"center"}>
                              <Box
                                h={3}
                                w={3}
                                borderRadius={"50%"}
                                bgColor={
                                  application?.placementDetails?.isJoined ===
                                  true
                                    ? "#00cb00"
                                    : "red"
                                }
                              ></Box>
                              {formatDateTime(
                                application?.placementDetails?.recordedDate
                              )}
                            </Flex>
                          )}
                        </Flex>
                      </Tooltip>
                      {application?.placementDetails?.isJoined === true &&
                        application?.billValidattion == "Pending" &&
                        hasMinBillingDateCompleted(application) && (
                          <Tooltip
                            hasArrow
                            label={
                              <>
                                <Text
                                  fontSize={"xs"}
                                  textAlign={"center"}
                                  w={"170px"}
                                  fontWeight={"bold"}
                                >
                                  Is Candidate Completed Minimum Billing Period
                                </Text>
                                <Text
                                  fontSize={"xs"}
                                  color={"gray"}
                                  textAlign={"center"}
                                  w={"170px"}
                                  mt={2}
                                >
                                  Click on this button to update the application
                                  validation for candidate staying or leaving
                                  the company for the duration of minimum
                                  billing period.
                                </Text>
                              </>
                            }
                            bg="gray.300"
                            color="black"
                            placement="top"
                          >
                            <Button
                              colorScheme="green"
                              size="xs"
                              ml={2}
                              onClick={() =>
                                handleRecordCanCompMBPPreview(application)
                              }
                            >
                              ICCMBP
                            </Button>
                          </Tooltip>
                        )}
                      {application?.billValidattion == "Validated" && (
                        <Text
                          ml={2}
                          fontSize={"xs"}
                          color={"#00CB00"}
                          textAlign={"center"}
                        >
                          Billing <br /> Approved
                        </Text>
                      )}{" "}
                      {application?.billValidattion == "Rejected" && (
                        <Tooltip
                          hasArrow
                          label={
                            application?.rejectionRemarks && (
                              <>
                                Remark:
                                <Box whiteSpace="pre-line">
                                  {application?.rejectionRemarks}
                                </Box>
                              </>
                            )
                          }
                          bg="gray.300"
                          color="black"
                          placement="top"
                        >
                          <Text
                            ml={2}
                            fontSize={"xs"}
                            color={"red"}
                            textAlign={"center"}
                          >
                            Billing <br /> Rejected
                          </Text>
                        </Tooltip>
                      )}
                      {application?.placementDetails?.isJoined !== true &&
                        application?.placementDetails?.isJoined !== false && (
                          <Button
                            colorScheme="blue"
                            size="xs"
                            onClick={() =>
                              handleRecordResponsePreview(application)
                            }
                          >
                            Rec. Joining Status
                          </Button>
                        )}
                    </Flex>
                  </Td>

                  <Td>
                    <Menu>
                      <MenuButton>
                        <GrMore />
                      </MenuButton>
                      <MenuList minW={"50px"}>
                        <MenuItem
                          onClick={() => {
                            setSelectedApplication(application);
                            handleApplicationPreview(application);
                          }}
                        >
                          View Application
                        </MenuItem>
                        {application?.placementDetails?.isJoined !== true &&
                        application?.placementDetails?.isJoined !== false ? (
                          <MenuItem
                            onClick={() =>
                              handleRecordResponsePreview(application)
                            }
                          >
                            Record Joining Status
                          </MenuItem>
                        ) : (
                          <MenuItem
                            onClick={() => {
                              setIsUpdatingResult(true);
                              handleRecordResponsePreview(application);
                            }}
                          >
                            Update joining Status
                          </MenuItem>
                        )}
                        {application?.placementDetails?.isJoined === true &&
                          application?.billValidattion == "Pending" &&
                          hasMinBillingDateCompleted(application) && (
                            <MenuItem
                              onClick={() =>
                                handleRecordCanCompMBPPreview(application)
                              }
                            >
                              Is Candidate Completed Billing Period
                            </MenuItem>
                          )}{" "}
                        {application?.billValidattion !== "Pending" && (
                          <MenuItem
                            onClick={() => {
                              setIsUpdatingResult(true);
                              handleRecordCanCompMBPPreview(application);
                            }}
                          >
                            Update Is Candidate Completed Billing Period
                          </MenuItem>
                        )}
                      </MenuList>
                    </Menu>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </TableContainer>

      {(loading ||
        hasMore) && (
          <>
            <Skeleton height="30px" m={2} />
            <Skeleton height="30px" m={2} />
            <Skeleton height="30px" m={2} />
          </>
        )}
      {(!hasMore && !loading) && (
        <p style={{ textAlign: "center", marginTop: "5px" }}>
          <b>End of the List</b>
        </p>
      )}

      {isCandidateDetailsModalOpen && candidateData && (
        <CandidateDetails
          candidate={candidateData}
          isOpen={isCandidateDetailsModalOpen}
          onClose={handleCandidateViewCloseModal}
        />
      )}

      {isJDDetailsModalOpen && jobDescriptionData && (
        <JdDetails
          jd={jobDescriptionData}
          isOpen={isJDDetailsModalOpen}
          onClose={handleJDViewCloseModal}
        />
      )}

      {isRecResModalOpen && selectedApplication && (
        <RecordJoiningRes
          application={selectedApplication}
          isOpen={isRecResModalOpen}
          onClose={handleRecordResponseViewCloseModal}
          status={status}
          recordCandJoiningRes={recordCandJoiningRes}
          isUpdating={isUpdatingResult}
        />
      )}

      {isRecCanCompMBPModalOpen && selectedApplication && (
        <RecordCanCompMBP
          application={selectedApplication}
          isOpen={isRecCanCompMBPModalOpen}
          onClose={handleRecordCanCompMBPViewCloseModal}
          markCanComMBP={markCanComMBP}
          isUpdating={isUpdatingResult}
        />
      )}

      {selectedApplication && isApplicationDetailsModalOpen && (
        <ApplicationDetails
          application={selectedApplication}
          isOpen={isApplicationDetailsModalOpen}
          onClose={handleApplicationViewCloseModal}
          tab={3}
        />
      )}
      <div ref={endRef} style={{ height: "1px" }}></div>
    </div>
  );
};

export default PlacementProcessBody;
