import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Tabs, TabList, TabPanels, Tab, TabPanel, Text } from "@chakra-ui/react";

import PlacementProcessBody from "./PlacementProcessBody";
import { tagColor } from "../../../Utils/GlobalFunctions/TagColor";
import { useApplication } from "../../../context/ApplicationContext";
import { useAuth } from "../../../context/AuthContext";

const PlacementProcessContainer = () => {
    const { loading, allApplications, hasMoreApplications, applicationsPageNo, fetchApplications } =
        useApplication();
    const { userData } = useAuth();

    const [tabIndex, setTabIndex] = useState(0);
    const { status } = useParams();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const initialPath = pathname.split("/")[1];

    useEffect(() => {
        //console.log("userData**", userData);

        switch (status) {
            case "all":
                fetchApplications("placementProcess", "all", 1, 30);
                setTabIndex(0);
                break;
            case "accepted":
                fetchApplications("placementProcess", "accepted", 1, 30);
                setTabIndex(1);
                break;
            case "rejected":
                fetchApplications("placementProcess", "rejected", 1, 30);
                setTabIndex(2);
                break;
            case "pending":
                fetchApplications("placementProcess", "pending", 1, 30);
                setTabIndex(3);
                break;
            default:
                fetchApplications("placementProcess", "all", 1, 30);
                setTabIndex(0);
                break;
        }
    }, [status]);

    const handleTabsChange = (index) => {
        setTabIndex(index);
        const tabMapping = ["all", "accepted", "rejected", "pending"];
        navigate(`/${initialPath}/placementProcess/${tabMapping[index]}`);
    };

    const applicationData = {};
    const [filteredData, setFilteredData] = useState(applicationData);
    const filterData = (query) => {
        //console.log("query", query);
        if (!query) {
            setFilteredData(applicationData);
            return;
        }

        const filtered = applicationData.filter((item) => {
            return deepSearch(item, query);
        });

        //console.log("filtered", filtered);
        setFilteredData(filtered);
    };

    const deepSearch = (obj, query) => {
        for (const key in obj) {
            const value = obj[key];
            if (typeof value === "object") {
                if (deepSearch(value, query)) {
                    return true;
                }
            } else if (
                typeof value === "string" ||
                typeof value === "number" ||
                typeof value === "boolean"
            ) {
                if (String(value).toLowerCase().includes(query.toLowerCase())) {
                    return true;
                }
            } else if (Array.isArray(value)) {
                for (const item of value) {
                    if (typeof item === "object") {
                        if (deepSearch(item, query)) {
                            return true;
                        }
                    } else if (
                        typeof item === "string" ||
                        typeof item === "number" ||
                        typeof item === "boolean"
                    ) {
                        if (String(item).toLowerCase().includes(query.toLowerCase())) {
                            return true;
                        }
                    }
                }
            }
        }
        return false;
    };

    const filterByStatus = (status) => {
        if (status === "pending") {
            return filteredData.filter(
                (item) =>
                    item.placementDetails.isJoined !== true &&
                    item.placementDetails.isJoined !== false
            );
        }
        return filteredData.filter((item) => item.placementDetails.isJoined === status);
    };
    return (
        <>
            <Tabs isLazy variant="enclosed" index={tabIndex} onChange={handleTabsChange}>
                {/* <PlacementProcessHeader filterData={filterData} /> */}
                <TabList>
                    <Tab>
                        <Text
                            bg={tagColor("").bg}
                            color={tagColor("").clr}
                            px={2}
                            borderRadius={20}
                            fontWeight="bold"
                            fontSize={"sm"}
                        >
                            All
                        </Text>
                    </Tab>
                    <Tab>
                        <Text
                            bg={tagColor("green").bg}
                            color={tagColor("green").clr}
                            px={2}
                            borderRadius={20}
                            fontWeight="bold"
                            fontSize={"sm"}
                        >
                            Joined
                        </Text>
                    </Tab>
                    <Tab>
                        <Text
                            bg={tagColor("red").bg}
                            color={tagColor("red").clr}
                            px={2}
                            borderRadius={20}
                            fontWeight="bold"
                            fontSize={"sm"}
                        >
                            Not Joined
                        </Text>
                    </Tab>
                    <Tab>
                        <Text
                            bg={tagColor("blue").bg}
                            color={tagColor("blue").clr}
                            px={2}
                            borderRadius={20}
                            fontWeight="bold"
                            fontSize={"sm"}
                        >
                            Pending
                        </Text>
                    </Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <PlacementProcessBody
                            data={allApplications?.placementProcess?.all}
                            fetchAllApplications={fetchApplications}
                            hasMore={hasMoreApplications.placementProcess?.all}
                            pageNo={applicationsPageNo.placementProcess?.all}
                            status={"all"}
                            loading={loading}
                        />
                    </TabPanel>
                    <TabPanel>
                        <PlacementProcessBody
                            data={allApplications?.placementProcess?.accepted}
                            fetchAllApplications={fetchApplications}
                            hasMore={hasMoreApplications.placementProcess?.accepted}
                            pageNo={applicationsPageNo.placementProcess?.accepted}
                            status={"accepted"}
                            loading={loading}
                        />
                    </TabPanel>
                    <TabPanel>
                        <PlacementProcessBody
                            data={allApplications?.placementProcess?.rejected}
                            fetchAllApplications={fetchApplications}
                            hasMore={hasMoreApplications.placementProcess?.rejected}
                            pageNo={applicationsPageNo.placementProcess?.rejected}
                            status={"rejected"}
                            loading={loading}
                        />
                    </TabPanel>
                    <TabPanel>
                        <PlacementProcessBody
                            data={allApplications?.placementProcess?.pending}
                            fetchAllApplications={fetchApplications}
                            hasMore={hasMoreApplications.placementProcess?.pending}
                            pageNo={applicationsPageNo.placementProcess?.pending}
                            status={"pending"}
                            loading={loading}
                        />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </>
    );
};

export default PlacementProcessContainer;
