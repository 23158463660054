// Applications to be Processed Today

import { Box, Flex, Heading, Skeleton, Text, Tooltip } from "@chakra-ui/react";
import React from "react";
import showHike from "../../../Utils/GlobalFunctions/showHike";
import { formatMonthYear } from "../../../Utils/GlobalFunctions/FormatDate";
import LineChart from "../../../Utils/GlobalComponents/Charts/LineChart";

const EarningStatus = ({ loading, earnings }) => {
    //console.log(earnings);
    return (
        <Box
            h={"200px"}
            minW={"200px"}
            bg={"#22242b"}
            borderRadius={"md"}
            p={4}
            position={"relative"}
        >
            {loading ? (
                <Skeleton h="100%" w="100%" borderRadius={"md"} />
            ) : (
                <>
                    <Flex gap={1} align={"center"} justify={"space-between"} w={"100%"}>
                        <Flex gap={1}>
                            <Text fontWeight={"bold"} fontSize={"xs"} color={"gray"}>
                                ₹
                            </Text>
                            <Heading as="h2" fontSize={"2xl"} textAlign={"left"} pl={1}>
                                {earnings ? earnings[0]?.totalIncome : 0}
                            </Heading>
                        </Flex>
                        <Flex
                            align={"center"}
                            gap={1}
                            ml={2}
                            fontSize={"xs"}
                            bg={"#5d5d5d5c"}
                            px={2}
                            py={"2px"}
                            borderRadius={"md"}
                        >
                            {earnings && showHike(earnings[0].totalIncome, earnings[1].totalIncome)}
                        </Flex>
                    </Flex>

                    <Text
                        fontSize={"sm"}
                        fontWeight={"bold"}
                        textAlign={"left"}
                        pl={1}
                        color={"#ffffff9c"}
                    >
                        Earnings in {earnings ? formatMonthYear(earnings[0]?.month) : ""}
                    </Text>

                    <Flex gap={1} align={"center"} mt={2} justify={"space-between"} w={"100%"}>
                        <Flex gap={1}>
                            <Text fontWeight={"bold"} fontSize={"xs"} color={"gray"}>
                                ₹
                            </Text>
                            <Heading as="h32" fontSize={"xl"} textAlign={"left"} pl={1}>
                                {earnings ? earnings[1]?.totalIncome : 0}
                            </Heading>
                        </Flex>
                        <Flex
                            align={"center"}
                            gap={1}
                            ml={2}
                            fontSize={"2xs"}
                            bg={"#5d5d5d5c"}
                            px={2}
                            py={"2px"}
                            borderRadius={"md"}
                        >
                            {earnings && showHike(earnings[1].totalIncome, earnings[2].totalIncome)}
                        </Flex>
                    </Flex>

                    <Text
                        fontSize={"xs"}
                        fontWeight={"bold"}
                        textAlign={"left"}
                        pl={1}
                        color={"#ffffff9c"}
                    >
                        Earnings in {earnings ? formatMonthYear(earnings[1]?.month) : ""}
                    </Text>

                    <Box position={"absolute"} bottom={1} left={0} px={5} pb={1} w={"100%"}>
                        {earnings && <LineChart height={"7px"} width={"20px"} dataset={earnings} />}
                        <Text
                            fontSize={"2xs"}
                            fontWeight={"bold"}
                            textAlign={"center"}
                            pl={1}
                            color={"#ffffff9c"}
                        >
                            Past 12 month earnings
                        </Text>
                    </Box>
                </>
            )}
        </Box>
    );
};

export default EarningStatus;
