import React from "react";
import NavBar from "../Components/NavBar/NavBar";
import RecruiterBody from "../Components/Body/RecruiterBody";

const RecruiterPage = () => {
    return (
        <div>
            <NavBar />
            <RecruiterBody/>
        </div>
    );
};

export default RecruiterPage;
