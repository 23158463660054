import React, { useEffect } from "react";

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Tag,
    TagLabel,
    Box,
    Text,
    Avatar,
    Flex,
    Table,
    Tbody,
    Tr,
    Td,
    TableContainer,
    Badge,
} from "@chakra-ui/react";

import { formatDateTime } from "../../../Utils/GlobalFunctions/FormatDate";
import { useEnv } from "../../../context/EnvContext";

const ClientDetails = ({ isOpen, onClose, client }) => {
    const { backendUrl } = useEnv();

    useEffect(() => {
        if (!client) return;
    }, [client]);

    return (
        <div>
            <Modal
                isLazy
                isOpen={isOpen}
                onClose={onClose}
                size={"4xl"}
                style={{ minHeight: "100vh" }}
            >
                <ModalOverlay />
                <ModalContent className="jd-details">
                    <ModalHeader>
                        <Flex gap={1} align={"center"}>
                            {client?.clientId} -{" "}
                            <Avatar
                                size="xs"
                                name={client?.name}
                                src={`${backendUrl}${client?.logo}`}
                            />
                            {client?.name}
                        </Flex>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody style={{ paddingBottom: "20px" }}>
                        <TableContainer width="100%" style={{ whiteSpace: "normal" }}>
                            <Table variant="simple">
                                <Tbody>
                                    <Tr>
                                        <Td>
                                            <b>Contact Number:</b>
                                        </Td>
                                        <Td>{client?.contactNo}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td>
                                            <b>Email:</b>
                                        </Td>
                                        <Td>{client?.email}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td>
                                            <b>Number of Employees:</b>
                                        </Td>
                                        <Td>{client?.noOfEmp}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td>
                                            <b>Established:</b>
                                        </Td>
                                        <Td>{client?.estd}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td>
                                            <b>Details:</b>
                                        </Td>
                                        <Td>{client?.details}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td>
                                            <b>Address:</b>
                                        </Td>
                                        <Td>
                                            {client?.address}, {client?.city}, {client?.state},{" "}
                                            {client?.country} - {client?.pincode}
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td>
                                            <b>Status:</b>
                                        </Td>
                                        <Td>
                                            {" "}
                                            {client?.status == "Active" && (
                                                <Badge variant="subtle" colorScheme="green">
                                                    Active
                                                </Badge>
                                            )}
                                            {client?.status == "Inactive" && (
                                                <Badge variant="subtle" colorScheme="orange">
                                                    Inactive
                                                </Badge>
                                            )}
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td>
                                            <b>Primary Contacts:</b>
                                        </Td>
                                        <Td>
                                            {client?.contacts?.map((contact, index) => (
                                                <Tag
                                                    key={index}
                                                    size="lg"
                                                    borderRadius="full"
                                                    mr={1}
                                                    mb={1}
                                                >
                                                    <TagLabel fontSize={"sm"}>
                                                        {contact?.name} ({contact?.role}) -{" "}
                                                        {contact?.contactNo} | {contact?.email}
                                                    </TagLabel>
                                                </Tag>
                                            ))}
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td>
                                            <b>Added On:</b>
                                        </Td>
                                        <Td>{formatDateTime(client?.createdAt)}</Td>
                                    </Tr>
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </div>
    );
};

export default ClientDetails;
