export const printSkills = (skills, count) => {
    // Extract skill names with relevant experience from the skills array
    const skillsString = skills
        ?.map((skill) => `${skill?.skillName}(${skill?.relevantExp})`)
        ?.join(", ");

    // Check if the total length exceeds asked count characters
    if (skillsString?.length > count) {
        // If it does, truncate it to asked count characters and append "..."
        return `${skillsString?.substring(0, count)}...`;
    }

    // Otherwise, return the full skills string
    return skillsString;
};

export const printSkillsDetailed = (skills) => {
    return skills?.map((skill) => `${skill?.skillName} - ${skill?.relevantExp} years`).join(", ");
};
