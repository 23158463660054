import React, { useState } from "react";
import { useClient } from "../../../context/ClientContext";
import { Avatar, Box, Flex, Heading, Text } from "@chakra-ui/react";
import { FaExternalLinkAlt } from "react-icons/fa";
import ClientDetails from "../ClientTab/ClientDetails";
import { useEnv } from "../../../context/EnvContext";

const ClientStatus = ({ loading, dashClientData, initialPath }) => {
    const { backendUrl } = useEnv();
    const { clientData, setClientData, fetchClientById } = useClient();

    const [isClientDetailsModalOpen, setIsClientDetailsModalOpen] = useState(false);

    // Handle Client Details modal Preview
    const handleClientPreview = (client) => {
        // fetch client then show
        fetchClientById(client.clientId);
        setIsClientDetailsModalOpen(true);
    };

    const handleClientViewCloseModal = () => {
        setIsClientDetailsModalOpen(false);
        setClientData(null);
    };

    return (
        <>
            <Box
                h={"200px"}
                w={"200px"}
                bg={"#005f53"}
                borderRadius={"md"}
                p={4}
                position={"relative"}
            >
                <Heading as="h2" size="xl" textAlign={"left"} pl={1}>
                    {dashClientData?.noOfActiveClients || 0}
                </Heading>
                <Heading as="h5" size="xs" textAlign={"left"} pl={1} color={"#ffffff9c"}>
                    Active Clients
                </Heading>

                <Box position={"absolute"} bottom={0} left={0} p={5} w={"100%"}>
                    <Text
                        fontSize={"xs"}
                        fontWeight={"bold"}
                        color={"white"}
                        textAlign={"left"}
                        pl={1}
                        mt={3}
                    >
                        Top 3 Clients
                    </Text>

                    {dashClientData?.topClients?.map((cli, index) => (
                        <Flex
                            key={index}
                            fontSize={"xs"}
                            color={"#64ccff"}
                            fontWeight={"500"}
                            pl={1}
                            display={"flex"}
                            alignItems={"center"}
                            gap={2}
                        >
                            <Avatar
                                size="2xs"
                                name={cli.name}
                                src={`${backendUrl}${cli.logo}`}
                                background={"black"}
                                mr={-1}
                            />
                            <Text
                                style={{
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    maxWidth: "150px",
                                }}
                            >
                                {cli.name}
                            </Text>
                            {initialPath == "admin_dashboard" && (
                                <FaExternalLinkAlt
                                    onClick={() => handleClientPreview(cli)}
                                    cursor={"pointer"}
                                />
                            )}
                        </Flex>
                    ))}
                </Box>
            </Box>
            {clientData && isClientDetailsModalOpen && (
                <ClientDetails
                    client={clientData}
                    isOpen={isClientDetailsModalOpen}
                    onClose={handleClientViewCloseModal}
                />
            )}
        </>
    );
};

export default ClientStatus;
