// convert 2024-08-02T13:08:31.417Z to 2nd Aug 2024
export function formatDate(inputDate) {
    // Create a new Date object from the input date string
    const date = new Date(inputDate);

    // Define arrays for short month names and ordinal suffixes
    const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];
    const daySuffixes = ["th", "st", "nd", "rd"];

    // Get the day, month, and year from the Date object
    const day = date.getDate();
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();

    // Determine the correct suffix for the day
    const suffix =
        day % 10 < 4 && day % 10 > 0 && !(day >= 11 && day <= 13)
            ? daySuffixes[day % 10]
            : daySuffixes[0];

    // Format the date string
    return `${day}${suffix} ${month} ${year}`;
}

// convert 2024-08-02T13:08:31.417Z to 2nd Aug 2024, Friday
export function formatDateDay(inputDate) {
    // Create a new Date object from the input date string
    const date = new Date(inputDate);

    // Define arrays for short month names and ordinal suffixes
    const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];
    const daySuffixes = ["th", "st", "nd", "rd"];

    // Get the day, month, and year from the Date object
    const day = date.getDate();
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();

    // Determine the correct suffix for the day
    const suffix =
        day % 10 < 4 && day % 10 > 0 && !(day >= 11 && day <= 13)
            ? daySuffixes[day % 10]
            : daySuffixes[0];

    const daysOfWeek = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
    ];
    const dayOfWeek = daysOfWeek[date.getDay()];

    // Format the date string
    return `${day}${suffix} ${month} ${year}, ${dayOfWeek}`;
}

// convert 2024-08-02T13:08:31.417Z to 02/08/2024-13:08
export function formatDateTime(inputDate) {
    if (!inputDate) return null;
    const date = new Date(inputDate);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${day}/${month}/${year}-${hours}:${minutes}`;
}

// convert 2024-08-02T13:08:31.417Z to 02/08/2024-13:08 Fri
export function formatDateTimeDay(inputDate) {
    if (!inputDate) return null;
    const date = new Date(inputDate);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const dayOfWeek = daysOfWeek[date.getDay()];

    return `${day}/${month}/${year}-${hours}:${minutes} ${dayOfWeek}`;
}

// convert 2024-08-02T13:08:31.417Z to 02/08/24
export function formatToShortDate(inputDate) {
    if (!inputDate) return null;

    const date = new Date(inputDate);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear()).slice(-2);

    return `${day}/${month}/${year}`;
}

// convert 2024-08-02T13:08:31.417Z to 2024-08-02
export function convertISOToDate(isoString) {
    const date = new Date(isoString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
}

// convert "2024-09" to "Sep, 2024"
export function formatMonthYear(input) {
    // Check if the input is valid
    if (!/^\d{4}-\d{2}$/.test(input)) {
        throw new Error('Invalid date format. Expected "YYYY-MM".');
    }

    // Extract the year and month from the input
    const [year, month] = input.split("-").map(Number);

    // Array of month names
    const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];

    // Check if the month is valid
    if (month < 1 || month > 12) {
        throw new Error("Invalid month value. Should be between 01 and 12.");
    }

    // Get the month name
    const monthName = monthNames[month - 1];

    // Return the formatted date
    return `${monthName}, ${year}`;
}

// Example usage
const input = "2024-09";
const formattedDate = formatDate(input);
console.log(formattedDate); // Output: "Sep, 2024"
