import { Box, Text } from "@chakra-ui/react";
import React from "react";
import MultiLineChart from "../../../Utils/GlobalComponents/Charts/MultiLineChart";

const DailyPerformance = ({ perDayApplicationNumbers, isCollapsed, loading }) => {
    function convertToDataSet(statistics) {
        if (!statistics) return [];
        const labels = [
            "Application Started",
            "CV Processed",
            "Interview Processed",
            "Offer Processed",
            "Placement Processed",
            "Billing Processed",
        ];
        const keys = [
            "applicationStarted",
            "cvProcessed",
            "interviewProcessed",
            "offerProcessed",
            "placementProcessed",
            "billingProcessed",
        ];
        const colors = ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF", "#FF9F40"];

        const data = labels.map((label, index) => {
            return {
                label,
                data: statistics.map((stat) => stat[keys[index]]),
                color: colors[index],
            };
        });

        return data;
    }

    return (
        <Box
            h={"200px"}
            bg={"#22242b"}
            borderRadius={"md"}
            p={4}
            position={"relative"}
            flexGrow={2}
        >
            <Text fontSize={"xs"} fontWeight={"bold"} color={"white"} align={"left"} lineHeight={1}>
                Daily Performance
            </Text>
            <Box position={"absolute"} bottom={1} left={0} px={5} pb={1} w={"100%"} h={"150px"}>
                <MultiLineChart
                    height={"150px"}
                    width={isCollapsed ? "750px" : "400px"}
                    dataSet={convertToDataSet(perDayApplicationNumbers)} // Direct function call
                />
            </Box>
        </Box>
    );
};

export default DailyPerformance;
