import React, { useEffect, useState } from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalCloseButton,
    Button,
    FormControl,
    FormLabel,
    Input,
    Select,
    Flex,
    Heading,
} from "@chakra-ui/react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useUser } from "../../../context/UserContext";

const validationSchema = Yup.object({
    name: Yup.string().required("* Name is required"),
    empId: Yup.string().required("* Employee ID is required"),
    email: Yup.string().email("* Invalid email format").required("* Email is required"),
    userType: Yup.string().required("* User type is required"),
});

const EmployeeForm = ({
    isOpen,
    onClose,
    operation = "Add Employee",
    employee = { name: "", empId: "", email: "", userType: "" },
    status,
}) => {
    const { addUser, updateUser } = useUser();

    const handleOverallSubmit = (updatedUser) => {
        // handle form submission logic here
        //console.log(updatedUser);
        if (operation === "Update Employee") {
            updateUser(updatedUser, employee._id, status, employee.userType);
        } else {
            addUser(updatedUser);
        }
        onClose();
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
            <ModalOverlay />
            <ModalContent overflow={"clip"}>
                <ModalCloseButton top={3} />
                <ModalBody mt={0}>
                    <Flex justify={"center"} mb={2} mt={2}>
                        <Heading as="h3" size="md">
                            {operation}
                        </Heading>
                    </Flex>

                    <Formik
                        initialValues={employee}
                        validationSchema={validationSchema}
                        onSubmit={(values) => {
                            handleOverallSubmit(values);
                        }}
                    >
                        {({ resetForm }) => (
                            <Form>
                                <FormControl id="name">
                                    <FormLabel mb={1} mt={2}>
                                        Name
                                    </FormLabel>
                                    <Field name="name" as={Input} bg={"gray.700"} />
                                    <ErrorMessage
                                        name="name"
                                        component="div"
                                        className="text-red-400 text-xs mt-1"
                                    />
                                </FormControl>

                                <FormControl id="empId">
                                    <FormLabel mb={1} mt={2}>
                                        Employee ID
                                    </FormLabel>
                                    <Field name="empId" as={Input} bg={"gray.700"} />
                                    <ErrorMessage
                                        name="empId"
                                        component="div"
                                        className="text-red-400 text-xs mt-1"
                                    />
                                </FormControl>

                                <FormControl id="email">
                                    <FormLabel mb={1} mt={2}>
                                        Email
                                    </FormLabel>
                                    <Field name="email" as={Input} type="email" bg={"gray.700"} />
                                    <ErrorMessage
                                        name="email"
                                        component="div"
                                        className="text-red-400 text-xs mt-1"
                                    />
                                </FormControl>

                                <FormControl id="userType">
                                    <FormLabel mb={1} mt={2}>
                                        Role
                                    </FormLabel>
                                    <Field name="userType" as={Select} bg={"gray.700"}>
                                        <option value="" disabled>
                                            Select Role
                                        </option>
                                        <option value="Admin">Admin</option>
                                        <option value="HR Manager">HR Manager</option>
                                        <option value="Business Developer">
                                            Business Developer
                                        </option>
                                        <option value="Recruiter">Recruiter</option>
                                    </Field>
                                    <ErrorMessage
                                        name="userType"
                                        component="div"
                                        className="text-red-400 text-xs mt-1"
                                    />
                                </FormControl>

                                <Flex justify={"center"} mb={2} mt={6} w={"100%"} gap={2}>
                                    <Button
                                        w={"100%"}
                                        type="button"
                                        colorScheme="gray"
                                        size="sm"
                                        style={{
                                            borderRadius: "20px",
                                            transition: "background 0.3s ease",
                                        }}
                                        onClick={() => resetForm()}
                                    >
                                        Reset
                                    </Button>
                                    <Button
                                        w={"100%"}
                                        type="submit"
                                        colorScheme="blue"
                                        size="sm"
                                        style={{
                                            borderRadius: "20px",
                                            transition: "background 0.3s ease",
                                        }}
                                    >
                                        {operation}
                                    </Button>
                                </Flex>
                            </Form>
                        )}
                    </Formik>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default EmployeeForm;
