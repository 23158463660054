import React, { useEffect, useState } from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Button,
    Tag,
    TagLabel,
    Box,
    Text,
    Avatar,
    Flex,
    FormControl,
    FormLabel,
    Select,
    Textarea,
} from "@chakra-ui/react";

import { useEnv } from "../../../context/EnvContext";
import getGenderIcon from "../../../Utils/GlobalFunctions/GenderIcon";

const RecordResult = ({ isOpen, onClose, application, recordCvRes, status }) => {
    const { backendUrl } = useEnv();
    const [formData, setFormData] = useState({ result: "Pending", remark: "" });
    const [isDataUpdated, setIsDataUpdated] = useState(false);

    useEffect(() => {
        setFormData({
            result: application?.cvSelectionDetails.result || "Pending",
            remark: application?.cvSelectionDetails.remark || "",
        });
    }, [application]);

    useEffect(() => {
        if (
            !(
                formData.result == application?.cvSelectionDetails.result &&
                (formData.remark == application?.cvSelectionDetails.remark || formData.remark == "")
            )
        ) {
            setIsDataUpdated(true);
        } else {
            setIsDataUpdated(false);
        }
    }, [formData]);

    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleFormReset = () => {
        setFormData({
            result: application?.cvSelectionDetails.result || "Pending",
            remark: application?.cvSelectionDetails.remark || "",
        });
    };
    const handleFormSubmit = async () => {
        // call submit function
        const res = await recordCvRes(
            application?._id,
            formData,
            application?.cvSelectionDetails.result || "Pending"
        );
        ////console.log("res", res);
        if (res?.success) {
            onClose();
        }
    };

    ////console.log("formData", formData);
    return (
        <div>
            <Modal
                isLazy
                isOpen={isOpen}
                onClose={onClose}
                size={"xl"}
                style={{ minHeight: "100vh" }}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        Record CV Response Result for <b>{application?.applicationID}</b>,<br /> CV
                        Process <b>{application?.cvSelectionDetails.processId}</b>
                        <Flex justify={"space-between"} w={"100%"} mt={2} gap={2}>
                            <Box background={"teal"} w={"50%"} px={2} py={1} borderRadius={"md"}>
                                <Text fontSize={"md"}>{application?.jobDescription.jobId}</Text>
                                <Text
                                    fontSize={"sm"}
                                    color={"#c9c9c9"}
                                    style={{
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                        maxWidth: "200px",
                                    }}
                                >
                                    {application?.jobDescription.jobTitle}
                                </Text>
                            </Box>{" "}
                            <Flex
                                align={"center"}
                                background={"teal"}
                                w={"50%"}
                                px={2}
                                py={1}
                                borderRadius={"md"}
                            >
                                <Avatar
                                    src={`${backendUrl}${application?.candidate?.dp}`}
                                    size="sm"
                                    name={application?.candidate?.name}
                                    mr={2}
                                    background={"green"}
                                    color={"white"}
                                />

                                <Box
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                >
                                    <Flex gap={1}>
                                        <Text
                                            fontSize={"sm"}
                                            style={{
                                                textOverflow: "ellipsis",
                                                overflow: "hidden",
                                                maxWidth: "270px",
                                            }}
                                        >
                                            <span
                                                style={{
                                                    color: "#c9c9c9",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                {application?.candidate?.stuId}
                                            </span>
                                            {" - "}
                                            {application?.candidate?.name}
                                        </Text>
                                        {getGenderIcon(application?.candidate?.gender)}
                                    </Flex>
                                    <Text
                                        fontSize={"sm"}
                                        color={"#c9c9c9"}
                                        style={{
                                            textOverflow: "ellipsis",
                                            overflow: "hidden",
                                            maxWidth: "270px",
                                        }}
                                    >
                                        {application?.candidate?.email}
                                    </Text>
                                </Box>
                            </Flex>
                        </Flex>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody style={{ paddingBottom: "20px" }}>
                        {/* <hr /> */}
                        <FormControl id="result" isRequired>
                            <FormLabel>CV Response:</FormLabel>
                            <Select name="result" value={formData.result} onChange={handleChange}>
                                <option value="Pending">Pending</option>
                                <option value="Selected">Selected</option>
                                <option value="Rejected">Rejected</option>
                            </Select>
                        </FormControl>
                        <FormControl id="remark" mt={3}>
                            <FormLabel>Remarks:</FormLabel>
                            <Textarea
                                name="remark"
                                value={formData.remark}
                                onChange={handleChange}
                                placeholder="Write your Remark Here"
                                size="sm"
                                borderRadius={"md"}
                            />
                        </FormControl>
                    </ModalBody>{" "}
                    <ModalFooter>
                        <Button colorScheme="red" mr={3} size="sm" onClick={handleFormReset}>
                            Reset
                        </Button>
                        <Button
                            colorScheme="blue"
                            size="sm"
                            onClick={handleFormSubmit}
                            isDisabled={!isDataUpdated}
                        >
                            Submit
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </div>
    );
};

export default RecordResult;
