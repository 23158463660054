import { Box, Flex, Heading, Skeleton, Text, Tooltip } from "@chakra-ui/react";
import React from "react";

const JDStatus = ({ loading, dashJDData }) => {
    //console.log(dashJDData);
    return (
        <Box h={"200px"} w={"200px"} bg={"#005f53"} borderRadius={"md"} p={4} position={"relative"}>
            {loading ? (
                <Skeleton h="100%" w="100%" borderRadius={"md"} />
            ) : (
                <>
                    <Heading as="h2" size="xl" textAlign={"left"} pl={1}>
                        {dashJDData?.activeJDs || 0}
                    </Heading>
                    <Heading as="h5" size="xs" textAlign={"left"} pl={1} color={"#ffffff9c"}>
                        Active Job Descriptions
                    </Heading>
                    <Text
                        fontSize={"xs"}
                        fontWeight={"bold"}
                        color={"white"}
                        textAlign={"left"}
                        pl={1}
                        mt={1}
                    >
                        {dashJDData?.openPositions || 0} Open Positions
                    </Text>
                    <Box position={"absolute"} bottom={0} left={0} p={5} w={"100%"}>
                        <Flex justify={"space-between"}>
                            <Text
                                fontSize={"sm"}
                                fontWeight={"bold"}
                                color={"#ffffff9c"}
                                textAlign={"left"}
                                mb={1}
                            >
                                {dashJDData?.pendingPositions || 0} Pending
                            </Text>{" "}
                            <Text
                                fontSize={"sm"}
                                fontWeight={"bold"}
                                color={"#ffffff9c"}
                                textAlign={"left"}
                                mb={1}
                            >
                                {!isNaN(
                                    Math.floor(
                                        (dashJDData?.pendingPositions / dashJDData?.openPositions) *
                                            100
                                    )
                                )
                                    ? 100 -
                                      Math.floor(
                                          (dashJDData?.pendingPositions /
                                              dashJDData?.openPositions) *
                                              100
                                      )
                                    : 0}
                                %
                            </Text>
                        </Flex>
                        <Tooltip
                            hasArrow
                            label={`${
                                !isNaN(
                                    Math.floor(
                                        (dashJDData?.pendingPositions / dashJDData?.openPositions) *
                                            100
                                    )
                                )
                                    ? 100 -
                                      Math.floor(
                                          (dashJDData?.pendingPositions /
                                              dashJDData?.openPositions) *
                                              100
                                      )
                                    : 0
                            }% Completed`}
                            placement="bottom"
                        >
                            <Box
                                w={"100%"}
                                h={"8px"}
                                bg={"#ffffff9c"}
                                borderRadius={"xl"}
                                position={"relative"}
                            >
                                <Box
                                    position={"absolute"}
                                    left={0}
                                    w={`${
                                        !isNaN(
                                            Math.floor(
                                                (dashJDData?.pendingPositions /
                                                    dashJDData?.openPositions) *
                                                    100
                                            )
                                        )
                                            ? 100 -
                                              Math.floor(
                                                  (dashJDData?.pendingPositions /
                                                      dashJDData?.openPositions) *
                                                      100
                                              )
                                            : 0
                                    }%`}
                                    h={"8px"}
                                    bg={"white"}
                                    borderRadius={"xl"}
                                ></Box>
                            </Box>
                        </Tooltip>
                    </Box>
                </>
            )}
        </Box>
    );
};

export default JDStatus;
