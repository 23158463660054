import { Box } from "@chakra-ui/react";
import React from "react";
import { Carousel } from "react-responsive-carousel";

export default ({
    showStatus = true,
    showIndicators = true,
    showThumbs = true,
    stopOnHover = true,
    interval = 2000,
    images,
    backendUrl,
}) => (
    <Carousel
        autoPlay
        infiniteLoop
        showStatus={showStatus}
        showIndicators={showIndicators}
        showThumbs={showThumbs}
        stopOnHover={stopOnHover}
        useKeyboardArrows={true}
        transitionTime={500}
        interval={interval}
    >
        {images?.map((image, index) => (
            <Box key={index} p={1} border={"4px solid white"} m={1}>
                <img src={`${backendUrl}${image}`} />
            </Box>
        ))}
    </Carousel>
);
