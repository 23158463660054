import { createContext, useContext, useEffect, useState } from "react";

const EnvContext = createContext();

export const EnvContextProvider = ({ children }) => {
    const [backendUrl, setBackendUrl] = useState(
        process.env.REACT_APP_BACKEND_URL || ""
    );
    const [key, setKey] = useState(process.env.REACT_APP_KEY || "dcsats");

    useEffect(() => {
        setBackendUrl(process.env.REACT_APP_BACKEND_URL);
        setKey(process.env.REACT_APP_KEY);
    }, []);

    return (
        <EnvContext.Provider value={{ backendUrl, key }}>
            {children}
        </EnvContext.Provider>
    );
};

export default EnvContext;

export const useEnv = () => useContext(EnvContext);
