export const labelBaseStyle = {
    position: "absolute",
    fontSize: "13px",
    fontWeight: "600",
    margin: "0",
    top: "-9px",
    left: "12px",
    background: "#2d3748",
    padding: "0 5px",
    lineHeight: "1.2",
    zIndex: 99,
    transition: "opacity 0.3s, visibility 0.3s",
};
