import React from "react";
import NavBar from "../Components/NavBar/NavBar";
import BDBody from "./../Components/Body/BDBody";

const BDPage = () => {
    return (
        <div>
            <NavBar />
            <BDBody />
        </div>
    );
};

export default BDPage;
