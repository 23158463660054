export function percentageToColor(percentage) {
    // Clamp percentage to be within the range [0, 100]
    percentage = Math.max(0, Math.min(percentage, 100));

    // Define colors for specific percentages
    const colors = [
        { percent: 0, color: "#FF0000" }, // Red
        { percent: 25, color: "#FF7A45" }, // Light Orange
        { percent: 50, color: "#F9F600" }, // Yellow
        { percent: 75, color: "#9FC813" }, // Light Green
        { percent: 100, color: "#00FF04" }, // Green
    ];

    // Helper function to convert hex to RGB
    const hexToRgb = (hex) => {
        const r = parseInt(hex.substring(1, 3), 16);
        const g = parseInt(hex.substring(3, 5), 16);
        const b = parseInt(hex.substring(5, 7), 16);
        return { r, g, b };
    };

    // Helper function to convert RGB to hex
    const rgbToHex = (r, g, b) => {
        return `#${[r, g, b].map((x) => x.toString(16).padStart(2, "0").toUpperCase()).join("")}`;
    };

    // Determine the lower and upper bounds for interpolation
    let lowerColor = colors[0];
    let upperColor = colors[colors.length - 1];

    for (let i = 0; i < colors.length - 1; i++) {
        if (percentage >= colors[i].percent && percentage <= colors[i + 1].percent) {
            lowerColor = colors[i];
            upperColor = colors[i + 1];
            break;
        }
    }

    if (percentage === upperColor.percent) {
        return upperColor.color;
    }

    // Interpolate between the lower and upper bound colors
    const lowerRgb = hexToRgb(lowerColor.color);
    const upperRgb = hexToRgb(upperColor.color);

    const ratio = (percentage - lowerColor.percent) / (upperColor.percent - lowerColor.percent);

    const r = Math.round(lowerRgb.r * (1 - ratio) + upperRgb.r * ratio);
    const g = Math.round(lowerRgb.g * (1 - ratio) + upperRgb.g * ratio);
    const b = Math.round(lowerRgb.b * (1 - ratio) + upperRgb.b * ratio);

    return rgbToHex(r, g, b);
}
