import axios from "axios";
import React, { createContext, useState, useContext, useEffect } from "react";
import { useEnv } from "./EnvContext";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import useGlobalToast from "../Utils/GlobalFunctions/toast";

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
    const toast = useGlobalToast();
    const navigate = useNavigate();

    const { backendUrl, key } = useEnv();
    const [error, setError] = useState(null);
    const [user, setUser] = useState(null);
    const [userData, setUserData] = useState(null);
    const [isLogingIn, setIsLogingIn] = useState(false);
    const [loading, setLoading] = useState(true);

    const updateUserData = (isUpdateLocalData = false, updatedUserData) => {
        const storedUserData = localStorage.getItem("userData");
        if (storedUserData) {
            try {
                // Decrypt user data
                const bytes = CryptoJS.AES.decrypt(storedUserData, key);
                const decryptedUserData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

                if (isUpdateLocalData) {
                    // Update local data
                    decryptedUserData.dp = updatedUserData?.dp;
                    decryptedUserData.empId = updatedUserData?.empId;
                    decryptedUserData.name = updatedUserData?.name;
                    decryptedUserData.userType = updatedUserData?.userType;

                    // Encrypt user data
                    const encryptedData = CryptoJS.AES.encrypt(
                        JSON.stringify(decryptedUserData),
                        key
                    ).toString();

                    // Store encrypted data in localStorage
                    localStorage.setItem("userData", encryptedData);
                }

                setUserData(decryptedUserData);
            } catch (err) {
                console.error("User Data Decrypting Error: ", err);
                toast.error("Failed to load user data!", "");
            }
        }
        setLoading(false);
    };

    useEffect(() => {
        updateUserData();
    }, []);

    // Login Function
    const login = async (email, password, rememberMe) => {
        const loggedInUser = { email, password, rememberMe };

        try {
            // Set login loding true
            setIsLogingIn(true);

            // Handle Remember me
            if (rememberMe) {
                // Encrypt Logged user email and password
                const encryptedLoginData = CryptoJS.AES.encrypt(
                    JSON.stringify({ email, password }),
                    key
                ).toString();

                // Store encrypted data in localStorage
                localStorage.setItem("loginCredential", encryptedLoginData);
            }
            setUser(loggedInUser);

            // Handle Login api
            const response = await axios.post(`${backendUrl}/user/login`, {
                email,
                password,
            });

            toast.success("Login successful!", "");
            console.log("response ", response.data);
            // console.log("key ", key);
            const data = response.data.data;

            // Encrypt user data
            const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), key).toString();

            // Store encrypted data in localStorage
            localStorage.setItem("userData", encryptedData);
            setUserData(data);

            const role = data?.userType;

            switch (role) {
                case "Admin":
                    navigate("/admin_dashboard/Dashboard/dashboard");
                    break;
                case "HR Manager":
                    navigate("/hr_dashboard");
                    break;
                case "Business Developer":
                    navigate("/bd_dashboard/JobDescriptions/Active");
                    break;
                case "Recruiter":
                    navigate("/recruiter_dashboard/JobDescriptions/Active");
                    break;
                default:
                    toast.error("Role not recognized!", "");
                    break;
            }
        } catch (err) {
            console.log("Error", err);
            let error = err.response
                ? err.response?.data?.message
                : err.message
                ? err.message
                : "Something Went Wrong";
            setError(error);
            toast.error("Error", error);
        } finally {
            // set login Loding false
            setIsLogingIn(false);
        }
    };

    // Logout Function
    const logout = () => {
        setUser(null);
        setUserData(null);
        localStorage.removeItem("userData");
        navigate("/");
        toast.success("Logout Successful!", "");
        console.log("User logged out");
    };

    return (
        <AuthContext.Provider
            value={{
                user,
                login,
                isLogingIn,
                logout,
                setUserData,
                userData,
                loading,
                updateUserData,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};
