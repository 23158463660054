import { IoMdMale, IoMdFemale } from "react-icons/io";
import { IoMaleFemale } from "react-icons/io5";

const getGenderIcon = (gender) => {
    if (gender === "Male") {
        return <IoMdMale color="#2D9AFE" />;
    } else if (gender === "Female") {
        return <IoMdFemale color="#F86CFF" />;
    } else if (gender === "Other") {
        return <IoMaleFemale color="#ffd600" />;
    } else {
        return;
    }
};

export default getGenderIcon;
