import React, { useState, useEffect,useRef } from "react";

import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Badge,
    Text,
    Box,
    Tooltip,
    Flex,
    Avatar,
    Skeleton,
} from "@chakra-ui/react";
import { Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";

import { TiArrowSortedUp, TiArrowSortedDown } from "react-icons/ti";
import { GrMore } from "react-icons/gr";
import { FaSort } from "react-icons/fa";
import { IoMdMale, IoMdFemale } from "react-icons/io";
import { IoMaleFemale } from "react-icons/io5";

import { formatDateTime } from "../../../Utils/GlobalFunctions/FormatDate";
import convertToLPA from "../../../Utils/GlobalFunctions/FormatSalary";
import getGenderIcon from "./../../../Utils/GlobalFunctions/GenderIcon";

import { useEnv } from "../../../context/EnvContext";
import { useCandidate } from "../../../context/CandidateContext";
import { useJD } from "../../../context/JDContext";


import CandidateDetails from "../Candidate/CandidateDetails";
import JdDetails from "../JobDescription/JdDetails";
import ApplicationDetails from "../Application/ApplicationDetails";
import { debounce } from "../../../Utils/GlobalFunctions/Debounce";
import { useBill } from "../../../context/BillingContext";

const BillingBody = ({ data, fetchBills, hasMore, pageNo, status,loading }) => {
    // console.log("data", data);
    // console.log("hasMore", hasMore);
    // console.log("pageNo", pageNo);
    // console.log("status", status);

    const { backendUrl } = useEnv();
    const { candidateData, setCandidateData, fetchSpecificCandidate, loadingCandidate } =
        useCandidate();
    const { jobDescriptionData, setJobDescriptionData, fetchJDById, loadingJD } = useJD();
    const [selectedApplication, setSelectedApplication] = useState(null);
    const [selectedBill, setSelectedBill] = useState(null);
    const [isApplicationDetailsModalOpen, setIsApplicationDetailsModalOpen] = useState(false);
    const [isCandidateDetailsModalOpen, setIsCandidateDetailsModalOpen] = useState(false);
    const [isJDDetailsModalOpen, setIsJDDetailsModalOpen] = useState(false);

    const containerRef = useRef(null); // Reference for the scrollable div
  const endRef = useRef(null);
  const pageNoRef = useRef(pageNo);
  const [scrollInitiated, setScrollInitiated] = useState(false); // Track if user has scrolled
  const [isInitialLoad, setIsInitialLoad] = useState(true); // Track initial load


    const [sortedData, setSortedData] = useState(data);
    const [sortConfig, setSortConfig] = useState({
        key: null,
        direction: null,
    });

   // Update pageNoRef whenever pageNo changes
   useEffect(() => {
    pageNoRef.current = pageNo;
  }, [pageNo]);

  const fetchMoreData = async () => {
    if (loading || !hasMore || !scrollInitiated) return;

    await fetchBills(status, pageNoRef.current, 30, true);
    setIsInitialLoad(false); // Update after initial load
};

useEffect(() => {
  const observer = new IntersectionObserver(
    ([entry]) => {
      if (entry.isIntersecting && scrollInitiated) {
        console.log("Fetching more data...");
        fetchMoreData();
      }
    },
    {
      root: containerRef.current,
      rootMargin: "0px 0px 100px 0px", // Adjusted to trigger earlier for smoother loading
      threshold: 0.5, // Increased to trigger loading more reliably
    }
  );

   // Only scroll to the bottom initially or after data is fetched
   if (isInitialLoad && containerRef.current) {
    containerRef.current.scrollIntoView({ behavior: "smooth" });
  }
  if (endRef.current) {
    // endRef.current.scrollIntoView({ behavior: "smooth" });
    observer.observe(endRef.current);
  }

  return () => {
    if (endRef.current) {
      observer.unobserve(endRef.current);
    }
  };
}, [hasMore, loading, scrollInitiated,isInitialLoad]);

  // Track user scrolling within the container
  const handleScroll = debounce(() => {
    if (containerRef?.current?.scrollTop > 0) {
      setScrollInitiated(true);
    }
  }, 300); // Adjust the delay as needed
    // Sort the data
    useEffect(() => {
        let sortedArray = [...data];
        if (sortConfig.key) {
            sortedArray.sort((a, b) => {
                let aValue = a[sortConfig.key];
                let bValue = b[sortConfig.key];

                // Handle nested keys for application jobDescription
                if (sortConfig.key === "application?.jobDescription?.jobTitle") {
                    aValue = a.application?.jobDescription?.jobTitle;
                    bValue = b.application?.jobDescription?.jobTitle;
                }

                // Handle nested keys for application candidate
                if (sortConfig.key === "application?.candidate?.name") {
                    aValue = a.application?.candidate?.name;
                    bValue = b.application?.candidate?.name;
                }

                // Handle nested keys for application
                if (sortConfig.key === "application?.applicationID") {
                    aValue = a.application?.applicationID;
                    bValue = b.application?.applicationID;
                }

                if (aValue < bValue) {
                    return sortConfig.direction === "ascending" ? -1 : 1;
                }
                if (aValue > bValue) {
                    return sortConfig.direction === "ascending" ? 1 : -1;
                }
                return 0;
            });
        }
        setSortedData(sortedArray);
    }, [sortConfig, data]);

    const requestSort = (key) => {
        let direction = "ascending";
        if (sortConfig.key === key && sortConfig.direction === "ascending") {
            direction = "descending";
        } else if (sortConfig.key === key && sortConfig.direction === "descending") {
            setSortConfig({ key: null, direction: null });
            return;
        }
        setSortConfig({ key, direction });
    };

    const getSortIcon = (key) => {
        if (sortConfig.key === key) {
            if (sortConfig.direction === "ascending") {
                return <TiArrowSortedUp cursor={"pointer"} />;
            } else if (sortConfig.direction === "descending") {
                return <TiArrowSortedDown cursor={"pointer"} />;
            }
        }
        return <FaSort cursor={"pointer"} />;
    };

    // Handle Candidate Details modal Preview
    const handleCandidatePreview = (candidate) => {
        // fetch the candidate
        fetchSpecificCandidate(candidate?._id);
        setIsCandidateDetailsModalOpen(true);
    };

    const handleCandidateViewCloseModal = () => {
        setIsCandidateDetailsModalOpen(false);
        setCandidateData(null);
    };

    // Handle JD Details modal Preview
    const handleJDPreview = (jd) => {
        // fetch the jd
        fetchJDById(jd._id);
        setIsJDDetailsModalOpen(true);
    };

    const handleJDViewCloseModal = () => {
        setIsJDDetailsModalOpen(false);
        setJobDescriptionData(null);
    };

    // Handle Application Details modal Preview
    const handleApplicationPreview = () => {
        setIsApplicationDetailsModalOpen(true);
    };
    const handleApplicationViewCloseModal = () => {
        setIsApplicationDetailsModalOpen(false);
        setSelectedApplication(null);
    };
    return (
        <div
        ref={containerRef}
        style={{
          height: "75vh",
          overflow: "auto",
          position: "relative",
        }}
        onScroll={handleScroll} // Trigger scroll detection
      >
                <TableContainer
                           style={{ position: "relative" }}
                    overflowY={sortedData.length < 5 ? "auto" : "clip"}
                    overflowX={sortedData.length < 5 ? "clip" : "clip"}
                >
                    <Table size="sm">
                        <Thead
                            style={{
                                position: "sticky",
                                top: 0,
                                backgroundColor: "#05080D",
                                zIndex: 1,
                            }}
                        >
                            <Tr>
                                <Th style={{ color: "white" }}>Sl</Th>
                                <Th
                                    style={{ color: "white" }}
                                    onClick={() => requestSort("billingId")}
                                >
                                    <Flex
                                        alignItems={"center"}
                                        width={"100%"}
                                        justify={"space-between"}
                                    >
                                        <Tooltip
                                            hasArrow
                                            label={
                                                <>
                                                    <Text textAlign={"center"}>
                                                        Billing Id, Application Id
                                                    </Text>
                                                    <Text
                                                        fontSize={"xs"}
                                                        color={"gray"}
                                                        textAlign={"center"}
                                                    >
                                                        Click to view the Application Details
                                                    </Text>
                                                </>
                                            }
                                            bg="gray.300"
                                            color="black"
                                            placement="top"
                                        >
                                            Ids
                                        </Tooltip>
                                        {getSortIcon("billingId")}
                                    </Flex>
                                </Th>

                                <Th
                                    style={{
                                        color: "white",
                                    }}
                                    onClick={() =>
                                        requestSort("application?.jobDescription?.jobTitle")
                                    }
                                >
                                    {" "}
                                    <Flex
                                        alignItems={"center"}
                                        width={"100%"}
                                        justify={"space-between"}
                                    >
                                        <Tooltip
                                            hasArrow
                                            label={
                                                <Box
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                    }}
                                                >
                                                    <Text fontSize={"sm"} textAlign={"center"}>
                                                        Job Id, Job Title
                                                    </Text>
                                                    <Text
                                                        fontSize={"xs"}
                                                        color={"gray"}
                                                        textAlign={"center"}
                                                    >
                                                        Click on job Id to view the JD details
                                                    </Text>
                                                </Box>
                                            }
                                            bg="gray.300"
                                            color="black"
                                            placement="top"
                                        >
                                            Job Description
                                        </Tooltip>
                                        {getSortIcon("application?.jobDescription?.jobTitle")}
                                    </Flex>
                                </Th>
                                <Th
                                    style={{
                                        color: "white",
                                    }}
                                    onClick={() => requestSort("application?.candidate?.name")}
                                >
                                    {" "}
                                    <Flex
                                        alignItems={"center"}
                                        width={"100%"}
                                        justify={"space-between"}
                                    >
                                        <Tooltip
                                            hasArrow
                                            label={
                                                <>
                                                    <Flex align={"center"}>
                                                        <Avatar
                                                            src=""
                                                            size="sm"
                                                            name="Display Picture"
                                                            mr={2}
                                                            background={"green"}
                                                            color={"white"}
                                                        />
                                                        <Box
                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                            }}
                                                        >
                                                            <Text
                                                                fontSize={"sm"}
                                                                style={{
                                                                    textOverflow: "ellipsis",
                                                                    overflow: "hidden",
                                                                }}
                                                            >
                                                                <b>Candidate Id</b>
                                                                {" - "}
                                                                Name, gender
                                                            </Text>
                                                            <Text
                                                                fontSize={"sm"}
                                                                color={"#2b2a2a"}
                                                                style={{
                                                                    textOverflow: "ellipsis",
                                                                    overflow: "hidden",
                                                                    maxWidth: "180px",
                                                                }}
                                                            >
                                                                Candidate Email
                                                            </Text>
                                                        </Box>
                                                    </Flex>
                                                    <Flex
                                                        direction={"column"}
                                                        fontSize={"xs"}
                                                        color={"gray"}
                                                        mt={2}
                                                    >
                                                        <Text color={"black"}>
                                                            Gender Details:{" "}
                                                        </Text>
                                                        <Flex
                                                            align={"center"}
                                                            gap={1}
                                                            background={"black"}
                                                            p={1}
                                                            px={2}
                                                            color={"white"}
                                                        >
                                                            <IoMdMale color="#2D9AFE" />
                                                            <Text>Male, </Text>
                                                            <IoMdFemale color="#F86CFF" />
                                                            <Text>Female, </Text>
                                                            <IoMaleFemale color="#ffd600" />
                                                            <Text>Other</Text>
                                                        </Flex>
                                                    </Flex>
                                                    <Text
                                                        fontSize={"xs"}
                                                        color={"gray"}
                                                        textAlign={"center"}
                                                    >
                                                        Click on the DP to view the candidate
                                                        details <br />
                                                        Click on mail id to mail the candidate?.
                                                    </Text>
                                                </>
                                            }
                                            bg="gray.300"
                                            color="black"
                                            placement="top"
                                        >
                                            Candidate
                                        </Tooltip>
                                        {getSortIcon("application?.candidate?.name")}
                                    </Flex>
                                </Th>

                                <Th style={{ color: "white" }}>
                                    <Tooltip
                                        hasArrow
                                        label="CTC offered to candidate"
                                        bg="gray.300"
                                        color="black"
                                        placement="top"
                                    >
                                        ctc
                                    </Tooltip>
                                </Th>
                                <Th style={{ color: "white" }}>
                                    <Tooltip
                                        hasArrow
                                        label="Percentage Share for this Application"
                                        bg="gray.300"
                                        color="black"
                                        placement="top"
                                    >
                                        ps
                                    </Tooltip>
                                </Th>
                                <Th style={{ color: "white" }}>
                                    <Tooltip
                                        hasArrow
                                        label="Total Billing Amount for this Application"
                                        bg="gray.300"
                                        color="black"
                                        placement="top"
                                    >
                                        Amount
                                    </Tooltip>
                                </Th>

                                <Th
                                    style={{ color: "white" }}
                                    onClick={() => requestSort("billingDate")}
                                >
                                    <Flex
                                        alignItems={"center"}
                                        width={"100%"}
                                        justify={"space-between"}
                                    >
                                        <Tooltip
                                            hasArrow
                                            label="Expected Billing Date after MBP"
                                            bg="gray.300"
                                            color="black"
                                            placement="top"
                                        >
                                            Billing Date
                                        </Tooltip>{" "}
                                        {getSortIcon("billingDate")}
                                    </Flex>
                                </Th>
                                <Th style={{ color: "white" }}>
                                    <Flex
                                        alignItems={"center"}
                                        width={"100%"}
                                        justify={"center"}
                                        w={"100%"}
                                    >
                                        <Tooltip
                                            hasArrow
                                            label={
                                                <>
                                                    <Text textAlign={"center"}>
                                                        Validity Status of the Bill
                                                    </Text>
                                                    <Text
                                                        fontSize={"xs"}
                                                        color={"gray"}
                                                        textAlign={"center"}
                                                    >
                                                        Validity Status updated on
                                                    </Text>
                                                </>
                                            }
                                            bg="gray.300"
                                            color="black"
                                            placement="top"
                                        >
                                            Status
                                        </Tooltip>
                                    </Flex>
                                </Th>
                                <Th style={{ color: "white" }}> </Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {sortedData &&
                                sortedData.map((bill, index) => (
                                    <Tr key={index}>
                                        <Td>{index + 1}</Td>
                                        <Td style={{ cursor: "pointer" }}>
                                            <Tooltip
                                                hasArrow
                                                label={
                                                    <Text fontSize={"xs"} color={"gray"}>
                                                        Click to view Application in Details
                                                    </Text>
                                                }
                                                bg="gray.300"
                                                color="black"
                                                placement="top"
                                            >
                                                <Flex
                                                    direction={"column"}
                                                    onClick={() => {
                                                        setSelectedBill(bill);
                                                        setSelectedApplication(bill?.application);
                                                        handleApplicationPreview();
                                                    }}
                                                >
                                                    <Text>{bill?.billingId}</Text>
                                                    <Text fontSize={"sm"} color={"#a3a3a3"}>
                                                        {bill?.application?.applicationID}
                                                    </Text>
                                                </Flex>
                                            </Tooltip>
                                        </Td>
                                        <Td
                                            style={{
                                                maxWidth: "230px",
                                                overflow: "hidden",
                                            }}
                                        >
                                            <Box
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <Text fontSize={"sm"}>
                                                    <Tooltip
                                                        hasArrow
                                                        label={
                                                            <Text fontSize={"xs"} color={"gray"}>
                                                                Click to view{" "}
                                                                <b>
                                                                    {
                                                                        bill?.application
                                                                            .jobDescription?.jobId
                                                                    }
                                                                </b>{" "}
                                                                in Details
                                                            </Text>
                                                        }
                                                        bg="gray.300"
                                                        color="black"
                                                        placement="top"
                                                    >
                                                        <Flex
                                                            style={{ cursor: "pointer" }}
                                                            cursor={
                                                                loadingJD ? "progress" : "progress"
                                                            }
                                                            onClick={() => {
                                                                if (!loadingJD)
                                                                    handleJDPreview(
                                                                        bill?.application
                                                                            .jobDescription
                                                                    );
                                                            }}
                                                        >
                                                            {bill?.application?.jobDescription?.jobId}
                                                        </Flex>
                                                    </Tooltip>
                                                </Text>

                                                <Text
                                                    fontSize={"sm"}
                                                    color={"#a3a3a3"}
                                                    style={{
                                                        textOverflow: "ellipsis",
                                                        overflow: "hidden",
                                                        maxWidth: "180px",
                                                    }}
                                                >
                                                    {bill?.application?.jobDescription?.jobTitle}
                                                </Text>
                                            </Box>
                                        </Td>
                                        <Td
                                            style={{
                                                maxWidth: "250px",
                                                overflow: "hidden",
                                            }}
                                        >
                                            <Flex>
                                                <Tooltip
                                                    hasArrow
                                                    label={
                                                        <Text fontSize={"xs"} color={"gray"}>
                                                            Click to view Candidate{" "}
                                                            <b>
                                                                {bill?.application?.candidate?.stuId}
                                                            </b>{" "}
                                                            in Details
                                                        </Text>
                                                    }
                                                    bg="gray.300"
                                                    color="black"
                                                    placement="top"
                                                >
                                                    <Avatar
                                                        src={`${backendUrl}${bill?.application?.candidate?.dp}`}
                                                        size="sm"
                                                        name={bill?.application?.candidate?.name}
                                                        mr={2}
                                                        background={"green"}
                                                        color={"white"}
                                                        cursor={
                                                            loadingCandidate
                                                                ? "progress"
                                                                : "pointer"
                                                        }
                                                        onClick={() => {
                                                            if (!loadingCandidate)
                                                                handleCandidatePreview(
                                                                    bill?.application?.candidate
                                                                );
                                                        }}
                                                    />
                                                </Tooltip>

                                                <Box
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                    }}
                                                >
                                                    <Flex gap={1}>
                                                        <Text
                                                            fontSize={"sm"}
                                                            style={{
                                                                textOverflow: "ellipsis",
                                                                overflow: "hidden",
                                                                maxWidth: "150px",
                                                            }}
                                                        >
                                                            <span
                                                                style={{
                                                                    color: "#a3a3a3",
                                                                    fontWeight: "bold",
                                                                }}
                                                            >
                                                                {bill?.application?.candidate?.stuId}
                                                            </span>
                                                            {" - "}
                                                            {bill?.application?.candidate?.name}
                                                        </Text>
                                                        {getGenderIcon(
                                                            bill?.application?.candidate?.gender
                                                        )}
                                                    </Flex>
                                                    <Text
                                                        fontSize={"sm"}
                                                        color={"#a3a3a3"}
                                                        style={{
                                                            textOverflow: "ellipsis",
                                                            overflow: "hidden",
                                                            maxWidth: "160px",
                                                        }}
                                                    >
                                                        <Tooltip
                                                            hasArrow
                                                            label={
                                                                <Text
                                                                    fontSize={"xs"}
                                                                    color={"gray"}
                                                                >
                                                                    Click to mail the Candidate
                                                                </Text>
                                                            }
                                                            bg="gray.300"
                                                            color="black"
                                                            placement="bottom"
                                                        >
                                                            <a
                                                                target="_blank"
                                                                href={`mailto:${bill?.application?.candidate?.email}`}
                                                            >
                                                                {bill?.application?.candidate?.email}
                                                            </a>
                                                        </Tooltip>
                                                    </Text>
                                                </Box>
                                            </Flex>
                                        </Td>
                                        <Td>{convertToLPA(bill?.ctc)}</Td>
                                        <Td>{bill?.pshare} %</Td>
                                        <Td>₹ {bill?.amount.toFixed(2)}</Td>
                                        <Td>{formatDateTime(bill?.billingDate)}</Td>
                                        <Td>
                                            <Tooltip
                                                hasArrow
                                                label={
                                                    bill?.remarks && (
                                                        <>
                                                            Remark:
                                                            <Box whiteSpace="pre-line">
                                                                {bill?.remarks}
                                                            </Box>
                                                        </>
                                                    )
                                                }
                                                bg="gray.300"
                                                color="black"
                                                placement="top"
                                            >
                                                <Flex
                                                    align={"center"}
                                                    justify={"center"}
                                                    w={"100%"}
                                                    direction={"column"}
                                                >
                                                    {bill?.billValidattion == "Pending" && (
                                                        <Badge variant="subtle" colorScheme="blue">
                                                            Pending
                                                        </Badge>
                                                    )}
                                                    {bill?.billValidattion == "Validated" && (
                                                        <Badge variant="subtle" colorScheme="green">
                                                            Validated
                                                        </Badge>
                                                    )}
                                                    {bill?.billValidattion == "Rejected" && (
                                                        <Badge variant="subtle" colorScheme="red">
                                                            Rejected
                                                        </Badge>
                                                    )}
                                                    <Text
                                                        fontSize={"sm"}
                                                        color={"#a3a3a3"}
                                                        style={{
                                                            textOverflow: "ellipsis",
                                                            overflow: "hidden",
                                                            maxWidth: "200px",
                                                        }}
                                                    >
                                                        {formatDateTime(
                                                            bill?.validityStatusUpdatedon
                                                        )}
                                                    </Text>
                                                </Flex>
                                            </Tooltip>
                                        </Td>
                                        <Td>
                                            <Menu>
                                                <MenuButton>
                                                    <GrMore />
                                                </MenuButton>
                                                <MenuList minW={"50px"}>
                                                    <MenuItem>
                                                        {/* <CandidateDetails
                                                        candidate={candidate}
                                                    > */}
                                                        View Application
                                                        {/* </CandidateDetails> */}
                                                    </MenuItem>
                                                </MenuList>
                                            </Menu>
                                        </Td>
                                    </Tr>
                                ))}
                        </Tbody>
                    </Table>
                </TableContainer>

                {(loading ||
        hasMore) && (
          <>
            <Skeleton height="30px" m={2} />
            <Skeleton height="30px" m={2} />
            <Skeleton height="30px" m={2} />
          </>
        )}
      {(!hasMore && !loading) && (
        <p style={{ textAlign: "center", marginTop: "5px" }}>
          <b>End of the List</b>
        </p>
      )}

            {candidateData && isCandidateDetailsModalOpen && (
                <CandidateDetails
                    candidate={candidateData}
                    isOpen={isCandidateDetailsModalOpen}
                    onClose={handleCandidateViewCloseModal}
                />
            )}
            {jobDescriptionData && isJDDetailsModalOpen && (
                <JdDetails
                    jd={jobDescriptionData}
                    isOpen={isJDDetailsModalOpen}
                    onClose={handleJDViewCloseModal}
                />
            )}

            {selectedApplication && isApplicationDetailsModalOpen && (
                <ApplicationDetails
                    application={selectedApplication}
                    isOpen={isApplicationDetailsModalOpen}
                    onClose={handleApplicationViewCloseModal}
                    tab={4}
                    bill={selectedBill}
                />
            )}
      <div ref={endRef} style={{ height: "1px" }}></div>

        </div>
    );
};

export default BillingBody;
