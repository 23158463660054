import React from "react";
import NavBar from "../Components/NavBar/NavBar";
import AdminBody from "../Components/Body/AdminBody";

const AdminPage = () => {
    return (
        <div>
            <NavBar />
            <AdminBody />
        </div>
    );
};

export default AdminPage;
