import axios from "axios";
import React, { createContext, useState, useContext, useMemo } from "react";
import { useEnv } from "./EnvContext";
import { useAuth } from "./AuthContext";
import useGlobalToast from "../Utils/GlobalFunctions/toast";
import { getStatusCtoS, getStatusStoC } from "../Utils/GlobalFunctions/GetStatus";

const ClientContext = createContext();

export const ClientContextProvider = ({ children }) => {
    const toast = useGlobalToast();
    const { backendUrl } = useEnv();
    const { userData } = useAuth();

    const [clientData, setClientData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [searchedClients, setSearchedClients] = useState([]);

    // handle Clients
    const [allClients, setAllClients] = useState({
        all: [],
        active: [],
        inActive: [],
    });
    const [hasMoreClients, setHasMoreClients] = useState({
        all: true,
        inActive: true,
        active: true,
    });
    const [clientsPageNo, setClientsPageNo] = useState({
        all: 1,
        inActive: 1,
        active: 1,
    });

    const memoizedClients = useMemo(() => allClients, [allClients]);

    // Fetch all clients with infinite scrolling
    const fetchAllClient = async (status, page = 1, limit = 30, updateData = false) => {
        // console.log("fetch clients");
        setLoading(true);
        try {
            if (!updateData && memoizedClients[status].length > 0) {
                setLoading(false);
                return;
            }

            let requestStatus = getStatusStoC(status);

            const token = userData?.token;
            const response = await axios.get(`${backendUrl}/client/all`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    status: requestStatus,
                    page,
                    limit,
                },
            });

            const fetchedClients = response.data;
            // console.log("fetchedClients", fetchedClients);

            if (updateData) {
                setAllClients((prevState) => ({
                    ...prevState,
                    [status]: [...prevState[status], ...fetchedClients],
                }));

                setClientsPageNo((prevState) => ({
                    ...prevState,
                    [status]: prevState[status] + 1,
                }));

                if (fetchedClients?.length < limit) {
                    setHasMoreClients((prevState) => ({
                        ...prevState,
                        [status]: false,
                    }));
                }
            } else {
                setAllClients((prevState) => ({
                    ...prevState,
                    [status]: fetchedClients,
                }));

                setClientsPageNo((prevState) => ({
                    ...prevState,
                    [status]: 2,
                }));

                setHasMoreClients((prevState) => ({
                    ...prevState,
                    [status]: fetchedClients.length === limit,
                }));
            }

            toast.success(`${status} Clients fetched successfully!`);
        } catch (err) {
            console.log("Error", err);
            let error = err.response
                ? err.response?.data?.message
                : err.message
                ? err.message
                : "Something Went Wrong";
            setError(error);
            toast.error("Error", error);
        } finally {
            setLoading(false);
        }
    };

    // Fetch client by ID
    const fetchClientById = async (clientId) => {
        // console.log("fetching candidate ", clientId);
        setLoading(true);
        setError(null);
        try {
            const token = userData?.token;
            const response = await axios.get(`${backendUrl}/client`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    clientId,
                },
            });

            const fetchedClient = response.data;
            // console.log("fetchedClient", fetchedClient);
            setClientData(fetchedClient.data);

            // toast.success("Job description fetched successfully!");
        } catch (err) {
            console.log("Error", err);
            let error = err.response
                ? err.response?.data?.message
                : err.message
                ? err.message
                : "Something Went Wrong";
            setError(error);
            toast.error("Error", error);
        } finally {
            setLoading(false);
        }
    };

    // Add Client
    const addClient = async (formData) => {
        setLoading(true);
        setError(null);

        const client = new FormData();
        Object.keys(formData).forEach((key) => {
            if (key === "contacts") {
                client.append(key, JSON.stringify(formData[key]));
            } else {
                client.append(key, formData[key]);
            }
        });

        try {
            const token = userData?.token;
            const response = await axios.post(`${backendUrl}/client`, client, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const addClientRes = response.data;
            // console.log("addClientRes", addClientRes);
            const addedClient = addClientRes.data;

            const status = getStatusCtoS(addedClient.status);

            setAllClients((prevState) => {
                const updatedStatusArray =
                    prevState[status].length > 0
                        ? [addedClient, ...prevState[status]]
                        : prevState[status];
                const updatedAllArray =
                    prevState.all.length > 0 ? [...prevState.all, addedClient] : prevState.all;

                return {
                    ...prevState,
                    [status]: updatedStatusArray,
                    all: updatedAllArray,
                };
            });

            toast.success("New Client Added Successfully!");
        } catch (err) {
            console.log("Error", err);
            let error = err.response
                ? err.response?.data?.message
                : err.message
                ? err.message
                : "Something Went Wrong";
            setError(error);
            toast.error("Error", error);
        } finally {
            setLoading(false);
        }
    };

    // Update Client
    const updateClient = async (formData, clientId, clientPrevStatus) => {
        setLoading(true);
        setError(null);

        const client = new FormData();
        Object.keys(formData).forEach((key) => {
            if (key === "contacts") {
                client.append(key, JSON.stringify(formData[key]));
            } else {
                client.append(key, formData[key]);
            }
        });

        try {
            const token = userData?.token;
            const response = await axios.put(`${backendUrl}/client?clientId=${clientId}`, client, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const updateClientRes = response.data;
            // console.log("Update client response", response);

            const updatedClientData = updateClientRes.data;
            const currStatus = getStatusCtoS(formData.status);
            const prevStatus = getStatusCtoS(clientPrevStatus);

            // console.log("currStatus", currStatus);
            // console.log("prevStatus", prevStatus);

            setAllClients((prevClients) => {
                const updatedClients = { ...prevClients };

                // Update the 'all' array
                updatedClients.all = prevClients.all.map((client) =>
                    client._id === clientId ? updatedClientData : client
                );

                if (currStatus == prevStatus) {
                    // Update the 'current status' array
                    updatedClients[currStatus] = prevClients[currStatus].map((client) =>
                        client._id === clientId ? updatedClientData : client
                    );
                } else {
                    // Remove from Previous Staus array
                    updatedClients[prevStatus] = prevClients[prevStatus].filter(
                        (client) => client._id !== clientId
                    );

                    // Add to current Status array
                    if (updatedClients[currStatus]?.length > 0)
                        updatedClients[currStatus] = [
                            updatedClientData,
                            ...prevClients[currStatus],
                        ];
                }

                return updatedClients;
            });

            toast.success("Client Updated Successfully!");
        } catch (err) {
            console.log("Error", err);
            let error = err.response
                ? err.response?.data?.message
                : err.message
                ? err.message
                : "Something Went Wrong";
            setError(error);
            toast.error("Error", error);
        } finally {
            setLoading(false);
        }
    };

    // Delete Client
    const deleteClient = async (clientId, clientPreviousStatus) => {
        // Prompt the user for confirmation
        const confirmation = window.prompt('Type "delete client" to confirm the deletion:');

        if (confirmation !== "delete client") {
            toast.error("Deletion cancelled or incorrect phrase entered.");
            return;
        }

        setLoading(true);
        setError(null);

        try {
            const token = userData?.token;
            const response = await axios.delete(`${backendUrl}/client?clientId=${clientId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            // console.log("Delete client response", response.data);
            const status = getStatusCtoS(clientPreviousStatus);

            setAllClients((prevClients) => {
                const updatedClients = { ...prevClients };

                // Update the 'all' array
                updatedClients.all = prevClients.all.filter((client) => client._id !== clientId);

                // Update the 'current status' array
                updatedClients[status] = prevClients[status].filter(
                    (client) => client._id !== clientId
                );
                return updatedClients;
            });

            toast.success("Client Deleted Successfully!");
        } catch (err) {
            console.log("Error", err);
            let error = err.response
                ? err.response?.data?.message
                : err.message
                ? err.message
                : "Something Went Wrong";
            setError(error);
            toast.error("Error", error);
        } finally {
            setLoading(false);
        }
    };

    const fetchSearchedClients = async (query) => {
        setLoading(true);
        setError(null);
        if (!query) {
            setSearchedClients([]);
            return;
        }
        try {
            const token = userData?.token;
            const response = await axios.get(`${backendUrl}/client/search`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    search: query,
                },
            });

            const searchRes = response.data;
            // console.log("searchRes", searchRes);
            setSearchedClients(searchRes.clients);

            toast.success(searchRes.message);
        } catch (err) {
            console.log("Error", err);
            let error = err.response
                ? err.response?.data?.message
                : err.message
                ? err.message
                : "Something Went Wrong";
            setError(error);
            toast.error("Error", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <ClientContext.Provider
            value={{
                clientData,
                setClientData,
                loading,
                error,
                fetchAllClient,
                fetchClientById,
                allClients,
                hasMoreClients,
                clientsPageNo,
                addClient,
                updateClient,
                deleteClient,
                searchedClients,
                fetchSearchedClients,
            }}
        >
            {children}
        </ClientContext.Provider>
    );
};

export const useClient = () => {
    return useContext(ClientContext);
};
