import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Tabs, TabList, TabPanels, Tab, TabPanel, Text } from "@chakra-ui/react";
import { tagColor } from "../../../Utils/GlobalFunctions/TagColor";

import BillingBody from "./BillingBody";
import { useAuth } from "../../../context/AuthContext";
import { useBill } from "../../../context/BillingContext";

const BillingContainer = () => {
    const { loadingBills, allBills, hasMoreBills, BillsPageNo, fetchBills } = useBill();
    const { userData } = useAuth();

    console.log("allBills", allBills);
    const [tabIndex, setTabIndex] = useState(0);
    const { status } = useParams();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const initialPath = pathname.split("/")[1];

    useEffect(() => {
        console.log("userData**", userData);

        switch (status) {
            case "all":
                fetchBills("all", 1, 30);
                setTabIndex(0);
                break;
            case "validated":
                fetchBills("validated", 1, 30);
                setTabIndex(1);
                break;
            case "rejected":
                fetchBills("rejected", 1, 30);
                setTabIndex(2);
                break;
            case "pending":
                fetchBills("pending", 1, 30);
                setTabIndex(3);
                break;
            default:
                fetchBills("all", 1, 30);
                setTabIndex(0);
                break;
        }
    }, [status]);

    const handleTabsChange = (index) => {
        setTabIndex(index);
        const tabMapping = ["all", "validated", "rejected", "pending"];
        navigate(`/${initialPath}/Billings/${tabMapping[index]}`);
    };

    const billingData = {};
    const [filteredData, setFilteredData] = useState(billingData);

    const filterData = (query) => {
        console.log("query", query);
        if (!query) {
            setFilteredData(billingData);
            return;
        }

        const filtered = billingData.filter((item) => {
            return deepSearch(item, query);
        });

        console.log("filtered", filtered);
        setFilteredData(filtered);
    };

    const deepSearch = (obj, query) => {
        for (const key in obj) {
            const value = obj[key];
            if (typeof value === "object") {
                if (deepSearch(value, query)) {
                    return true;
                }
            } else if (
                typeof value === "string" ||
                typeof value === "number" ||
                typeof value === "boolean"
            ) {
                if (String(value).toLowerCase().includes(query.toLowerCase())) {
                    return true;
                }
            } else if (Array.isArray(value)) {
                for (const item of value) {
                    if (typeof item === "object") {
                        if (deepSearch(item, query)) {
                            return true;
                        }
                    } else if (
                        typeof item === "string" ||
                        typeof item === "number" ||
                        typeof item === "boolean"
                    ) {
                        if (String(item).toLowerCase().includes(query.toLowerCase())) {
                            return true;
                        }
                    }
                }
            }
        }
        return false;
    };

    return (
        <>
            <Tabs isLazy variant="enclosed" index={tabIndex} onChange={handleTabsChange}>
                {/* <BillingHeader filterData={filterData} /> */}
                <TabList>
                    <Tab>
                        <Text
                            bg={tagColor("").bg}
                            color={tagColor("").clr}
                            px={2}
                            borderRadius={20}
                            fontWeight="bold"
                            fontSize={"sm"}
                        >
                            All
                        </Text>
                    </Tab>
                    <Tab>
                        <Text
                            bg={tagColor("green").bg}
                            color={tagColor("green").clr}
                            px={2}
                            borderRadius={20}
                            fontWeight="bold"
                            fontSize={"sm"}
                        >
                            Validated
                        </Text>
                    </Tab>
                    <Tab>
                        <Text
                            bg={tagColor("red").bg}
                            color={tagColor("red").clr}
                            px={2}
                            borderRadius={20}
                            fontWeight="bold"
                            fontSize={"sm"}
                        >
                            Rejected
                        </Text>
                    </Tab>
                    <Tab>
                        <Text
                            bg={tagColor("blue").bg}
                            color={tagColor("blue").clr}
                            px={2}
                            borderRadius={20}
                            fontWeight="bold"
                            fontSize={"sm"}
                        >
                            Pending
                        </Text>
                    </Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <BillingBody
                            data={allBills?.all}
                            fetchBills={fetchBills}
                            hasMore={hasMoreBills.all}
                            pageNo={BillsPageNo.all}
                            status={"all"}
                            loading={loadingBills}
                        />
                    </TabPanel>{" "}
                    <TabPanel>
                        <BillingBody
                            data={allBills?.validated}
                            fetchBills={fetchBills}
                            hasMore={hasMoreBills.validated}
                            pageNo={BillsPageNo.validated}
                            status={"validated"}
                            loading={loadingBills}
                        />
                    </TabPanel>{" "}
                    <TabPanel>
                        <BillingBody
                            data={allBills?.rejected}
                            fetchBills={fetchBills}
                            hasMore={hasMoreBills.rejected}
                            pageNo={BillsPageNo.rejected}
                            status={"rejected"}
                            loading={loadingBills}
                        />
                    </TabPanel>{" "}
                    <TabPanel>
                        <BillingBody
                            data={allBills?.pending}
                            fetchBills={fetchBills}
                            hasMore={hasMoreBills.pending}
                            pageNo={BillsPageNo.pending}
                            status={"pending"}
                            loading={loadingBills}
                        />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </>
    );
};

export default BillingContainer;
