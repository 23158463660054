import React, { useState, useEffect,useRef } from "react";
import { useLocation } from "react-router-dom";

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Badge,
  Text,
  Box,
  Tooltip,
  Flex,
  Avatar,
  Skeleton,
} from "@chakra-ui/react";
import { Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";

import { MdMenu } from "react-icons/md";
import { GrMore } from "react-icons/gr";
import { FaSort } from "react-icons/fa";
import { IoMaleFemale } from "react-icons/io5";
import { IoMdMale, IoMdFemale } from "react-icons/io";
import { TiArrowSortedUp, TiArrowSortedDown } from "react-icons/ti";

import { formatDateTime } from "../../../Utils/GlobalFunctions/FormatDate";
import getGenderIcon from "./../../../Utils/GlobalFunctions/GenderIcon";

import { useCandidate } from "../../../context/CandidateContext";
import { useJD } from "../../../context/JDContext";
import { useEnv } from "../../../context/EnvContext";
import { useApplication } from "../../../context/ApplicationContext";

import ApplicationDetails from "./ApplicationDetails";
import JdDetails from "../JobDescription/JdDetails";
import CandidateDetails from "../Candidate/CandidateDetails";
import { debounce } from "../../../Utils/GlobalFunctions/Debounce";

const ApplicationBody = ({
  data,
  fetchAllApplications,
  hasMore,
  pageNo,
  status,
  loading
}) => {
  // console.log("data", data);
  // console.log("hasMore", hasMore);
  // console.log("pageNo", pageNo);
  // console.log("status", status);
  const { backendUrl } = useEnv();
  const {
    candidateData,
    setCandidateData,
    fetchSpecificCandidate,
    loadingCandidate,
  } = useCandidate();
  const { jobDescriptionData, setJobDescriptionData, fetchJDById, loadingJD } =
    useJD();

  const { loadingApplication, forwardCV, rejectApplication, revertRejection } =
    useApplication();
  // handle view modals
  const [isCandidateDetailsModalOpen, setIsCandidateDetailsModalOpen] =
    useState(false);
  const [isJDDetailsModalOpen, setIsJDDetailsModalOpen] = useState(false);
  const [selectedApplication, setSelectedApplication] = useState(null);
  const [isApplicationDetailsModalOpen, setIsApplicationDetailsModalOpen] =
    useState(false);

    const { pathname } = useLocation();
    const initialPath = pathname.split("/")[1];

    // Fetch more data

  const containerRef = useRef(null); // Reference for the scrollable div
  const endRef = useRef(null);
  const pageNoRef = useRef(pageNo);
  const [scrollInitiated, setScrollInitiated] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true); // Track initial load


  const [sortedData, setSortedData] = useState(data);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: null,
  });

  useEffect(() => {
    let sortedArray = [...data];
    if (sortConfig.key) {
      sortedArray.sort((a, b) => {
        let aValue = a[sortConfig.key];
        let bValue = b[sortConfig.key];

        // Handle nested keys for jobDescription
        if (sortConfig.key === "jobDescription.jobTitle") {
          aValue = a.jobDescription.jobTitle;
          bValue = b.jobDescription.jobTitle;
        }

        // Handle nested keys for candidate
        if (sortConfig.key === "candidate?.name") {
          aValue = a.candidate?.name;
          bValue = b.candidate?.name;
        }

        if (aValue < bValue) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    setSortedData(sortedArray);
  }, [sortConfig, data]);

  // Update pageNoRef whenever pageNo changes
  useEffect(() => {
    pageNoRef.current = pageNo;
  }, [pageNo]);

  const fetchMoreData = async () => {
    if (loadingApplication || !hasMore || !scrollInitiated) return;

    await fetchAllApplications("applications",status, pageNoRef.current, 30, true);
    setIsInitialLoad(false); // Update after initial load
  };
  
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && scrollInitiated) {
          console.log("Fetching more data...");
          fetchMoreData();
        }
      },
      {
        root: containerRef.current,
        rootMargin: "0px 0px 100px 0px", // Adjusted to trigger earlier for smoother loading
        threshold: 0.5, // Increased to trigger loading more reliably
      }
    );
  
     // Only scroll to the bottom initially or after data is fetched
     if (isInitialLoad && containerRef.current) {
      containerRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (endRef.current) {
      // endRef.current.scrollIntoView({ behavior: "smooth" });
      observer.observe(endRef.current);
    }
  
    return () => {
      if (endRef.current) {
        observer.unobserve(endRef.current);
      }
    };
  }, [hasMore, loading, scrollInitiated,isInitialLoad]);

  // Track user scrolling within the container
  const handleScroll = debounce(() => {
    if (containerRef?.current?.scrollTop > 0) {
      setScrollInitiated(true);
    }
  }, 300); // Adjust the delay as needed

  // console.log(loadingApplication, hasMore);

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    } else if (
      sortConfig.key === key &&
      sortConfig.direction === "descending"
    ) {
      setSortConfig({ key: null, direction: null });
      return;
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      if (sortConfig.direction === "ascending") {
        return <TiArrowSortedUp cursor={"pointer"} />;
      } else if (sortConfig.direction === "descending") {
        return <TiArrowSortedDown cursor={"pointer"} />;
      }
    }
    return <FaSort cursor={"pointer"} />;
  };

  // Handle Candidate Details modal Preview
  const handleCandidatePreview = (candidate) => {
    // fetch the candidate
    fetchSpecificCandidate(candidate?._id);
    setIsCandidateDetailsModalOpen(true);
  };

  const handleCandidateViewCloseModal = () => {
    setIsCandidateDetailsModalOpen(false);
    setCandidateData(null);
  };

  // Handle JD Details modal Preview
  const handleJDPreview = (jd) => {
    // fetch the jd
    fetchJDById(jd._id);
    setIsJDDetailsModalOpen(true);
  };

  const handleJDViewCloseModal = () => {
    setIsJDDetailsModalOpen(false);
    setJobDescriptionData(null);
  };

  // Handle Application Details modal Preview
  const handleApplicationPreview = () => {
    setIsApplicationDetailsModalOpen(true);
  };
  const handleApplicationViewCloseModal = () => {
    setIsApplicationDetailsModalOpen(false);
    setSelectedApplication(null);
  };
  return (
    <div
      ref={containerRef}
      style={{
        height: "75vh",
        overflow: "auto",
        position: "relative",
      }}
      onScroll={handleScroll} // Trigger scroll detection
    >
      <TableContainer
        style={{ position: "relative" }}
        overflowY={sortedData.length < 5 ? "auto" : "clip"}
        overflowX={sortedData.length < 5 ? "clip" : "clip"}
      >
        <Table size="sm">
          <Thead
            style={{
              position: "sticky",
              top: 0,
              backgroundColor: "#05080D",
              zIndex: 1,
            }}
          >
            <Tr>
              <Th style={{ color: "white" }}>Sl</Th>
              <Th
                style={{ color: "white" }}
                onClick={() => requestSort("applicationID")}
              >
                <Flex
                  alignItems={"center"}
                  width={"100%"}
                  justify={"space-between"}
                >
                  <Tooltip
                    hasArrow
                    label={
                      <>
                        <Text textAlign={"center"}>Application Id</Text>
                        <Text
                          fontSize={"xs"}
                          color={"gray"}
                          textAlign={"center"}
                        >
                          Click to view the Application Details
                        </Text>
                      </>
                    }
                    bg="gray.300"
                    color="black"
                    placement="top"
                  >
                    App. Id
                  </Tooltip>{" "}
                  {getSortIcon("applicationID")}
                </Flex>
              </Th>
              <Th
                style={{
                  color: "white",
                }}
                onClick={() => requestSort("jobDescription.jobTitle")}
              >
                {" "}
                <Flex
                  alignItems={"center"}
                  width={"100%"}
                  justify={"space-between"}
                >
                  <Tooltip
                    hasArrow
                    label={
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Text fontSize={"sm"} textAlign={"center"}>
                          Job Id, Job Title
                        </Text>
                        <Text
                          fontSize={"xs"}
                          color={"gray"}
                          textAlign={"center"}
                        >
                          Click on job Id to view the JD details
                        </Text>
                      </Box>
                    }
                    bg="gray.300"
                    color="black"
                    placement="top"
                  >
                    Job Description
                  </Tooltip>
                  {getSortIcon("jobDescription.jobTitle")}
                </Flex>
              </Th>
              {(initialPath == "admin_dashboard" || initialPath == "bd_dashboard") && (
                <Th
                  style={{
                    color: "white",
                  }}
                  onClick={() => requestSort("addedBy.name")}
                >
                  <Flex
                    alignItems={"center"}
                    width={"100%"}
                    justify={"space-between"}
                  >
                    <Tooltip
                      hasArrow
                      label={
                        <>
                          <Flex align={"center"}>
                            <Avatar
                              src=""
                              size="sm"
                              name="Display Picture"
                              mr={2}
                              background={"green"}
                              color={"white"}
                            />
                            <Box
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Text
                                fontSize={"sm"}
                                style={{
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  maxWidth: "180px",
                                }}
                              >
                                Recruiter Name
                              </Text>
                              <Text
                                fontSize={"sm"}
                                color={"#2b2a2a"}
                                style={{
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  maxWidth: "180px",
                                }}
                              >
                                Employee Id
                              </Text>
                            </Box>
                          </Flex>
                        </>
                      }
                      bg="gray.300"
                      color="black"
                      placement="top"
                    >
                      Recruiter
                    </Tooltip>
                    {getSortIcon("addedBy.name")}
                  </Flex>
                </Th>
              )}
              <Th
                style={{
                  color: "white",
                }}
                onClick={() => requestSort("candidate?.name")}
              >
                {" "}
                <Flex
                  alignItems={"center"}
                  width={"100%"}
                  justify={"space-between"}
                >
                  <Tooltip
                    hasArrow
                    label={
                      <>
                        <Flex align={"center"}>
                          <Avatar
                            src=""
                            size="sm"
                            name="Display Picture"
                            mr={2}
                            background={"green"}
                            color={"white"}
                          />
                          <Box
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Text
                              fontSize={"sm"}
                              style={{
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                              }}
                            >
                              <b>Candidate Id</b>
                              {" - "}
                              Name, gender
                            </Text>
                            <Text
                              fontSize={"sm"}
                              color={"#2b2a2a"}
                              style={{
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                maxWidth: "180px",
                              }}
                            >
                              Candidate Email
                            </Text>
                          </Box>
                        </Flex>
                        <Flex
                          direction={"column"}
                          fontSize={"xs"}
                          color={"gray"}
                          mt={2}
                        >
                          <Text color={"black"}>Gender Details: </Text>
                          <Flex
                            align={"center"}
                            gap={1}
                            background={"black"}
                            p={1}
                            px={2}
                            color={"white"}
                          >
                            <IoMdMale color="#2D9AFE" />
                            <Text>Male, </Text>
                            <IoMdFemale color="#F86CFF" />
                            <Text>Female, </Text>
                            <IoMaleFemale color="#ffd600" />
                            <Text>Other</Text>
                          </Flex>
                        </Flex>
                        <Text
                          fontSize={"xs"}
                          color={"gray"}
                          textAlign={"center"}
                        >
                          Click on the DP to view the candidate details <br />
                          Click on mail id to mail the candidate?.
                        </Text>
                      </>
                    }
                    bg="gray.300"
                    color="black"
                    placement="top"
                  >
                    Candidate
                  </Tooltip>
                  {getSortIcon("candidate?.name")}
                </Flex>
              </Th>

              <Th
                style={{ color: "white" }}
                onClick={() => requestSort("updatedAt")}
              >
                <Flex
                  alignItems={"center"}
                  width={"100%"}
                  justify={"space-between"}
                >
                  <Tooltip
                    hasArrow
                    label={
                      <>
                        <Text textAlign={"center"}>
                          Application Current Status
                        </Text>
                        <Text
                          fontSize={"sm"}
                          color={"#575757"}
                          textAlign={"center"}
                        >
                          Status change date and time
                        </Text>
                        <Text
                          fontSize={"xs"}
                          color={"#575757"}
                          maxW={"200px"}
                          m={2}
                          textAlign={"center"}
                        >
                          If Status is Rejected then hover on it to see
                          Rejection Remarks.
                        </Text>
                      </>
                    }
                    bg="gray.300"
                    color="black"
                    placement="top"
                  >
                    Cur. St. & Time
                  </Tooltip>{" "}
                  {getSortIcon("updatedAt")}
                </Flex>
              </Th>

              <Th
                style={{ color: "white" }}
                onClick={() => requestSort("createdAt")}
              >
                {" "}
                <Flex
                  alignItems={"center"}
                  width={"100%"}
                  justify={"space-between"}
                >
                  <Tooltip
                    hasArrow
                    label="Application Created On"
                    bg="gray.300"
                    color="black"
                    placement="top"
                  >
                    Created on
                  </Tooltip>{" "}
                  {getSortIcon("createdAt")}
                </Flex>
              </Th>
              <Th
                style={{ color: "white" }}
                onClick={() => requestSort("applicationStatus")}
              >
                {" "}
                <Flex
                  alignItems={"center"}
                  width={"100%"}
                  justify={"space-between"}
                >
                  <Tooltip
                    hasArrow
                    label="Application Status"
                    bg="gray.300"
                    color="black"
                    placement="top"
                  >
                    Status
                  </Tooltip>{" "}
                  {getSortIcon("applicationStatus")}
                </Flex>
              </Th>

              <Th
                style={{
                  color: "white",
                }}
              >
                <Tooltip
                  hasArrow
                  label='Click on respective "Menu Button" for more options.'
                  bg="gray.300"
                  color="black"
                  placement="top"
                  maxW={"200px"}
                >
                  <Box>
                    <MdMenu />
                  </Box>
                </Tooltip>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {sortedData &&
              sortedData.map((application, index) => (
                <Tr key={index}>
                  <Td>{index + 1}</Td>
                  <Td style={{ cursor: "pointer" }}>
                    <Tooltip
                      hasArrow
                      label={
                        <Text fontSize={"xs"} color={"gray"}>
                          Click to view <b>{application?.applicationID}</b> in
                          Details
                        </Text>
                      }
                      bg="gray.300"
                      color="black"
                      placement="top"
                    >
                      <Box
                        onClick={() => {
                          setSelectedApplication(application);
                          handleApplicationPreview(application);
                        }}
                      >
                        {application?.applicationID}
                      </Box>
                    </Tooltip>
                  </Td>
                  <Td
                    style={{
                      maxWidth: "230px",
                      overflow: "hidden",
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Text fontSize={"sm"}>
                        <Tooltip
                          hasArrow
                          label={
                            <Text fontSize={"xs"} color={"gray"}>
                              Click to view{" "}
                              <b>{application?.jobDescription?.jobId}</b> in
                              Details
                            </Text>
                          }
                          bg="gray.300"
                          color="black"
                          placement="top"
                        >
                          <Flex
                            cursor={loadingJD ? "progress" : "pointer"}
                            onClick={() => {
                              if (!loadingJD)
                                handleJDPreview(application?.jobDescription);
                            }}
                          >
                            {application?.jobDescription?.jobId}
                          </Flex>
                        </Tooltip>
                      </Text>

                      <Text
                        fontSize={"sm"}
                        color={"#a3a3a3"}
                        style={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          maxWidth: "180px",
                        }}
                      >
                        {application?.jobDescription?.jobTitle}
                      </Text>
                    </Box>
                  </Td>
                  {(initialPath == "admin_dashboard" || initialPath == "bd_dashboard") && (
                    <Td
                      style={{
                        maxWidth: "250px",
                        overflow: "hidden",
                      }}
                    >
                      <Flex>
                        {/* <Avatar
                                                        isLazy
                                                        src={`${backendUrl}${application?.addedBy?.dp}`}
                                                        size="sm"
                                                        name={application?.recruiter?.name}
                                                        mr={2}
                                                        background={"green"}
                                                        color={"white"}
                                                    /> */}
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Flex gap={1}>
                            <Text
                              fontSize={"sm"}
                              style={{
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                maxWidth: "180px",
                              }}
                            >
                              {application?.recruiter?.name}
                            </Text>
                          </Flex>
                          <Text
                            fontSize={"sm"}
                            color={"#a3a3a3"}
                            style={{
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              maxWidth: "180px",
                            }}
                          >
                            {application?.recruiter?.empId}
                          </Text>
                        </Box>
                      </Flex>
                    </Td>
                  )}
                  <Td
                    style={{
                      maxWidth: "250px",
                      overflow: "hidden",
                    }}
                  >
                    <Flex>
                      <Tooltip
                        hasArrow
                        label={
                          <Text fontSize={"xs"} color={"gray"}>
                            Click to view Candidate{" "}
                            <b>{application?.candidate?.stuId}</b> in Details
                          </Text>
                        }
                        bg="gray.300"
                        color="black"
                        placement="top"
                      >
                        <Avatar
                          src={`${backendUrl}${application?.candidate?.dp}`}
                          size="sm"
                          name={application?.candidate?.name}
                          mr={2}
                          background={"green"}
                          color={"white"}
                          cursor={loadingCandidate ? "progress" : "pointer"}
                          onClick={() => {
                            if (!loadingCandidate)
                              handleCandidatePreview(application?.candidate);
                          }}
                        />
                      </Tooltip>

                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Flex gap={1}>
                          <Text
                            fontSize={"sm"}
                            style={{
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              maxWidth: "150px",
                            }}
                          >
                            <span
                              style={{
                                color: "#a3a3a3",
                                fontWeight: "bold",
                              }}
                            >
                              {application?.candidate?.stuId}
                            </span>
                            {" - "}
                            {application?.candidate?.name}
                          </Text>
                          {getGenderIcon(application?.candidate?.gender)}
                        </Flex>
                        <Text
                          fontSize={"sm"}
                          color={"#a3a3a3"}
                          style={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            maxWidth: "160px",
                          }}
                        >
                          <Tooltip
                            hasArrow
                            label={
                              <Text fontSize={"xs"} color={"gray"}>
                                Click to mail the Candidate
                              </Text>
                            }
                            bg="gray.300"
                            color="black"
                            placement="bottom"
                          >
                            <a
                              target="_blank"
                              href={`mailto:${application?.candidate?.email}`}
                            >
                              {application?.candidate?.email}
                            </a>
                          </Tooltip>
                        </Text>
                      </Box>
                    </Flex>
                  </Td>
                  <Td>
                    {application?.applicationStatus == "Rejected" ? (
                      <Tooltip
                        hasArrow
                        label={
                          application?.rejectionRemarks && (
                            <>
                              Remark:
                              <Box whiteSpace="pre-line">
                                {application?.rejectionRemarks}
                              </Box>
                            </>
                          )
                        }
                        bg="gray.300"
                        color="black"
                        placement="top"
                      >
                        <Flex direction={"column"}>
                          {application?.currentState}
                          <Text fontSize={"xs"} color={"#a3a3a3"}>
                            {formatDateTime(application?.updatedAt)}
                          </Text>
                        </Flex>
                      </Tooltip>
                    ) : (
                      <Flex direction={"column"}>
                        {application?.currentState}
                        <Text fontSize={"xs"} color={"#a3a3a3"}>
                          {formatDateTime(application?.updatedAt)}
                        </Text>
                      </Flex>
                    )}
                  </Td>
                  <Td>{formatDateTime(application?.createdAt)}</Td>
                  <Td>
                    {application?.applicationStatus == "Not Started" && (
                      <Badge variant="subtle" colorScheme="yellow">
                        Not Started
                      </Badge>
                    )}
                    {application?.applicationStatus == "On Process" && (
                      <Badge variant="subtle" colorScheme="blue">
                        On Process
                      </Badge>
                    )}
                    {application?.applicationStatus == "Selected" && (
                      <Badge variant="subtle" colorScheme="green">
                        Selected
                      </Badge>
                    )}
                    {application?.applicationStatus == "Rejected" && (
                      <Badge variant="subtle" colorScheme="red">
                        Rejected
                      </Badge>
                    )}
                  </Td>
                  <Td>
                    <Menu>
                      <MenuButton>
                        <GrMore />
                      </MenuButton>
                      <MenuList minW={"50px"}>
                        <MenuItem
                          onClick={() => {
                            setSelectedApplication(application);
                            handleApplicationPreview(application);
                          }}
                        >
                          View Application
                        </MenuItem>
                        {application?.applicationStatus == "Not Started" && (
                          <MenuItem
                            onClick={() => forwardCV(application?._id, status)}
                          >
                            Forward CV
                          </MenuItem>
                        )}
                        {/* {application?.applicationStatus ==
                                                        "Rejected" && (
                                                        <MenuItem
                                                            color={"#f25c5c"}
                                                            onClick={() =>
                                                                revertRejection(
                                                                    application?._id,
                                                                    status
                                                                )
                                                            }
                                                        >
                                                            Revert Application Rejection
                                                        </MenuItem>
                                                    )} */}
                        {application?.applicationStatus !== "Rejected" && (
                          <MenuItem
                            color={"#f25c5c"}
                            onClick={() =>
                              rejectApplication(application?._id, status)
                            }
                          >
                            Reject Application
                          </MenuItem>
                        )}
                      </MenuList>
                    </Menu>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </TableContainer>

      {(loading || hasMore) && (
        <>
          <Skeleton height="30px" m={2} />
          <Skeleton height="30px" m={2} />
          <Skeleton height="30px" m={2} />
        </>
      )}
      {!hasMore && !loading && (
        <p style={{ textAlign: "center", marginTop: "5px" }}>
          <b>End of the List</b>
        </p>
      )}
      {candidateData && isCandidateDetailsModalOpen && (
        <CandidateDetails
          candidate={candidateData}
          isOpen={isCandidateDetailsModalOpen}
          onClose={handleCandidateViewCloseModal}
        />
      )}
      {jobDescriptionData && isJDDetailsModalOpen && (
        <JdDetails
          jd={jobDescriptionData}
          isOpen={isJDDetailsModalOpen}
          onClose={handleJDViewCloseModal}
        />
      )}
      {selectedApplication && isApplicationDetailsModalOpen && (
        <ApplicationDetails
          application={selectedApplication}
          isOpen={isApplicationDetailsModalOpen}
          onClose={handleApplicationViewCloseModal}
        />
      )}

      <div ref={endRef} style={{ height: "1px" }}></div>
    </div>
  );
};

export default ApplicationBody;
