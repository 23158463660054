import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  VStack,
  Textarea,
  Box,
  Flex,
  Heading,
  Tag,
  TagLabel,
  TagCloseButton,
  Text,
  IconButton,
  Avatar,
  InputRightAddon,
  InputGroup,
} from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";
import SkillsInput from "../../../Utils/GlobalComponents/SkillsInput";
import { useEnv } from "../../../context/EnvContext";
import OptionalSkillsInput from "./../../../Utils/GlobalComponents/OptionalSkillsInput ";
import RecruitersInput from "../../../Utils/GlobalComponents/RecruitersInput";
import { useJD } from "../../../context/JDContext";
import { useAuth } from "../../../context/AuthContext";
import ClientDetailsInput from "../../../Utils/GlobalComponents/ClientInput";

const SimilarTagsInput = ({ similarTags, setSimilarTags }) => {
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputKeyDown = (e) => {
    if (e.key === "Enter" && inputValue.trim()) {
      const trimmedValue = inputValue.trim();
      const lowerCaseValue = trimmedValue.toLowerCase();

      // Check if the tag already exists (case-insensitive)
      if (similarTags.some((tag) => tag.toLowerCase() === lowerCaseValue)) {
        alert("Tag already exists");
        setInputValue(""); // Clear input
        return;
      }

      // Check if the input contains a comma
      if (trimmedValue.includes(",")) {
        alert("Tags cannot contain commas");
        setInputValue(""); // Clear input
        return;
      }

      // Add new tag in its original case
      setSimilarTags([...similarTags, trimmedValue]);
      setInputValue(""); // Clear input after adding
    }
  };

  return (
    <FormControl id="similarTags">
      <FormLabel mb={1}>Similar Tags</FormLabel>
      <Input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleInputKeyDown}
        placeholder="Type a tag and press enter, Ex. Java Script Developer"
      />
    </FormControl>
  );
};

const InterviewRoundsInput = ({ interviewRounds, setInterviewRounds }) => {
  const [inputValue, setInputValue] = useState({
    roundNo: "1",
    title: "",
    locationOrMode: "",
    instructions: "",
  });

  const [lastRoundNo, setLastRoundNo] = useState(1);
  const [editIndex, setEditIndex] = useState(-1);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    if (editIndex === -1) {
      setInterviewRounds([...interviewRounds, inputValue]);
      setInputValue({
        roundNo: lastRoundNo + 1,
        title: "",
        locationOrMode: "",
        instructions: "",
      });
      setLastRoundNo((pr) => pr + 1);
    } else {
      const updatedRounds = interviewRounds.map((round, index) =>
        index === editIndex ? inputValue : round
      );
      setInterviewRounds(updatedRounds);
      setEditIndex(-1);
      setInputValue({
        roundNo: lastRoundNo,
        title: "",
        locationOrMode: "",
        instructions: "",
      });
    }
  };

  const editRound = (index) => {
    setInputValue(interviewRounds[index]);
    setEditIndex(index);
  };

  const handleClear = (index) => {
    setEditIndex(-1);
    setInputValue({
      roundNo: lastRoundNo,
      title: "",
      locationOrMode: "",
      instructions: "",
    });
  };

  const removeRound = (roundToRemove) => {
    setInterviewRounds(
      interviewRounds.filter((round) => round !== roundToRemove)
    );
  };

  return (
    <FormControl id="interviewRounds">
      <FormLabel mb={1}>Interview Rounds</FormLabel>
      <Box p={2} border="1px" borderColor={"inherit"} borderRadius={"md"}>
        <VStack spacing={1} align="stretch">
          <FormControl id="roundNo" position="relative">
            <FormLabel mb={1}>Round Number - {inputValue.roundNo}</FormLabel>
          </FormControl>
          <FormControl id="title" position="relative" mt={1}>
            <FormLabel mb={1}>Title</FormLabel>
            <Input
              type="text"
              name="title"
              value={inputValue.title}
              onChange={handleInputChange}
              placeholder="Ex. HR Round"
            />
          </FormControl>
          <FormControl id="locationOrMode" position="relative" mt={3}>
            <FormLabel mb={1}>Location or Mode</FormLabel>
            <Input
              type="text"
              name="locationOrMode"
              value={inputValue.locationOrMode}
              onChange={handleInputChange}
              placeholder="Ex. Online"
            />
          </FormControl>
          <FormControl id="instructions" position="relative" mt={3}>
            <FormLabel mb={1}>Instructions</FormLabel>
            <Textarea
              name="instructions"
              value={inputValue.instructions}
              onChange={handleInputChange}
              placeholder="Write about the Interview Round in details..."
            />
          </FormControl>
          {editIndex === -1 ? (
            <Button
              onClick={handleSubmit}
              size="xs"
              colorScheme="green"
              style={{
                borderRadius: "20px",
                transition: "background 0.3s ease",
              }}
            >
              Add Round
            </Button>
          ) : (
            <Flex gap={1} w={"100%"}>
              <Button
                p={1}
                onClick={handleClear}
                colorScheme="red"
                size="xs"
                style={{
                  borderRadius: "20px",
                  transition: "background 0.3s ease",
                }}
                flex={1}
              >
                Cancel Update
              </Button>
              <Button
                onClick={handleSubmit}
                colorScheme="orange"
                flex={1}
                size="xs"
                style={{
                  borderRadius: "20px",
                  transition: "background 0.3s ease",
                }}
              >
                Update Round
              </Button>
            </Flex>
          )}
          <Box mt={1}>
            {interviewRounds.map((round, index) => (
              <Tag
                key={index}
                size="md"
                borderRadius="sm"
                variant="solid"
                colorScheme="teal"
                m={1}
                style={{ alignItems: "flex-start" }}
                w={"100%"}
              >
                <TagLabel w={"100%"}>
                  <Flex direction={"column"} py={2} w={"100%"} gap={1}>
                    <Text fontSize={"sm"} color={"white"}>
                      Round No. {round.roundNo}
                      {", "}
                      {round.title}
                      {", "}
                      {round.locationOrMode}
                    </Text>
                    <Text fontSize={"xs"} color={"#c9c9c9"}>
                      <b>Instruction: </b>
                      {round.instructions}
                    </Text>
                  </Flex>
                </TagLabel>
                <IconButton
                  size="xs"
                  icon={<EditIcon />}
                  onClick={() => editRound(index)}
                  m={1}
                />
                <IconButton
                  size="xs"
                  icon={<TagCloseButton m={0} />}
                  onClick={() => removeRound(round)}
                  mt={1}
                />
              </Tag>
            ))}
          </Box>
        </VStack>
      </Box>
    </FormControl>
  );
};

const JDForm = ({ isOpen, onClose, operation = "Add JD", jd }) => {
  const { backendUrl } = useEnv();
  const { addJD, updateJD } = useJD();
  const { userData } = useAuth();

  // State hooks
  const [similarTags, setSimilarTags] = useState([]);
  const [workingRecruiters, setWorkingRecruiters] = useState([]);
  const [clientDetails, setClientDetails] = useState([]);
  const [optionalSkills, setOptionalSkills] = useState([]);
  const [skills, setSkills] = useState([]);
  const [interviewRounds, setInterviewRounds] = useState([]);

  const [formData, setFormData] = useState({
    jobTitle: "",
    similarTags: [],
    skills: [],
    optionalSkills: [],
    description: "",
    experience: 0,
    expValidity: 50,
    relExpValidity: 50,
    numberOfOpenings: 0,
    interviewRounds: [],
    closingDate: "",
    status: "Active",
    salaryOffering: "",
    percentage: 0,
    billingPeriod: 0,
    workingRecruiters: [],
    broughtBy: userData.userId,
    clientDetails: "",
  });

  // Load existing job data for updating
  const loadJD = () => {
    if (operation === "Update JD") {
      setFormData(jd);
      setSimilarTags(jd?.similarTags);
      setSkills(jd?.skills);
      setOptionalSkills(jd?.optionalSkills);
      setInterviewRounds(jd?.interviewRounds);
      setWorkingRecruiters(jd?.workingRecruiters);
      setClientDetails(jd?.clientDetails);
    }
  };

  useEffect(() => {
    loadJD();
  }, [operation]);

  //console.log("selected jd", jd);

  // Handle input changes
  const handleChange = (e) => {
    let { name, value } = e.target;
    const [parent, child] = name.split(".");

    // Handle child inputs
    if (child) {
      setFormData((prevData) => ({
        ...prevData,
        [parent]: {
          ...prevData[parent],
          [child]: value,
        },
      }));
    } else {
      // Ensure value is a valid number and handle constraints
      if (value < 0) value = 0;

      if (["expValidity", "relExpValidity", "percentage"].includes(name)) {
        if (value > 100) value = 100;
      }

      if (name === "expValidity") {
        if (value > 100) value = 100;
        setFormData((prevData) => ({
          ...prevData,
          expValidity: value,
          relExpValidity: 100 - value,
        }));
      } else if (name === "relExpValidity") {
        if (value > 100) value = 100;
        setFormData((prevData) => ({
          ...prevData,
          relExpValidity: value,
          expValidity: 100 - value,
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    }
  };

  //console.log("formData", formData);

  // Validate form data before submission
  const validateForm = () => {
    const errors = [];

    if (!formData.jobTitle) {
      errors.push("Job title is required.");
    }
    if (formData.experience < 0) {
      errors.push("Overall experience must be a non-negative number.");
    }
    if (formData.numberOfOpenings <= 0) {
      errors.push("Number of openings must be a positive number.");
    }
    if (!formData.closingDate) {
      errors.push("Closing date is required.");
    } else if (formData.closingDate) {
      const today = new Date();
      const closingDate = new Date(formData.closingDate);

      // Check if closing date is in the past
      if (closingDate < today) {
        errors.push("Closing date cannot be in the past.");
      }
    }
    if (!formData.salaryOffering) {
      errors.push("Salary offering is required.");
    }
    if (formData.percentage < 0 || formData.percentage > 100) {
      errors.push("Percentage share must be between 0 and 100.");
    }
    if (formData.billingPeriod < 0) {
      errors.push("Billing period must be a non-negative number.");
    }
    if (workingRecruiters.length === 0) {
      errors.push("At least one working recruiter is required.");
    }
    if (similarTags.length === 0) {
      errors.push("At least one similar tag is required.");
    }
    if (skills.length === 0) {
      errors.push("At least one skill is required.");
    }

    return errors;
  };

  const handleOverallSubmit = () => {
    const validationErrors = validateForm();
    if (validationErrors.length > 0) {
      alert(validationErrors.join("\n")); // Display errors to the user
      return;
    }

    const updatedFormData = {
      ...formData,
      similarTags,
      skills,
      optionalSkills,
      interviewRounds,
      workingRecruiters: workingRecruiters.map((wr) => wr._id),
      clientDetails,
    };

    // Handle form submission logic here
    //console.log(updatedFormData);
    if (operation === "Update JD") {
      updateJD(updatedFormData, jd?._id, jd?.status);
    } else {
      addJD(updatedFormData);
    }
    onClose();
  };

  const removeTag = (tagToRemove) => {
    setSimilarTags(similarTags.filter((tag) => tag !== tagToRemove));
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"4xl"}>
      <ModalOverlay />
      <ModalContent height={"100vh"} overflow={"clip"} mt={0} mb={0}>
        <ModalCloseButton top={1} />
        <ModalBody mt={0}>
          <Flex justify={"space-between"} mb={2}>
            <Heading as="h3" size="md">
              {operation}
            </Heading>

            <Flex mr={4} mb={2}>
              {operation === "Update JD" && (
                <Button
                  colorScheme="red"
                  size="xs"
                  style={{
                    borderRadius: "2px",
                    marginInline: "5px",
                    transition: "background 0.3s ease",
                  }}
                  onClick={loadJD}
                >
                  Reset
                </Button>
              )}
              <Button
                colorScheme="blue"
                size="xs"
                style={{
                  borderRadius: "2px",
                  marginRight: "25px",
                  transition: "background 0.3s ease",
                }}
                onClick={handleOverallSubmit}
              >
                {operation}
              </Button>
            </Flex>
          </Flex>
          <VStack
            as="form"
            spacing={4}
            width="100%"
            height={"calc(100vh - 40px)"}
            overflowY={"auto"}
            pr={2}
            pb={4}
          >
            <Flex w={"100%"} gap={2}>
              <FormControl id="jobTitle" isRequired>
                <FormLabel mb={1}>Job Title</FormLabel>
                <Input
                  type="text"
                  name="jobTitle"
                  value={formData.jobTitle}
                  onChange={handleChange}
                  placeholder="Ex. Full Stack Web Developer"
                />
              </FormControl>
              <SimilarTagsInput
                similarTags={similarTags}
                setSimilarTags={setSimilarTags}
              />
            </Flex>
            {similarTags.length > 0 && (
              <Box mt={"-8px"} w={"100%"}>
                {similarTags.map((tag, index) => (
                  <Tag key={index} size="md" borderRadius="full" m={1}>
                    <TagLabel>{tag}</TagLabel>
                    <TagCloseButton onClick={() => removeTag(tag)} />
                  </Tag>
                ))}
              </Box>
            )}
            <Flex w={"100%"} gap={2}>
              <SkillsInput skills={skills} setSkills={setSkills} />
              <OptionalSkillsInput
                optionalSkills={optionalSkills}
                setOptionalSkills={setOptionalSkills}
              />
            </Flex>
            <FormControl id="description">
              <FormLabel mb={1}>Description</FormLabel>
              <Textarea
                name="description"
                value={formData.description}
                onChange={handleChange}
                placeholder="Write Job Description in details..."
              />
            </FormControl>

            <Flex w={"100%"} gap={2}>
              <FormControl id="experience">
                <FormLabel mb={1}>Overall Experience in Years</FormLabel>
                <InputGroup>
                  <Input
                    type="number"
                    name="experience"
                    value={formData.experience}
                    onChange={handleChange}
                    placeholder="Ex. 5"
                    min={0}
                  />
                  <InputRightAddon>Years</InputRightAddon>
                </InputGroup>
              </FormControl>
              <FormControl id="expValidity">
                <FormLabel mb={1}>Overall Experience Weightage</FormLabel>
                <InputGroup>
                  <Input
                    type="number"
                    name="expValidity"
                    value={formData.expValidity}
                    onChange={handleChange}
                    placeholder="Ex. 45"
                    min={0}
                    max={100}
                  />
                  <InputRightAddon>%</InputRightAddon>
                </InputGroup>
              </FormControl>
              <FormControl id="relExpValidity">
                <FormLabel mb={1}>Relevant Experience Weightage</FormLabel>
                <InputGroup>
                  <Input
                    type="number"
                    name="relExpValidity"
                    value={formData.relExpValidity}
                    onChange={handleChange}
                    placeholder="Ex. 65"
                    min={0}
                    max={100}
                  />
                  <InputRightAddon>%</InputRightAddon>
                </InputGroup>
              </FormControl>
            </Flex>
            <InterviewRoundsInput
              interviewRounds={interviewRounds}
              setInterviewRounds={setInterviewRounds}
            />
            <Flex w={"100%"} gap={2}>
              <FormControl id="numberOfOpenings">
                <FormLabel mb={1}>Number of Openings</FormLabel>
                <Input
                  type="number"
                  name="numberOfOpenings"
                  value={formData.numberOfOpenings}
                  onChange={handleChange}
                  onFocus={() =>
                    setFormData((prevData) => ({
                      ...prevData,
                      numberOfOpenings: "",
                    }))
                  } // Clear input on focus
                  placeholder="Ex. 5"
                  min={0}
                />
              </FormControl>
              <FormControl id="closingDate">
                <FormLabel mb={1}>Closing Date</FormLabel>
                <Input
                  type="date"
                  name="closingDate"
                  value={formData.closingDate}
                  onChange={handleChange}
                  placeholder="Ex. 12/11/2024"
                />
              </FormControl>
              {operation === "Update JD" && (
                <FormControl id="status">
                  <FormLabel mb={1}>Current Status</FormLabel>
                  <Select
                    name="status"
                    value={formData.status}
                    onChange={handleChange}
                  >
                    <option value="Active">Active</option>
                    <option value="Closed">Closed</option>
                    <option value="On Hold">On Hold</option>
                    <option value="Cancelled">Cancelled</option>
                  </Select>
                </FormControl>
              )}
            </Flex>

            <Flex w={"100%"} gap={2}>
              <FormControl id="salaryOffering">
                <FormLabel mb={1}>Salary Offering</FormLabel>
                <Input
                  type="text"
                  name="salaryOffering"
                  value={formData.salaryOffering}
                  onChange={handleChange}
                  placeholder="Ex. 5 to 8 LPA"
                />
              </FormControl>
              <FormControl id="percentage">
                <FormLabel mb={1}>Percentage Share</FormLabel>
                <InputGroup>
                  <Input
                    type="number"
                    name="percentage"
                    value={formData.percentage}
                    onChange={handleChange}
                    placeholder="Ex. 5"
                    min={0}
                    max={100}
                  />
                  <InputRightAddon>%</InputRightAddon>
                </InputGroup>
              </FormControl>
              <FormControl id="billingPeriod">
                <FormLabel mb={1}>Billing Period</FormLabel>
                <InputGroup>
                  <Input
                    type="number"
                    name="billingPeriod"
                    value={formData.billingPeriod}
                    onChange={handleChange}
                    placeholder="Ex. 45"
                    min={0}
                  />
                  <InputRightAddon>Days</InputRightAddon>
                </InputGroup>
              </FormControl>
            </Flex>
            <ClientDetailsInput
              clientDetails={clientDetails}
              setClientDetails={setClientDetails}
            />
            <RecruitersInput
              workingRecruiters={workingRecruiters}
              setWorkingRecruiters={setWorkingRecruiters}
            />
            {userData.userType === "Admin" && (
              <FormControl id="broughtBy">
                <FormLabel mb={1}>Brought By</FormLabel>
                <Input
                  type="text"
                  name="broughtBy"
                  value={formData.broughtBy}
                  onChange={handleChange}
                  placeholder="Type to search Business Developers by name or ID, Ex. Anand"
                />
              </FormControl>
            )}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default JDForm;
