function convertToLPA(salary) {
    // Convert the salary to lakhs by dividing by 100,000
    let lakhs = salary / 100000;
    // Format the result to one decimal place if necessary and add ' LPA'
    if (Number.isInteger(lakhs)) {
        return `${lakhs} LPA`;
    } else {
        return `${lakhs.toFixed(1)} LPA`;
    }
}

export default convertToLPA;

export function numberToWords(number) {
    if (isNaN(number)) return "Invalid number";
    if (number === 0) return "Zero";

    const units = [
        "",
        "One",
        "Two",
        "Three",
        "Four",
        "Five",
        "Six",
        "Seven",
        "Eight",
        "Nine",
        "Ten",
        "Eleven",
        "Twelve",
        "Thirteen",
        "Fourteen",
        "Fifteen",
        "Sixteen",
        "Seventeen",
        "Eighteen",
        "Nineteen",
    ];
    const tens = [
        "",
        "",
        "Twenty",
        "Thirty",
        "Forty",
        "Fifty",
        "Sixty",
        "Seventy",
        "Eighty",
        "Ninety",
    ];
    const thousands = ["", "Thousand", "Lakh", "Crore"];

    function convertLessThanHundred(num) {
        if (num < 20) {
            return units[num] || "";
        } else {
            const tenPart = Math.floor(num / 10);
            const unitPart = num % 10;
            return (tens[tenPart] + (unitPart ? " " + units[unitPart] : "")).trim();
        }
    }

    function convertLessThanThousand(num) {
        const hundredPart = Math.floor(num / 100);
        const restPart = num % 100;
        return (
            (hundredPart ? units[hundredPart] + " Hundred " : "") + convertLessThanHundred(restPart)
        );
    }

    function convertToWords(num) {
        if (num === 0) return "Zero";
        let word = "";
        if (num >= 10000000) {
            word += convertLessThanThousand(Math.floor(num / 10000000)) + " Crore ";
            num %= 10000000;
        }
        if (num >= 100000) {
            word += convertLessThanThousand(Math.floor(num / 100000)) + " Lakh ";
            num %= 100000;
        }
        if (num >= 1000) {
            word += convertLessThanThousand(Math.floor(num / 1000)) + " Thousand ";
            num %= 1000;
        }
        if (num > 0) {
            word += convertLessThanThousand(num);
        }
        return word.trim();
    }

    return convertToWords(number);
}
