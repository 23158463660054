import React from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = ({ height = "5px", width = "5px", dataSet }) => {
    const data = {
        labels: dataSet.map((item) => item.label),
        datasets: [
            {
                data: dataSet.map((item) => item.data),
                backgroundColor: dataSet.map((item) => item.color),
                borderWidth: 0,
                hoverOffset: 4,
            },
        ],
    };

    const options = {
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: true,
            },
            datalabels: {
                color: "#fff",
                display: false,
                font: {
                    weight: "bold",
                },
            },
        },
    };

    return (
        <Pie data={data} options={options} height={height} width={width} style={{ zIndex: 999 }} />
    );
};

export default PieChart;
