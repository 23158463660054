import React, { useEffect, useState } from "react";

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Box,
    Text,
    Avatar,
    Flex,
    FormControl,
    FormLabel,
    Input,
} from "@chakra-ui/react";

import { useEnv } from "../../../context/EnvContext";
import getGenderIcon from "../../../Utils/GlobalFunctions/GenderIcon";
import { formatDateTimeDay } from "../../../Utils/GlobalFunctions/FormatDate";

const RecordJoining = ({ isOpen, onClose, application, recordJoiningDetails, status }) => {
    const { backendUrl } = useEnv();
    const [formData, setFormData] = useState({
        joiningDate: "",
        noticePeriodStartDate: "",
        noticePeriodEndDate: "",
    });
    const [isDataUpdated, setIsDataUpdated] = useState(false);
    const [errors, setErrors] = useState({});

    //console.log("application", application);
    useEffect(() => {
        setFormData({
            joiningDate: application?.placementDetails?.joiningDate || "",
            noticePeriodStartDate: application?.placementDetails?.noticePeriodStartDate || "",
            noticePeriodEndDate: application?.placementDetails?.noticePeriodEndDate || "",
        });
    }, [application]);

    useEffect(() => {
        if (
            formData.joiningDate !== "" &&
            !errors.joiningDate &&
            !errors.noticePeriodStartDate &&
            !errors.noticePeriodEndDate
        ) {
            setIsDataUpdated(true);
        } else {
            setIsDataUpdated(false);
        }
    }, [formData, application]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        let error = "";

        if (name === "joiningDate") {
            const selectedDate = new Date(value);
            // const currentDate = new Date();
            // if (selectedDate < currentDate) {
            //     error = "Joining date cannot be in the past";
            // }
        } else if (name === "noticePeriodStartDate" || name === "noticePeriodEndDate") {
            const noticePeriodStartDate =
                name === "noticePeriodStartDate"
                    ? new Date(value)
                    : new Date(formData.noticePeriodStartDate);
            const noticePeriodEndDate =
                name === "noticePeriodEndDate"
                    ? new Date(value)
                    : new Date(formData.noticePeriodEndDate);

            if (
                noticePeriodStartDate &&
                noticePeriodEndDate &&
                noticePeriodStartDate > noticePeriodEndDate
            ) {
                error = "Notice period start date cannot be after the notice period end date";
            }
        }

        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: error,
        }));

        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleFormReset = () => {
        setFormData({
            joiningDate: application?.placementDetails?.joiningDate || "",
            noticePeriodStartDate: application?.placementDetails?.noticePeriodStartDate || "",
            noticePeriodEndDate: application?.placementDetails?.noticePeriodEndDate || "",
        });
        setErrors({});
    };

    const handleFormSubmit = async () => {
        const res = await recordJoiningDetails(application?._id, formData);
        if (res?.success) {
            onClose();
        }
    };
    //console.log("formData", formData);
    return (
        <div>
            <Modal
                isLazy
                isOpen={isOpen}
                onClose={onClose}
                size={"xl"}
                style={{ minHeight: "100vh" }}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        Record Joining Details for <b>{application?.applicationID}</b>
                        <Flex justify={"space-between"} w={"100%"} mt={2} gap={2}>
                            <Box background={"teal"} w={"50%"} px={2} py={1} borderRadius={"md"}>
                                <Text fontSize={"md"}>{application?.jobDescription?.jobId}</Text>
                                <Text
                                    fontSize={"sm"}
                                    color={"#c9c9c9"}
                                    style={{
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                        maxWidth: "200px",
                                    }}
                                >
                                    {application?.jobDescription?.jobTitle}
                                </Text>
                            </Box>{" "}
                            <Flex
                                align={"center"}
                                background={"teal"}
                                w={"50%"}
                                px={2}
                                py={1}
                                borderRadius={"md"}
                            >
                                <Avatar
                                    src={`${backendUrl}${application?.candidate?.dp}`}
                                    size="sm"
                                    name={application?.candidate?.name}
                                    mr={2}
                                    background={"green"}
                                    color={"white"}
                                />

                                <Box
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                >
                                    <Flex gap={1}>
                                        <Text
                                            fontSize={"sm"}
                                            style={{
                                                textOverflow: "ellipsis",
                                                overflow: "hidden",
                                                maxWidth: "270px",
                                            }}
                                        >
                                            <span
                                                style={{
                                                    color: "#c9c9c9",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                {application?.candidate?.stuId}
                                            </span>
                                            {" - "}
                                            {application?.candidate?.name}
                                        </Text>
                                        {getGenderIcon(application?.candidate?.gender)}
                                    </Flex>
                                    <Text
                                        fontSize={"sm"}
                                        color={"#c9c9c9"}
                                        style={{
                                            textOverflow: "ellipsis",
                                            overflow: "hidden",
                                            maxWidth: "270px",
                                        }}
                                    >
                                        {application?.candidate?.email}
                                    </Text>
                                </Box>
                            </Flex>
                        </Flex>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody style={{ paddingBottom: "20px" }}>
                        <Flex gap={3} w={"100%"} direction={"column"}>
                            <Flex gap={2} w={"100%"}>
                                <FormControl id="noticePeriodStartDate">
                                    <FormLabel>Notice Period Starting Date:</FormLabel>
                                    <Input
                                        type="date"
                                        name="noticePeriodStartDate"
                                        value={formData.noticePeriodStartDate}
                                        onChange={handleChange}
                                        placeholder="Ex. 400000"
                                    />
                                    {errors.noticePeriodStartDate && (
                                        <Text color="red.500" fontSize="sm">
                                            {errors.noticePeriodStartDate}
                                        </Text>
                                    )}
                                </FormControl>{" "}
                                <FormControl id="noticePeriodEndDate">
                                    <FormLabel>Notice Period Ending Date:</FormLabel>
                                    <Input
                                        type="date"
                                        name="noticePeriodEndDate"
                                        value={formData.noticePeriodEndDate}
                                        onChange={handleChange}
                                        placeholder="Ex. 200000"
                                    />
                                    {errors.noticePeriodEndDate && (
                                        <Text color="red.500" fontSize="sm">
                                            {errors.noticePeriodEndDate}
                                        </Text>
                                    )}
                                </FormControl>
                            </Flex>
                            <FormControl id="joiningDate" isRequired>
                                <FormLabel>Joining Date:</FormLabel>
                                <Input
                                    type="date"
                                    name="joiningDate"
                                    value={formData.joiningDate}
                                    onChange={handleChange}
                                    placeholder="Ex. 600000"
                                />
                                {errors.joiningDate && (
                                    <Text color="red.500" fontSize="sm">
                                        {errors.joiningDate}
                                    </Text>
                                )}
                            </FormControl>
                        </Flex>
                    </ModalBody>{" "}
                    <ModalFooter>
                        <Button colorScheme="red" mr={3} size="sm" onClick={handleFormReset}>
                            Reset
                        </Button>
                        <Button
                            colorScheme="blue"
                            size="sm"
                            onClick={handleFormSubmit}
                            isDisabled={!isDataUpdated}
                        >
                            Submit
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </div>
    );
};

export default RecordJoining;
