import React, { useEffect } from "react";

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Tag,
    TagLabel,
    Box,
    Text,
    Avatar,
    Tooltip,
    Flex,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionIcon,
    AccordionPanel,
} from "@chakra-ui/react";
import { Table, Tbody, Tr, Td, TableContainer } from "@chakra-ui/react";

import { formatDate, formatDateTime } from "../../../Utils/GlobalFunctions/FormatDate";
import { useEnv } from "../../../context/EnvContext";

const JdDetails = ({ isOpen, onClose, jd }) => {
    const { backendUrl } = useEnv();
    //console.log("jd", jd);

    useEffect(() => {
        if (!jd) return;
    }, [jd]);

    return (
        <div>
            <Modal
                isLazy
                isOpen={isOpen}
                onClose={onClose}
                size={"4xl"}
                style={{ minHeight: "100vh" }}
            >
                <ModalOverlay />
                <ModalContent className="jd-details">
                    <ModalHeader>
                        {jd?.jobId} - {jd?.jobTitle}
                        <Box>
                            {jd?.similarTags?.map((tag, index) => (
                                <Tag
                                    key={index}
                                    size="md"
                                    borderRadius="full"
                                    variant="solid"
                                    mr={1}
                                >
                                    <TagLabel>{tag}</TagLabel>
                                </Tag>
                            ))}
                        </Box>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody style={{ paddingBottom: "20px" }}>
                        <TableContainer width="100%" style={{ whiteSpace: "normal" }}>
                            <Table variant="simple">
                                <Tbody>
                                    <Tr>
                                        <Td>
                                            <b>Job Description:</b>
                                        </Td>
                                        <Td>{jd?.description}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td>
                                            <b>Company:</b>
                                        </Td>
                                        <Td>
                                            <Box bg={"#4A5363"} px={2} py={1} borderRadius={"md"}>
                                                <Flex gap={2} align={"center"}>
                                                    <Avatar
                                                        size="sm"
                                                        name={jd?.clientDetails?.client?.name}
                                                        src={`${backendUrl}${jd?.clientDetails?.client?.logo}`}
                                                    />
                                                    <Flex direction={"column"}>
                                                        <Text fontSize={"sm"}>
                                                            {jd?.clientDetails?.client?.name}
                                                        </Text>
                                                        <Box fontSize={"xs"} mt={-1}>
                                                            {jd?.clientDetails?.client?.city && (
                                                                <Text as={"span"}>
                                                                    {
                                                                        jd?.clientDetails?.client
                                                                            ?.city
                                                                    }
                                                                    ,
                                                                </Text>
                                                            )}
                                                            {jd?.clientDetails?.client?.state && (
                                                                <Text as={"span"}>
                                                                    {
                                                                        jd?.clientDetails?.client
                                                                            ?.state
                                                                    }
                                                                    ,
                                                                </Text>
                                                            )}
                                                            {jd?.clientDetails?.client?.country && (
                                                                <Text as={"span"}>
                                                                    {
                                                                        jd?.clientDetails?.client
                                                                            ?.country
                                                                    }
                                                                </Text>
                                                            )}
                                                        </Box>
                                                    </Flex>
                                                </Flex>
                                                {jd?.clientDetails?.client?.estd && (
                                                    <Text fontSize={"xs"}>
                                                        <b>Established - </b>
                                                        {jd?.clientDetails?.client?.estd}
                                                    </Text>
                                                )}
                                                {jd?.clientDetails?.client?.noOfEmp && (
                                                    <Text fontSize={"xs"}>
                                                        <b>No. of Employees - </b>
                                                        {jd?.clientDetails?.client?.noOfEmp}
                                                    </Text>
                                                )}
                                                {jd?.clientDetails?.client?.details && (
                                                    <Text fontSize={"xs"}>
                                                        <b>Details -</b>{" "}
                                                        {jd?.clientDetails?.client?.details}
                                                    </Text>
                                                )}
                                                {jd?.clientDetails?.handlerId &&
                                                    jd?.clientDetails?.client?.contacts && (
                                                        <Text fontSize={"xs"}>
                                                            <b>Contact -</b>{" "}
                                                            <Text as={"span"}>
                                                                {(() => {
                                                                    const contact =
                                                                        jd?.clientDetails.client.contacts.find(
                                                                            (contact) =>
                                                                                contact.handlerId ===
                                                                                jd?.clientDetails
                                                                                    .handlerId
                                                                        );
                                                                    return contact
                                                                        ? `${contact.name} (${contact.role}) - ${contact.contactNo} | ${contact.email}`
                                                                        : null;
                                                                })()}
                                                            </Text>
                                                        </Text>
                                                    )}
                                            </Box>
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td>
                                            <b>Required Skills:</b>
                                        </Td>
                                        <Td>
                                            {jd?.skills?.map((skill, index) => (
                                                <Tag
                                                    key={index}
                                                    m={1}
                                                    size="md"
                                                    borderRadius="full"
                                                    variant="solid"
                                                >
                                                    <TagLabel>{`${skill.skillName} - ${skill.relevantExp} Years`}</TagLabel>
                                                </Tag>
                                            ))}
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td>
                                            <b>Optional Skills:</b>
                                        </Td>
                                        <Td>
                                            {jd?.optionalSkills?.map((skill, index) => (
                                                <Tag
                                                    key={index}
                                                    m={"2px"}
                                                    size="md"
                                                    borderRadius="full"
                                                    variant="solid"
                                                >
                                                    <TagLabel>{skill}</TagLabel>
                                                </Tag>
                                            ))}
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td>
                                            <b>Overall Experience:</b>
                                        </Td>
                                        <Td>{jd?.experience} Years</Td>
                                    </Tr>

                                    <Tr>
                                        <Td>
                                            <b>Experience Weightage:</b>
                                        </Td>
                                        <Td>
                                            <Flex
                                                align={"center"}
                                                justify={"center"}
                                                height={"20px"}
                                                paddingX={2}
                                                boxSizing="border-box"
                                                flexGrow={1}
                                            >
                                                <Tooltip
                                                    hasArrow
                                                    label={`Overall Experience Weightage: ${jd?.expValidity}%`}
                                                    bg="gray.300"
                                                    color="black"
                                                    placement="top"
                                                >
                                                    <Box
                                                        width={`${jd?.expValidity}%`}
                                                        height={"80%"}
                                                        background={"#eb73e2"}
                                                        style={{
                                                            borderRadius: "10px 0px 0px 10px",
                                                        }}
                                                    >
                                                        <Text
                                                            align={"center"}
                                                            fontSize={"xs"}
                                                            color={"black"}
                                                            fontWeight={"bold"}
                                                            lineHeight={1.2}
                                                        >
                                                            {jd?.expValidity} %
                                                        </Text>
                                                    </Box>
                                                </Tooltip>
                                                <Tooltip
                                                    hasArrow
                                                    label={`Relevant Experience Weightage: ${jd?.relExpValidity}%`}
                                                    bg="gray.300"
                                                    color="black"
                                                    placement="top"
                                                >
                                                    <Box
                                                        width={`${jd?.relExpValidity}%`}
                                                        height={"80%"}
                                                        background={"#fbd1a2"}
                                                        style={{
                                                            borderRadius: "0px 10px 10px 0px",
                                                        }}
                                                    >
                                                        <Text
                                                            align={"center"}
                                                            fontSize={"xs"}
                                                            color={"black"}
                                                            fontWeight={"bold"}
                                                            lineHeight={1.2}
                                                        >
                                                            {jd?.relExpValidity} %
                                                        </Text>
                                                    </Box>
                                                </Tooltip>
                                            </Flex>
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td>
                                            <b>Inteview Details: </b>
                                        </Td>
                                        <Td>
                                            <Accordion allowMultiple>
                                                {jd?.interviewRounds.map((roundDetails, index) => (
                                                    <AccordionItem key={index}>
                                                        <h2>
                                                            <AccordionButton>
                                                                <Box
                                                                    as="span"
                                                                    flex="1"
                                                                    textAlign="left"
                                                                >
                                                                    <Text fontSize={"sm"}>
                                                                        Round No.{" "}
                                                                        {roundDetails.roundNo}
                                                                        {", "}
                                                                        {roundDetails.title}
                                                                    </Text>
                                                                </Box>
                                                                <AccordionIcon />
                                                            </AccordionButton>
                                                        </h2>
                                                        <AccordionPanel pb={2} pt={0}>
                                                            <Text fontSize={"sm"}>
                                                                <b>Mode: </b>{" "}
                                                                {roundDetails.locationOrMode}
                                                            </Text>
                                                            <Text fontSize={"sm"}>
                                                                <b>Intsructions: </b>
                                                                <Text as="span" fontSize={"xs"}>
                                                                    {" "}
                                                                    {roundDetails.instructions}
                                                                </Text>
                                                            </Text>
                                                        </AccordionPanel>
                                                    </AccordionItem>
                                                ))}
                                            </Accordion>
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td>
                                            <b>Salary Offering:</b>
                                        </Td>
                                        <Td>{jd?.salaryOffering}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td>
                                            <b>No. of Open Positions:</b>
                                        </Td>
                                        <Td>{jd?.numberOfOpenings}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td>
                                            <b>No. of Closed Positions:</b>
                                        </Td>
                                        <Td>{jd?.noOfClosings}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td>
                                            <b>Closing Date:</b>
                                        </Td>
                                        <Td>{formatDate(jd?.closingDate)}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td>
                                            <b>Percentage Share:</b>
                                        </Td>
                                        <Td>{jd?.percentage}%</Td>
                                    </Tr>
                                    <Tr>
                                        <Td>
                                            <b>Working Recruiters:</b>
                                        </Td>
                                        <Td>
                                            {jd?.workingRecruiters?.map((recruiter, index) => (
                                                <Tag
                                                    key={index}
                                                    size="lg"
                                                    borderRadius="full"
                                                    mr={1}
                                                    mb={1}
                                                >
                                                    <Avatar
                                                        src={`${backendUrl}${recruiter?.dp}`}
                                                        size="xs"
                                                        name={recruiter.name}
                                                        ml={-2}
                                                        mr={2}
                                                    />
                                                    <TagLabel fontSize={"sm"}>
                                                        ({recruiter.empId}) {recruiter.name}
                                                    </TagLabel>
                                                </Tag>
                                            ))}
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td>
                                            <b>Brought By:</b>
                                        </Td>
                                        <Td>
                                            <Tag size="lg" borderRadius="full">
                                                <Avatar
                                                    src={`${backendUrl}${jd?.broughtBy?.dp}`}
                                                    size="xs"
                                                    name={jd?.broughtBy.name}
                                                    ml={-2}
                                                    mr={2}
                                                />
                                                <TagLabel fontSize={"sm"}>
                                                    <Text fontSize={"sm"}>
                                                        ({jd?.broughtBy?.empId}) {jd?.broughtBy?.name}{" "}
                                                        on {formatDateTime(jd?.createdAt)}
                                                    </Text>
                                                </TagLabel>
                                            </Tag>
                                        </Td>
                                    </Tr>
                                    {jd?.selectedApplications && (
                                        <Tr>
                                            <Td>
                                                <b>Selected Candidates:</b>
                                            </Td>
                                            <Td>
                                                {jd?.selectedApplications?.map(
                                                    (application, index) => (
                                                            <Tag
                                                                key={index}
                                                                mr={1}
                                                                size="lg"
                                                                borderRadius="full"
                                                            >
                                                                <Avatar
                                                                    src={`${backendUrl}${application?.candidate?.dp}`}
                                                                    size="xs"
                                                                    name={
                                                                        application?.candidate?.name
                                                                    }
                                                                    ml={-1}
                                                                    mr={2}
                                                                />
                                                                <TagLabel fontSize={"sm"}>
                                                                    {" "}
                                                                    ({application?.applicationID}) (
                                                                    {application?.candidate?.stuId}){" "}
                                                                    {application?.candidate?.name}
                                                                </TagLabel>
                                                            </Tag>
                                                    )
                                                )}
                                            </Td>
                                        </Tr>
                                    )}
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </div>
    );
};

export default JdDetails;
