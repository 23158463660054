import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Tooltip } from "chart.js";

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip);

const BarChart = ({ height = "400px", width = "600px", dataset }) => {
    // Extract labels and data from the dataset
    const labels = dataset
        .map((item) => {
            const [year, month] = item.month.split("-");
            const monthNames = [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
            ];
            return `${monthNames[parseInt(month, 10) - 1]} ${year}`;
        })
        .reverse();

    const dataValues = dataset.map((item) => item.noOfClosing).reverse();

    const data = {
        labels,
        datasets: [
            {
                data: dataValues,
                backgroundColor: "#3D993D", // Green bar color
                borderWidth: 0,
                borderColor: "white",
                barThickness: 4,
            },
        ],
    };

    const options = {
        scales: {
            x: {
                display: false,
            },
            y: {
                display: false,
                beginAtZero: true,
            },
        },
        plugins: {
            legend: {
                display: false, // Hides the legend
            },
            tooltip: {
                enabled: true, // Enable tooltips
            },
        },
    };

    return (
        <Bar data={data} options={options} height={height} width={width} style={{ zIndex: 999 }} />
    );
};

export default BarChart;
