import React from "react";
import HomeNavBar from "../Components/NavBar/HomeNavBar";
import HomePageBody from "./../Components/Body/HomePage/HomePage";

const HomePage = () => {
    return (
        <div>
            <HomeNavBar/>
            <HomePageBody/>
        </div>
    );
};

export default HomePage;
