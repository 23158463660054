import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Box } from "@chakra-ui/react";

ChartJS.register(ArcElement, Tooltip, Legend);

const DonutChartFull = ({ size = "50px", cutout = "70%", dataSet, tooltip = true }) => {
    const data = {
        labels: dataSet.map((item) => item.label),
        datasets: [
            {
                data: dataSet.map((item) => item.data),
                backgroundColor: dataSet.map((item) => item.color),
                borderWidth: 0,
                hoverOffset: 4,
            },
        ],
    };

    const options = {
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: tooltip,
            },
            datalabels: {
                color: "#fff",
                display: false,
                font: {
                    weight: "bold",
                },
            },
        },
        cutout: cutout,
    };

    return (
        <Box size={size} width={size}>
            <Doughnut data={data} options={options} style={{ zIndex: 999 }} />
        </Box>
    );
};

export default DonutChartFull;
