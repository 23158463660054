// List of categorized skills
export const categorizedSkills = {
    "Software Development and Programming": [
        "HTML",
        "CSS",
        "JavaScript",
        "React",
        "Angular",
        "Vue",
        "Node.js",
        "Express",
        "MongoDB",
        "SQL",
        "Python",
        "Django",
        "Flask",
        "Java",
        "Spring",
        "Ruby",
        "Rails",
        "PHP",
        "Laravel",
        "C#",
        ".NET",
        "C++",
        "Swift",
        "Kotlin",
        "Go",
        "Rust",
        "TypeScript",
        "SASS",
        "Less",
        "Bootstrap",
        "Tailwind",
        "GraphQL",
        "Docker",
        "Kubernetes",
        "Git",
        "GitHub",
    ],
    "Data Analysis and Statistics": [
        "SQL",
        "Python",
        "R",
        "Tableau",
        "Power BI",
        "QlikView",
        "Excel",
        "Matlab",
        "Hadoop",
        "Spark",
        "Kafka",
        "Elasticsearch",
        "Logstash",
        "Kibana",
    ],
    "Artificial Intelligence (AI) and Machine Learning (ML)": [
        "Python",
        "R",
        "TensorFlow",
        "Keras",
        "PyTorch",
        "Scikit-Learn",
        "Spark MLlib",
        "AWS SageMaker",
    ],
    "Cloud Computing": [
        "AWS",
        "Azure",
        "GCP",
        "Firebase",
        "Terraform",
        "Ansible",
        "Docker",
        "Kubernetes",
        "Jenkins",
        "TravisCI",
        "CircleCI",
    ],
    Cybersecurity: [
        "Linux",
        "Unix",
        "Shell Scripting",
        "Powershell",
        "Ansible",
        "Puppet",
        "Chef",
    ],
    "Project Management": ["JIRA", "Confluence", "Trello", "Asana", "CI/CD"],
    "Digital Marketing": [
        "SEO",
        "Google Analytics",
        "Content Marketing",
        "Social Media Marketing",
    ],
    "Graphic Design": ["Photoshop", "Illustrator", "InDesign"],
    "UX/UI Design": ["Sketch", "Figma", "Adobe XD"],
    "Financial Analysis": ["Excel", "Tableau", "Power BI"],
    "Foreign Languages": ["Spanish", "French", "German"],
    "Soft skills": ["Communication", "Teamwork", "Problem-solving"],
};
