import React, { useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Box, Flex, Text, Tag, Tooltip as ChakraTooltip } from "@chakra-ui/react";

ChartJS.register(ArcElement, Tooltip, Legend);

const DonutChart = ({ size = "50px", cutout = "70%", dataSet, tooltip = true, onHoverSegment }) => {
    const data = {
        labels: dataSet.map((item) => item.label),
        datasets: [
            {
                data: dataSet.map((item) => item.data),
                backgroundColor: dataSet.map((item) => item.color),
                borderWidth: 0,
                hoverOffset: 4,
            },
        ],
    };

    const options = {
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: tooltip,
            },
        },
        cutout: cutout,
        onHover: (event, chartElement) => {
            if (chartElement.length) {
                const index = chartElement[0].index;
                onHoverSegment(index);
            } else {
                onHoverSegment(null);
            }
        },
    };

    return (
        <Box size={size} width={size}>
            <Doughnut data={data} options={options} />
        </Box>
    );
};

export default DonutChart;
