import React, { useState, useEffect } from "react";

function TimeDisplay() {
    const [dateTime, setDateTime] = useState(new Date());

    useEffect(() => {
        const intervalId = setInterval(() => {
            setDateTime(new Date());
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    const formatDate = (date) => {
        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const year = date.getFullYear();
        const weekday = date.toLocaleDateString("en-US", { weekday: "long" });

        return `${month}/${day}/${year}, ${weekday}`;
    };

    return (
        <div>
            {formatDate(dateTime)} - {dateTime.toLocaleTimeString()}
        </div>
    );
}

export default TimeDisplay;
