export function getStatusStoC(status) {
    let requestedStatus = "";
    switch (status) {
        case "all":
            requestedStatus = "";
            break;
        case "selected":
            requestedStatus = "Selected";
            break;
        case "accepted":
            requestedStatus = "Accepted";
            break;
        case "validated":
            requestedStatus = "Validated";
            break;
        case "onProcess":
            requestedStatus = "On Process";
            break;
        case "onBench":
            requestedStatus = "On Bench";
            break;
        case "rejected":
            requestedStatus = "Rejected";
            break;
        case "notStarted":
            requestedStatus = "Not Started";
            break;
        case "pending":
            requestedStatus = "Pending";
            break;
        case "processing":
            requestedStatus = "Processing";
            break;
        case "closed":
            requestedStatus = "Closed";
            break;
        case "active":
            requestedStatus = "Active";
            break;
        case "inActive":
            requestedStatus = "Inactive";
            break;
        case "onHold":
            requestedStatus = "On Hold";
            break;
        case "cancelled":
            requestedStatus = "Cancelled";
            break;
        case "blocked":
            requestedStatus = "Blocked";
            break;
        case "deleted":
            requestedStatus = "Deleted";
            break;
        case "activeBDs":
            requestedStatus = "ActiveBDs";
            break;
        case "activeRecruiters":
            requestedStatus = "ActiveRecruiters";
            break;
        default:
            requestedStatus = "";
            break;
    }
    return requestedStatus;
}

export function getStatusCtoS(status) {
    let requestedStatus = "";
    switch (status) {
        case "Selected":
            requestedStatus = "selected";
            break;
        case "Validated":
            requestedStatus = "validated";
            break;
        case "Accepted":
            requestedStatus = "accepted";
            break;
        case "On Process":
            requestedStatus = "onProcess";
            break;
        case "On Bench":
            requestedStatus = "onBench";
            break;
        case "Rejected":
            requestedStatus = "rejected";
            break;
        case "Not Started":
            requestedStatus = "notStarted";
            break;
        case "Pending":
            requestedStatus = "pending";
            break;
        case "Processing":
            requestedStatus = "processing";
            break;
        case "Closed":
            requestedStatus = "closed";
            break;
        case "Active":
            requestedStatus = "active";
            break;
        case "Inactive":
            requestedStatus = "inActive";
            break;
        case "On Hold":
            requestedStatus = "onHold";
            break;
        case "Cancelled":
            requestedStatus = "cancelled";
            break;
        case "Blocked":
            requestedStatus = "blocked";
            break;
        case "Deleted":
            requestedStatus = "deleted";
            break;
        case "ActiveBDs":
            requestedStatus = "activeBDs";
            break;
        case "ActiveRecruiters":
            requestedStatus = "activeRecruiters";
            break;
        default:
            requestedStatus = "all";
            break;
    }
    return requestedStatus;
}
