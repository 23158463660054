import "./App.css";
import AllRoutes from "./Routes/AllRoutes";

function App() {
    localStorage.removeItem("chakra-ui-color-mode");

    return (
        <div className="App">
            <AllRoutes />
        </div>
    );
}

export default App;
