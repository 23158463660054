import axios from "axios";
import React, { createContext, useState, useContext, useMemo, useEffect } from "react";
import { useEnv } from "./EnvContext";
import { useAuth } from "./AuthContext";
import useGlobalToast from "../Utils/GlobalFunctions/toast";

const DashboardContext = createContext();

export const DashboardContextProvider = ({ children }) => {
    const toast = useGlobalToast();
    const { backendUrl } = useEnv();
    const { userData } = useAuth();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [dashJDData, setDashJDData] = useState(null);
    const [dashClientData, setDashClientData] = useState(null);
    const [applicationsOnProcessByStatusCount, setApplicationsOnProcessByStatusCount] =
        useState(null);
    const [applicationsStatics, setApplicationsStatics] = useState(null);
    const [perDayApplicationNumbers, setLast7DaysApplicationStatistics] = useState(null);
    const [earnings, setEarnings] = useState(null);
    const [closings, setClosings] = useState(null);
    const [recruiterStatus, setRecruiterStatus] = useState(null);
    const [todaysWorkCount, setTodaysWorkCount] = useState(null);

    const memoizedDashJDData = useMemo(() => dashJDData, [dashJDData]);
    const memoizedDashCLientData = useMemo(() => dashClientData, [dashClientData]);
    const memoizedApplicationsOnProcessByStatusCount = useMemo(
        () => applicationsOnProcessByStatusCount,
        [applicationsOnProcessByStatusCount]
    );
    const memoizedApplicationsStatics = useMemo(() => applicationsStatics, [applicationsStatics]);
    const memoizedLast7DaysApplicationStatistics = useMemo(
        () => perDayApplicationNumbers,
        [perDayApplicationNumbers]
    );
    const memoizedEarnings = useMemo(() => earnings, [earnings]);
    const memoizedClosings = useMemo(() => closings, [closings]);
    const memoizedRecruiterStatus = useMemo(() => recruiterStatus, [recruiterStatus]);
    const memoizedTodaysWorkCount = useMemo(() => todaysWorkCount, [todaysWorkCount]);

    // Fetch JD Data
    const fetchJDs = async () => {
        setLoading(true);
        setError(null);

        try {
            const token = userData?.token;

            // If data is already cached, no need to fetch again
            if (memoizedDashJDData) {
                setLoading(false);
                return;
            }

            const response = await axios.get(`${backendUrl}/dashboard/jd`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const fetchedJDDataRes = response.data;
            setDashJDData(fetchedJDDataRes); // Store fetched data in state

            // console.log("fetchedJDDataRes", fetchedJDDataRes);
            return;
        } catch (err) {
            console.log("Error", err);
            const errorMsg = err.response?.data?.message || err.message || "Something Went Wrong";
            setError(errorMsg);
            toast.error("Error", errorMsg);
        } finally {
            setLoading(false);
        }
    };

    // Fetch Clients
    const fetchClients = async () => {
        setLoading(true);
        setError(null);

        try {
            const token = userData?.token;

            // If data is already cached, no need to fetch again
            if (memoizedDashCLientData) {
                setLoading(false);
                return;
            }

            const response = await axios.get(`${backendUrl}/dashboard/client`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const fetchedClientDataRes = response.data;
            setDashClientData(fetchedClientDataRes); // Store fetched data in state

            // console.log("fetchedClientDataRes", fetchedClientDataRes);
            return;
        } catch (err) {
            console.log("Error", err);
            const errorMsg = err.response?.data?.message || err.message || "Something Went Wrong";
            setError(errorMsg);
            toast.error("Error", errorMsg);
        } finally {
            setLoading(false);
        }
    };

    const countApplicationsOnProcessByStatus = async () => {
        setLoading(true);
        setError(null);

        try {
            const token = userData?.token;

            // If data is already cached, no need to fetch again
            if (memoizedApplicationsOnProcessByStatusCount) {
                setLoading(false);
                return;
            }

            const response = await axios.get(
                `${backendUrl}/dashboard/countApplicationsOnProcessByStatus`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            const countApplicationsOnProcessByStatusRes = response.data;
            // console.log(
            //     "countApplicationsOnProcessByStatusRes",
            //     countApplicationsOnProcessByStatusRes
            // );

            setApplicationsOnProcessByStatusCount(countApplicationsOnProcessByStatusRes);
            return;
        } catch (err) {
            console.log("Error", err);
            const errorMsg = err.response?.data?.message || err.message || "Something Went Wrong";
            setError(errorMsg);
            toast.error("Error", errorMsg);
        } finally {
            setLoading(false);
        }
    };

    const getApplicationStatistics = async () => {
        setLoading(true);
        setError(null);

        try {
            const token = userData?.token;

            // If data is already cached, no need to fetch again
            if (memoizedApplicationsStatics) {
                setLoading(false);
                return;
            }

            const response = await axios.get(`${backendUrl}/dashboard/getApplicationStatistics`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const getApplicationStatisticsRes = response.data;
            // console.log("getApplicationStatisticsRes", getApplicationStatisticsRes);

            setApplicationsStatics(getApplicationStatisticsRes);
            return;
        } catch (err) {
            console.log("Error", err);
            const errorMsg = err.response?.data?.message || err.message || "Something Went Wrong";
            setError(errorMsg);
            toast.error("Error", errorMsg);
        } finally {
            setLoading(false);
        }
    };

    const getPerDayApplicationNumbers = async () => {
        setLoading(true);
        setError(null);

        try {
            const token = userData?.token;

            // If data is already cached, no need to fetch again
            if (memoizedLast7DaysApplicationStatistics) {
                setLoading(false);
                return;
            }

            const response = await axios.get(`${backendUrl}/dashboard/getLast7DaysStatistics`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const getLast7DaysStatisticsRes = response.data;
            // console.log("getLast7DaysStatisticsRes", getLast7DaysStatisticsRes);

            setLast7DaysApplicationStatistics(getLast7DaysStatisticsRes);
            return;
        } catch (err) {
            console.log("Error", err);
            const errorMsg = err.response?.data?.message || err.message || "Something Went Wrong";
            setError(errorMsg);
            toast.error("Error", errorMsg);
        } finally {
            setLoading(false);
        }
    };

    const fetchEarnings = async () => {
        setLoading(true);
        setError(null);

        try {
            const token = userData?.token;

            // If data is already cached, no need to fetch again
            if (memoizedEarnings) {
                setLoading(false);
                return;
            }

            const response = await axios.get(`${backendUrl}/dashboard/fetchEarnings`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const fetchEarningsRes = response.data;
            // console.log("fetchEarningsRes", fetchEarningsRes);

            setEarnings(fetchEarningsRes.earnings);
            return;
        } catch (err) {
            console.log("Error", err);
            const errorMsg = err.response?.data?.message || err.message || "Something Went Wrong";
            setError(errorMsg);
            toast.error("Error", errorMsg);
        } finally {
            setLoading(false);
        }
    };

    const fetchClosings = async () => {
        setLoading(true);
        setError(null);

        try {
            const token = userData?.token;

            // If data is already cached, no need to fetch again
            if (memoizedClosings) {
                setLoading(false);
                return;
            }

            const response = await axios.get(`${backendUrl}/dashboard/calculateClosings`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const fetchClosingsRes = response.data;
            // console.log("fetchClosingsRes", fetchClosingsRes);

            setClosings(fetchClosingsRes.closings);
            return;
        } catch (err) {
            console.log("Error", err);
            const errorMsg = err.response?.data?.message || err.message || "Something Went Wrong";
            setError(errorMsg);
            toast.error("Error", errorMsg);
        } finally {
            setLoading(false);
        }
    };

    const fetchRecruiterStatus = async () => {
        setLoading(true);
        setError(null);

        try {
            const token = userData?.token;

            // If data is already cached, no need to fetch again
            if (memoizedRecruiterStatus) {
                setLoading(false);
                return;
            }

            const response = await axios.get(`${backendUrl}/dashboard/recruiterStatus`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const fetchRecruiterStatusRes = response.data;
            // console.log("fetchRecruiterStatusRes", fetchRecruiterStatusRes);

            setRecruiterStatus(fetchRecruiterStatusRes);
            return;
        } catch (err) {
            console.log("Error", err);
            const errorMsg = err.response?.data?.message || err.message || "Something Went Wrong";
            setError(errorMsg);
            toast.error("Error", errorMsg);
        } finally {
            setLoading(false);
        }
    };

    const fetchTodaysWorkCount = async () => {
        setLoading(true);
        setError(null);

        try {
            const token = userData?.token;

            // If data is already cached, no need to fetch again
            if (memoizedTodaysWorkCount) {
                setLoading(false);
                return;
            }

            const response = await axios.get(`${backendUrl}/dashboard/fetchTodaysWorkCount`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const fetchTodaysWorkCountRes = response.data;
            // console.log("fetchTodaysWorkCountRes", fetchTodaysWorkCountRes);

            setTodaysWorkCount(fetchTodaysWorkCountRes);
            return;
        } catch (err) {
            console.log("Error", err);
            const errorMsg = err.response?.data?.message || err.message || "Something Went Wrong";
            setError(errorMsg);
            toast.error("Error", errorMsg);
        } finally {
            setLoading(false);
        }
    };

    return (
        <DashboardContext.Provider
            value={{
                loading,
                error,
                dashJDData,
                fetchJDs,
                dashClientData,
                fetchClients,
                countApplicationsOnProcessByStatus,
                applicationsOnProcessByStatusCount,
                getApplicationStatistics,
                applicationsStatics,
                getPerDayApplicationNumbers,
                perDayApplicationNumbers,
                fetchEarnings,
                earnings,
                fetchClosings,
                closings,
                fetchRecruiterStatus,
                recruiterStatus,
                fetchTodaysWorkCount,
                todaysWorkCount,
            }}
        >
            {children}
        </DashboardContext.Provider>
    );
};

export default DashboardContext;

export const useDashboard = () => useContext(DashboardContext);
