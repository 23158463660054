import React from "react";
import { Line } from "react-chartjs-2";
import {
    Chart as ChartJS,
    LineElement,
    PointElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend,
    Filler,
} from "chart.js";
import { Box, Flex } from "@chakra-ui/react";

ChartJS.register(LineElement, PointElement, CategoryScale, LinearScale, Tooltip, Legend, Filler);

const generateRandomData = (days) => {
    return Array.from({ length: days }, () => Math.floor(Math.random() * 60) + 45);
};

const getLastNDays = (n) => {
    return Array.from({ length: n }, (_, i) => {
        const date = new Date();
        date.setDate(date.getDate() - i);
        return `${date.getDate()}/${date.getMonth() + 1}`;
    }).reverse();
};

const MultiLineChart = ({
    dataSet = [
        { label: "Application Started", data: [0, 0, 0, 0, 0, 0, 0], color: "#FF6384" },
        { label: "CV Processed", data: [0, 0, 0, 0, 0, 0, 0], color: "#36A2EB" },
        { label: "Interview Processed", data: [0, 0, 0, 0, 0, 0, 0], color: "#FFCE56" },
        { label: "Offer Processed", data: [0, 0, 0, 0, 0, 0, 0], color: "#4BC0C0" },
        { label: "Placement Processed", data: [0, 0, 0, 0, 0, 0, 0], color: "#9966FF" },
        { label: "Billing Processed", data: [0, 0, 0, 0, 0, 0, 0], color: "#FF9F40" },
    ],
    tooltip = true,
    height,
    width,
}) => {
    console.log("width", width);
    const data = {
        labels: getLastNDays(10),
        datasets: dataSet?.map((item) => ({
            label: item.label,
            data: item.data,
            borderColor: item.color,
            backgroundColor: item.color, // Set the background color to match the line color
            tension: 0.2,
            pointRadius: 2,
        })),
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false, // Hides the legend
            },
            tooltip: {
                enabled: true, // Enable tooltips
                mode: "index", // Show all datasets' data for the hovered date
                intersect: false, // Do not require the hover to be exactly on a point
                callbacks: {
                    labelColor: function (context) {
                        return {
                            borderColor: context.dataset.borderColor,
                            backgroundColor: context.dataset.borderColor, // Match the box fill color with the line color
                            borderWidth: 2,
                        };
                    },
                },
            },
        },
        scales: {
            x: {
                grid: {
                    display: true,
                    color: "#444444",
                },
                ticks: {
                    color: "#ffffff", // Light color for dark background
                    font: {
                        size: 10, // Smaller font size for x-axis labels
                    },
                },
            },
            y: {
                grid: {
                    color: "#444444", // Slightly lighter grid lines for dark background
                },
                ticks: {
                    color: "#ffffff", // Light color for dark background
                    font: {
                        size: 10, // Smaller font size for y-axis labels
                    },
                },
            },
        },
        interaction: {
            mode: "index", // Ensure tooltips are shown for all datasets for a particular date
            intersect: false, // Don't require the hover to intersect with a specific point
        },
    };

    return (
        <Flex w={"100%"} height={height}>
            <Line data={data} height={height} width={width} options={options} />
        </Flex>
    );
};

export default MultiLineChart;
