import React, { useEffect, useState } from "react";

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Box,
    Text,
    Avatar,
    Flex,
    FormControl,
    FormLabel,
    Input,
} from "@chakra-ui/react";

import { useEnv } from "../../../context/EnvContext";
import getGenderIcon from "../../../Utils/GlobalFunctions/GenderIcon";

const RecordOffer = ({ isOpen, onClose, application, recordOffer, status }) => {
    const { backendUrl } = useEnv();
    const [formData, setFormData] = useState({
        newOfferedSalary: "",
        fixedComponent: "",
        variableComponent: "",
    });
    const [isDataUpdated, setIsDataUpdated] = useState(false);
    const [errors, setErrors] = useState({});

    //console.log("application", application);
    useEffect(() => {
        setFormData({
            newOfferedSalary: application?.offerDetails?.newOfferedSalary || "",
            fixedComponent: application?.offerDetails?.fixedComponent || "",
            variableComponent: application?.offerDetails?.variableComponent || "",
        });
    }, [application]);

    console.log(
        "application?.offerDetails?.newOfferedSalary",
        application?.offerDetails?.newOfferedSalary
    );
    useEffect(() => {
        if (formData.newOfferedSalary !== "") {
            setIsDataUpdated(true);
        } else {
            setIsDataUpdated(false);
        }
    }, [formData, application]);

    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleFormReset = () => {
        setFormData({
            newOfferedSalary: application?.offerDetails?.newOfferedSalary || "",
            fixedComponent: application?.offerDetails?.fixedComponent || "",
            variableComponent: application?.offerDetails?.variableComponent || "",
        });
        setErrors({});
    };

    const handleFormSubmit = async () => {
        const res = await recordOffer(application?._id, formData);
        if (res?.success) {
            onClose();
        }
    };
    //console.log("formData", formData);
    return (
        <div>
            <Modal
                isLazy
                isOpen={isOpen}
                onClose={onClose}
                size={"xl"}
                style={{ minHeight: "100vh" }}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        Record Offer Details for <b>{application?.applicationID}</b>
                        <Flex justify={"space-between"} w={"100%"} mt={2} gap={2}>
                            <Box background={"teal"} w={"50%"} px={2} py={1} borderRadius={"md"}>
                                <Text fontSize={"md"}>{application?.jobDescription?.jobId}</Text>
                                <Text
                                    fontSize={"sm"}
                                    color={"#c9c9c9"}
                                    style={{
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                        maxWidth: "200px",
                                    }}
                                >
                                    {application?.jobDescription?.jobTitle}
                                </Text>
                            </Box>{" "}
                            <Flex
                                align={"center"}
                                background={"teal"}
                                w={"50%"}
                                px={2}
                                py={1}
                                borderRadius={"md"}
                            >
                                <Avatar
                                    src={`${backendUrl}${application?.candidate?.dp}`}
                                    size="sm"
                                    name={application?.candidate?.name}
                                    mr={2}
                                    background={"green"}
                                    color={"white"}
                                />

                                <Box
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                >
                                    <Flex gap={1}>
                                        <Text
                                            fontSize={"sm"}
                                            style={{
                                                textOverflow: "ellipsis",
                                                overflow: "hidden",
                                                maxWidth: "270px",
                                            }}
                                        >
                                            <span
                                                style={{
                                                    color: "#c9c9c9",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                {application?.candidate?.stuId}
                                            </span>
                                            {" - "}
                                            {application?.candidate?.name}
                                        </Text>
                                        {getGenderIcon(application?.candidate?.gender)}
                                    </Flex>
                                    <Text
                                        fontSize={"sm"}
                                        color={"#c9c9c9"}
                                        style={{
                                            textOverflow: "ellipsis",
                                            overflow: "hidden",
                                            maxWidth: "270px",
                                        }}
                                    >
                                        {application?.candidate?.email}
                                    </Text>
                                </Box>
                            </Flex>
                        </Flex>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody style={{ paddingBottom: "20px" }}>
                        <Flex gap={1} w={"100%"}>
                            <FormControl id="newOfferedSalary" isRequired>
                                <FormLabel>Salary offered:</FormLabel>
                                <Input
                                    type="number"
                                    name="newOfferedSalary"
                                    value={formData.newOfferedSalary}
                                    onChange={handleChange}
                                    placeholder="Ex. 600000"
                                />
                            </FormControl>
                            <FormControl id="fixedComponent">
                                <FormLabel>Fixed:</FormLabel>
                                <Input
                                    type="number"
                                    name="fixedComponent"
                                    value={formData.fixedComponent}
                                    onChange={handleChange}
                                    placeholder="Ex. 400000"
                                />
                            </FormControl>{" "}
                            <FormControl id="variableComponent">
                                <FormLabel>Variable:</FormLabel>
                                <Input
                                    type="number"
                                    name="variableComponent"
                                    value={formData.variableComponent}
                                    onChange={handleChange}
                                    placeholder="Ex. 200000"
                                />
                            </FormControl>
                        </Flex>
                    </ModalBody>{" "}
                    <ModalFooter>
                        <Button colorScheme="red" mr={3} size="sm" onClick={handleFormReset}>
                            Reset
                        </Button>
                        <Button
                            colorScheme="blue"
                            size="sm"
                            onClick={handleFormSubmit}
                            isDisabled={!isDataUpdated}
                        >
                            Submit
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </div>
    );
};

export default RecordOffer;
