import React, { useEffect, useState } from "react";

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Tag,
    TagLabel,
    Box,
    Text,
    Avatar,
    Tooltip,
    Flex,
    background,
    Icon,
} from "@chakra-ui/react";
import { Table, Tbody, Tr, Td, TableContainer } from "@chakra-ui/react";

import { formatDate, formatDateTime } from "../../../Utils/GlobalFunctions/FormatDate";
import { useEnv } from "../../../context/EnvContext";
import getGenderIcon from "../../../Utils/GlobalFunctions/GenderIcon";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { FaPhoneAlt, FaRegEdit } from "react-icons/fa";
import { BsWhatsapp } from "react-icons/bs";
import { css } from "@emotion/react";
import EditMyProfile from "./EditMyProfile";

const EmployeeDetails = ({ isOpen, onClose, user, operation }) => {
    const { backendUrl } = useEnv();
    //console.log("user", user);
    const [isEditMyProfileModalOpen, setIsEditMyProfileModalOpen] = useState(false);

    useEffect(() => {
        if (!user) return;
    }, [user]);

    // Handle Employee Form modal Preview
    const handleEditMyProfile = () => {
        setIsEditMyProfileModalOpen(true);
    };

    const handleEditMyProfileCloseModal = () => {
        setIsEditMyProfileModalOpen(false);
    };

    return (
        <div>
            <Modal isLazy isOpen={isOpen} onClose={onClose} style={{ minHeight: "100vh" }}>
                <ModalOverlay />
                <ModalContent className="user-details">
                    <ModalHeader fontWeight={"bold"}>
                        <Flex justify={"center"} align={"center"} gap={3}>
                            {operation}
                            {operation == "My Profile" && (
                                <Icon
                                    as={FaRegEdit}
                                    cursor="pointer"
                                    color="#a3a3a3"
                                    _hover={{ color: "white" }}
                                    onClick={handleEditMyProfile}
                                />
                            )}
                        </Flex>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody style={{ paddingBottom: "20px" }}>
                        <Flex gap={4}>
                            <Avatar size="2xl" name={user?.name} src={`${backendUrl}${user?.dp}`} />
                            <Flex direction={"column"}>
                                <Text fontSize="md">
                                    <b>{user?.empId}</b> - {user?.userType}
                                </Text>
                                <Flex gap={1} align={"center"}>
                                    {getGenderIcon(user?.gender)}
                                    <Text>{user?.name}</Text>
                                </Flex>
                                <Flex
                                    gap={"5px"}
                                    align={"center"}
                                    fontSize="md"
                                    color="#a3a3a3"
                                    width={"100%"}
                                    flexWrap={"wrap"}
                                >
                                    <MdOutlineAlternateEmail /> <Text>{user?.email}</Text>
                                </Flex>
                                {user?.phoneNo && (
                                    <Flex
                                        gap={"5px"}
                                        align={"center"}
                                        fontSize="sm"
                                        color="#a3a3a3"
                                    >
                                        <FaPhoneAlt /> {user?.phoneNo}
                                    </Flex>
                                )}
                                {user?.whatsAppNo && (
                                    <Flex
                                        gap={"5px"}
                                        align={"center"}
                                        fontSize="sm"
                                        color="#a3a3a3"
                                    >
                                        <BsWhatsapp /> {user?.whatsAppNo}
                                    </Flex>
                                )}
                            </Flex>
                        </Flex>
                    </ModalBody>
                </ModalContent>
            </Modal>
            {isEditMyProfileModalOpen && (
                <EditMyProfile
                    isOpen={isEditMyProfileModalOpen}
                    onClose={handleEditMyProfileCloseModal}
                    user={user}
                />
            )}
        </div>
    );
};

export default EmployeeDetails;
