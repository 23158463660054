import React, { useEffect, useState } from "react";

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Box,
    Text,
    Avatar,
    Flex,
    FormControl,
    FormLabel,
    Textarea,
    Checkbox,
} from "@chakra-ui/react";

import { useEnv } from "../../../context/EnvContext";
import getGenderIcon from "../../../Utils/GlobalFunctions/GenderIcon";

const RecordJoiningRes = ({ isOpen, onClose, application, recordCandJoiningRes, isUpdating }) => {
    const { backendUrl } = useEnv();
    const [formData, setFormData] = useState({ isJoined: true, remarks: "" });

    useEffect(() => {
        setFormData({
            isJoined: application?.placementDetails?.isJoined,
            remarks: application?.placementDetails?.remarks || "",
        });
    }, [application]);

    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleFormReset = () => {
        setFormData({
            isJoined: application?.placementDetails?.isJoined || true,
            remarks: application?.placementDetails?.remarks || "",
        });
    };
    const handleFormSubmit = async () => {
        // call submit function
        const res = await recordCandJoiningRes(
            application?._id,
            formData,
            isUpdating,
            application?.placementDetails?.candidateResponse || "Pending"
        );
        //console.log("res", res);
        if (res?.success) {
            onClose();
        }
    };

    //console.log("formData", formData);
    return (
        <div>
            <Modal
                isLazy
                isOpen={isOpen}
                onClose={onClose}
                size={"xl"}
                style={{ minHeight: "100vh" }}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        {isUpdating ? "Update" : "Record"} Joining Status of{" "}
                        <b>{application?.candidate?.stuId}</b>, for{" "}
                        <b>{application?.placementDetails?.placementId}</b>,{" "}
                        <b>{application?.applicationID}</b>
                        <Flex justify={"space-between"} w={"100%"} mt={2} gap={2}>
                            <Box background={"teal"} w={"50%"} px={2} py={1} borderRadius={"md"}>
                                <Text fontSize={"md"}>{application?.jobDescription?.jobId}</Text>
                                <Text
                                    fontSize={"sm"}
                                    color={"#c9c9c9"}
                                    style={{
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                        maxWidth: "200px",
                                    }}
                                >
                                    {application?.jobDescription?.jobTitle}
                                </Text>
                            </Box>{" "}
                            <Flex
                                align={"center"}
                                background={"teal"}
                                w={"50%"}
                                px={2}
                                py={1}
                                borderRadius={"md"}
                            >
                                <Avatar
                                    src={`${backendUrl}${application?.candidate?.dp}`}
                                    size="sm"
                                    name={application?.candidate?.name}
                                    mr={2}
                                    background={"green"}
                                    color={"white"}
                                />

                                <Box
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                >
                                    <Flex gap={1}>
                                        <Text
                                            fontSize={"sm"}
                                            style={{
                                                textOverflow: "ellipsis",
                                                overflow: "hidden",
                                                maxWidth: "270px",
                                            }}
                                        >
                                            <span
                                                style={{
                                                    color: "#c9c9c9",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                {application?.candidate?.stuId}
                                            </span>
                                            {" - "}
                                            {application?.candidate?.name}
                                        </Text>
                                        {getGenderIcon(application?.candidate?.gender)}
                                    </Flex>
                                    <Text
                                        fontSize={"sm"}
                                        color={"#c9c9c9"}
                                        style={{
                                            textOverflow: "ellipsis",
                                            overflow: "hidden",
                                            maxWidth: "270px",
                                        }}
                                    >
                                        {application?.candidate?.email}
                                    </Text>
                                </Box>
                            </Flex>
                        </Flex>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody style={{ paddingBottom: "20px" }}>
                        {/* <hr /> */}
                        <FormControl id="isJoined" isRequired>
                            <Flex align={"center"} gap={2}>
                                <FormLabel>Joining Status</FormLabel>
                                <Checkbox
                                    name="isFresher"
                                    isChecked={formData.isJoined}
                                    onChange={(e) =>
                                        setFormData({
                                            ...formData,
                                            isJoined: e.target.checked,
                                        })
                                    }
                                >
                                    Joined
                                </Checkbox>
                                <Checkbox
                                    ml={3}
                                    name="isJoined"
                                    colorScheme="red"
                                    isChecked={!formData.isJoined}
                                    onChange={(e) =>
                                        setFormData({
                                            ...formData,
                                            isJoined: !e.target.checked,
                                        })
                                    }
                                >
                                    Not Joined
                                </Checkbox>
                            </Flex>
                        </FormControl>
                        <FormControl id="remarks" mt={3}>
                            <FormLabel>Remarks:</FormLabel>
                            <Textarea
                                name="remarks"
                                value={formData.remarks}
                                onChange={handleChange}
                                placeholder="Write your Remark Here"
                                size="sm"
                                borderRadius={"md"}
                            />
                        </FormControl>
                    </ModalBody>{" "}
                    <ModalFooter>
                        <Button colorScheme="red" mr={3} size="sm" onClick={handleFormReset}>
                            Reset
                        </Button>
                        <Button colorScheme="blue" size="sm" onClick={handleFormSubmit}>
                            Submit
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </div>
    );
};

export default RecordJoiningRes;
