import React, { useState, useRef, useEffect } from "react";
import {
    Box,
    FormControl,
    FormLabel,
    Input,
    Tag,
    TagLabel,
    TagCloseButton,
    List,
    ListItem,
} from "@chakra-ui/react";
import { useCombobox } from "downshift";
import { categorizedSkills } from "./../DataSets/skills";

// Flatten the categorized skills into a single array
const allSkills = Object.values(categorizedSkills).flat();

const OptionalSkillsInput = ({ optionalSkills, setOptionalSkills }) => {
    const [inputValue, setInputValue] = useState("");
    const [selectedSkill, setSelectedSkill] = useState(null);
    const skillInputRef = useRef(null);

    const {
        isOpen,
        getMenuProps,
        getInputProps,
        getItemProps,
        highlightedIndex,
        setHighlightedIndex,
    } = useCombobox({
        items: allSkills.filter((skill) => skill.toLowerCase().includes(inputValue.toLowerCase())),
        onInputValueChange: ({ inputValue }) => {
            setInputValue(inputValue);
        },
        onSelectedItemChange: ({ selectedItem }) => {
            if (selectedItem) {
                setSelectedSkill(selectedItem);
            }
        },
    });

    useEffect(() => {
        if (selectedSkill) {
            addOptionalSkill(selectedSkill);
            setSelectedSkill(null);
            setInputValue("");
        }
    }, [selectedSkill]);

    const handleKeyDown = (e) => {
        if (e.key === "Enter" && inputValue && highlightedIndex === -1) {
            addOptionalSkill(inputValue);
            setInputValue("");
        }
    };

    const addOptionalSkill = (skill) => {
        if (skill && !optionalSkills.includes(skill)) {
            setOptionalSkills([...optionalSkills, skill]);
            setTimeout(() => {
                skillInputRef.current?.focus();
                setHighlightedIndex(0);
            }, 0);
        }
    };

    const removeOptionalSkill = (skillToRemove) => {
        setOptionalSkills(optionalSkills.filter((skill) => skill !== skillToRemove));
    };

    useEffect(() => {
        if (skillInputRef.current) {
            skillInputRef.current.addEventListener("keydown", handleKeyDown);
        }
        return () => {
            if (skillInputRef.current) {
                skillInputRef.current.removeEventListener("keydown", handleKeyDown);
            }
        };
    }, [skillInputRef.current, handleKeyDown]);

    return (
        <FormControl id="optional-skills">
            <FormLabel mb={0}>Optional Skills</FormLabel>
            <Box>
                <Input
                    {...getInputProps({
                        placeholder: "Type a skill, Ex. html",
                        ref: skillInputRef,
                        value: inputValue,
                    })}
                />
                <List {...getMenuProps()} borderRadius="md" maxH="150px" overflowY="auto">
                    {isOpen &&
                        allSkills
                            .filter((skill) =>
                                skill.toLowerCase().includes(inputValue.toLowerCase())
                            )
                            .map((item, index) => (
                                <ListItem
                                    key={index}
                                    {...getItemProps({ item, index })}
                                    bg={highlightedIndex === index ? "#ffffff29" : "#ffffff14"}
                                    p={2}
                                    cursor="pointer"
                                >
                                    {item}
                                </ListItem>
                            ))}
                </List>
            </Box>
            {optionalSkills.length > 0 && (
                <Box mt={2}>
                    {optionalSkills.map((skill, index) => (
                        <Tag
                            key={index}
                            size="md"
                            borderRadius="full"
                            variant="solid"
                            fontSize={"sm"}
                            colorScheme="teal"
                            mt={1}
                            mx={1}
                        >
                            <TagLabel pl={2}>{skill}</TagLabel>
                            <TagCloseButton onClick={() => removeOptionalSkill(skill)} />
                        </Tag>
                    ))}
                </Box>
            )}
        </FormControl>
    );
};

export default OptionalSkillsInput;
