// ProtectedRoute.js
import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

const ProtectedRoute = ({ component: Component, allowedRoles, ...rest }) => {
    const { userData, loading } = useAuth();

    if (loading) {
        return <div>Loading...</div>;
    }

    const userRole = userData?.userType;

    return allowedRoles.includes(userRole) ? <Component {...rest} /> : <Navigate to="/" />;
};

export default ProtectedRoute;
